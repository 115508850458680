import { validateDataMethods } from "utils-library/dist/validation-engine";
import { camelToHuman } from "utils-library/dist/utils";
export var EBold;
(function (EBold) {
    EBold["INHERIT"] = "INHERIT";
    EBold["B100"] = "B100";
    EBold["B200"] = "B200";
    EBold["B300"] = "B300";
    EBold["B400"] = "B400";
    EBold["B500"] = "B500";
    EBold["B600"] = "B600";
    EBold["B700"] = "B700";
    EBold["B800"] = "B800";
    EBold["B900"] = "B900";
    EBold["B1000"] = "B1000";
    EBold["BOLD"] = "BOLD";
})(EBold || (EBold = {}));
export var EFontSize;
(function (EFontSize) {
    EFontSize["INHERIT"] = "inherit";
    EFontSize["PX6"] = "6px";
    EFontSize["PX8"] = "8px";
    EFontSize["PX9"] = "9px";
    EFontSize["PX10"] = "10px";
    EFontSize["PX11"] = "11px";
    EFontSize["PX12"] = "12px";
    EFontSize["PX13"] = "13px";
    EFontSize["PX14"] = "14px";
    EFontSize["PX16"] = "16px";
    EFontSize["PX18"] = "18px";
    EFontSize["PX20"] = "20px";
    EFontSize["PX24"] = "24px";
    EFontSize["PX28"] = "28px";
    EFontSize["PX32"] = "32px";
    EFontSize["PX36"] = "36px";
    EFontSize["PX42"] = "42px";
    EFontSize["PX64"] = "64px";
    EFontSize["PX96"] = "96px";
    EFontSize["PX128"] = "128px";
    EFontSize["PX192"] = "192px";
    EFontSize["PX256"] = "256px";
})(EFontSize || (EFontSize = {}));
export var EItalic;
(function (EItalic) {
    EItalic["INHERIT"] = "inherit";
    EItalic["ITALIC"] = "ITALIC";
    EItalic["NON_ITALIC"] = "NON_ITALIC";
})(EItalic || (EItalic = {}));
export var EUnderline;
(function (EUnderline) {
    EUnderline["INHERIT"] = "inherit";
    EUnderline["UNDERLINE"] = "UNDERLINE";
    EUnderline["NON_UNDERLINE"] = "NON_UNDERLINE";
})(EUnderline || (EUnderline = {}));
export var ETextAlign;
(function (ETextAlign) {
    ETextAlign["INHERIT"] = "inherit";
    ETextAlign["LEFT"] = "left";
    ETextAlign["CENTER"] = "center";
    ETextAlign["RIGHT"] = "right";
})(ETextAlign || (ETextAlign = {}));
export var ETextTransform;
(function (ETextTransform) {
    // https://developer.mozilla.org/en-US/docs/Web/CSS/text-transform
    ETextTransform["INHERIT"] = "inherit";
    ETextTransform["NONE"] = "none";
    ETextTransform["CAPITALIZE"] = "capitalize";
    ETextTransform["UPPERCASE"] = "uppercase";
    ETextTransform["LOWERCASE"] = "lowercase";
    ETextTransform["FULL_WIDTH"] = "full-width";
    ETextTransform["FULL_WIDTH_KANA"] = "full-width-kana";
})(ETextTransform || (ETextTransform = {}));
export var EDynaCMSFontFamily;
(function (EDynaCMSFontFamily) {
    // The order of the fonts is based on client's dynaCMSFonts implementation
    EDynaCMSFontFamily["INHERIT"] = "INHERIT";
    // PLain
    EDynaCMSFontFamily["F100_PLAIN_ROBOTO"] = "F100";
    // Times
    EDynaCMSFontFamily["F101_TIMES_BASE_MARCELLUS"] = "F101";
    EDynaCMSFontFamily["F125_TIMES_BASE_PRATA"] = "F125";
    EDynaCMSFontFamily["F173_TIMES_MIDDLEAGE_ARINK"] = "F173";
    EDynaCMSFontFamily["F153_TIMES_BOOK_TINOS"] = "F153";
    EDynaCMSFontFamily["F140_TIMES_BOOK_GENTIUM_PLUS"] = "F140";
    EDynaCMSFontFamily["F187_TIMES_TERMINAL"] = "F187";
    // Celine
    EDynaCMSFontFamily["F132_CELINE_BASE_JOSEFIN"] = "F132";
    EDynaCMSFontFamily["F154_CELINE_TIMES_NUOSU"] = "F154";
    EDynaCMSFontFamily["F155_CELINE_ROUND_FREDOKA"] = "F155";
    EDynaCMSFontFamily["F161_CELINE_CONDENSED"] = "F161";
    EDynaCMSFontFamily["F164_CELINE_XCONDENSED"] = "F164";
    EDynaCMSFontFamily["F165_CELINE_XXCONDENSED"] = "F165";
    EDynaCMSFontFamily["F160_CELINE_CALLIGRAPHY_POIRET_ONE"] = "F160";
    // Modern
    EDynaCMSFontFamily["F123_MODERN_BASE_MUSEO_MODERNO"] = "F123";
    EDynaCMSFontFamily["F135_MODERN_BASE_NOTO"] = "F135";
    // Fashion
    EDynaCMSFontFamily["F126_FASHION_BASE_RALEWAY"] = "F126";
    EDynaCMSFontFamily["F127_FASHION_CONDENSED_RADHANI"] = "F127";
    EDynaCMSFontFamily["F186_FASHION_EMPIRE_FS"] = "F186";
    EDynaCMSFontFamily["F188_FASHION_OSWALD"] = "F188";
    // Elegant
    EDynaCMSFontFamily["F128_ELEGANT_BASE_ITALIANA"] = "F128";
    EDynaCMSFontFamily["F138_ELEGANT_BASE_ROBOTO_FLEX"] = "F138";
    // Impact
    EDynaCMSFontFamily["F129_IMPACT_BASE_ANTON"] = "F129";
    EDynaCMSFontFamily["F139_IMPACT_BASE_FIRA"] = "F139";
    // Calligraphy
    EDynaCMSFontFamily["F130_CALLIGRAPHY_BASE_PINYON"] = "F130";
    EDynaCMSFontFamily["F177_CALLIGRAPHY_CHERRY"] = "F177";
    EDynaCMSFontFamily["F182_CALLIGRAPHY_LOVERS_QUARREL"] = "F182";
    EDynaCMSFontFamily["F184_CALLIGRAPHY_MEA_CULPA"] = "F184";
    EDynaCMSFontFamily["F185_CALLIGRAPHY_LETTERISH_YUJI_MAI"] = "F185";
    EDynaCMSFontFamily["F183_CALLIGRAPHY_E"] = "F183";
    // Artistic
    EDynaCMSFontFamily["F168_ARTISTIC_LINES_DOUBLE_TRAIN"] = "F168";
    EDynaCMSFontFamily["F131_ARTISTIC_LINES_MULTI_MONOTON"] = "F131";
    EDynaCMSFontFamily["F169_ARTISTIC_CINEMA_LINELIGHT"] = "F169";
    EDynaCMSFontFamily["F170_ARTISTIC_CURLY_GRIFFY"] = "F170";
    // Animal print
    EDynaCMSFontFamily["F171_ANIMALPRINT_CHEETAH"] = "F171";
    EDynaCMSFontFamily["F172_ANIMALPRINT_COW"] = "F172";
})(EDynaCMSFontFamily || (EDynaCMSFontFamily = {}));
export const getDefaultIDynaCMSFont = () => ({
    fontFamily: EDynaCMSFontFamily.INHERIT,
    fontSize: EFontSize.INHERIT,
    bold: EBold.INHERIT,
    italic: EItalic.INHERIT,
    underline: EUnderline.INHERIT,
    textAlign: ETextAlign.INHERIT,
    textTransform: ETextTransform.INHERIT,
    letterSpacingInherit: true,
    letterSpacing: -1,
    fontStretchInherit: true,
    fontStretchPercentage: 100,
    strokeInherit: true,
    strokeWidth: 0,
    strokeColor: 'background',
    opacityInherit: true,
    opacity: 1,
    scale: false,
    scaleX: 1,
    scaleY: 1,
});
const fontValidationRules = {
    fontFamily: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isEnumValue(value, EDynaCMSFontFamily);
    },
    fontSize: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isEnumValue(value, EFontSize);
    },
    italic: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isEnumValue(value, EItalic);
    },
    underline: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isEnumValue(value, EUnderline);
    },
    bold: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isEnumValue(value, EBold);
    },
    textAlign: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isEnumValue(value, ETextAlign);
    },
    textTransform: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isEnumValue(value, ETextTransform);
    },
    letterSpacingInherit: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isBoolean(value);
    },
    letterSpacing: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isNumberInRange(value, -16, 16);
    },
    fontStretchInherit: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isBoolean(value);
    },
    fontStretchPercentage: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isNumberInRange(value, 50, 200);
    },
    strokeInherit: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isBoolean(value);
    },
    strokeWidth: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isNumberInRange(value, 0, 24);
    },
    strokeColor: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isValidText(value, 0, 20);
    },
    opacityInherit: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isBoolean(value);
    },
    opacity: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isNumberInRange(value, 0, 1);
    },
    scale: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isBoolean(value);
    },
    scaleY: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isNumberInRange(value, 0.5, 1.5);
    },
    scaleX: value => {
        if (value === undefined)
            return "";
        return validateDataMethods.isNumberInRange(value, 0.5, 1.5);
    },
};
export const validateIDynaCMSFont = (value) => {
    return Object
        .entries(value)
        .map((entry) => {
        const [property, value] = entry;
        const validator = fontValidationRules[property];
        if (!validator)
            return `validateIDynaCMSFont: property [${property}] is not expected`;
        const validation = validator(value);
        if (!validation)
            return "";
        return `${camelToHuman(property)}: ${validation} Value: [${value}]`;
    })
        .filter(Boolean)
        .join(', ');
};
