import {EDynaCMSBlockImageLabelPosition} from "server-app/dist/interfaces";

import {Box} from "ui-components/dist/Box";
import {
  Theme,
  SxProps,
} from "ui-components/dist/ThemeProvider";

export interface IDynaCMSLabelProps {
  sx?: SxProps<Theme>;
  show?: boolean;
  foreground?: string;
  background?: string;
  position?: EDynaCMSBlockImageLabelPosition;
  children: any;
}

export const DynaCMSLabel = (props: IDynaCMSLabelProps): JSX.Element => {
  const {
    sx = {},
    foreground,
    background,
    position,
    children,
    show = !!children,
  } = props;

  return (
    <Box
      show={show}
      inline
      sx={{
        fontWeight: 'bold',
        fontSize: theme => theme.typography.fontSize * 1.1,
        color: foreground ? foreground : 'whitesmoke',
        backgroundColor: theme => background ? background : theme.palette.appMainColor.main,
        whiteSpace: 'nowrap',
        letterSpacing: -1,
        paddingTop: theme => theme.spacing(0.5),
        paddingBottom: theme => theme.spacing(0.5),
        paddingLeft: theme => theme.spacing(1.5),
        paddingRight: theme => theme.spacing(1.5),
        position: position ? 'absolute' : undefined,
        top: theme => position === EDynaCMSBlockImageLabelPosition.TOP_LEFT || position === EDynaCMSBlockImageLabelPosition.TOP_RIGHT ? theme.spacing(2) : undefined,
        bottom: theme => position === EDynaCMSBlockImageLabelPosition.BOTTOM_LEFT || position === EDynaCMSBlockImageLabelPosition.BOTTOM_RIGHT ? theme.spacing(2) : undefined,
        left: position === EDynaCMSBlockImageLabelPosition.TOP_LEFT || position === EDynaCMSBlockImageLabelPosition.BOTTOM_LEFT ? 0 : undefined,
        right: position === EDynaCMSBlockImageLabelPosition.TOP_RIGHT || position === EDynaCMSBlockImageLabelPosition.BOTTOM_RIGHT ? 0 : undefined,
        zIndex: position ? 1 : undefined,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
