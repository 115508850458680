/**
 * Convert an HTML script to plain text.
 *
 * Technically it uses the DOM.
 * Pros:
 * - High quality of the result.
 * Cons:
 * - It is not available in the node environment
 * - Kinda expensive since uses the DOM (but never draws anything on the screen).
 *
 * For performance and for simpler HTML script use the convertHTMLScriptToPlainText of the `utils`.
 *
 * @param html
 */
export const convertHTMLScriptToPlainText = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || '';
};
