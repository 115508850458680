import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Box } from "../../Box";
import { Thumbnail } from "./Thumbnail";
import { scrollTo } from "../utils/scrollTo";
const THUMBNAIL_WIDTH = 120;
export class Thumbnails extends React.Component {
    constructor() {
        super(...arguments);
        this.containerRef = React.createRef();
        this.handleWindowResize = () => {
            this.checkSelectedToBeInPortView();
        };
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.checkSelectedToBeInPortView();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.index !== this.props.index)
            this.checkSelectedToBeInPortView();
    }
    checkSelectedToBeInPortView() {
        if (!this.containerRef.current)
            return;
        const { index, animation = 250, } = this.props;
        const scrollPosition = this.containerRef.current.scrollLeft;
        const viewPortStart = scrollPosition;
        const viewPortEnd = scrollPosition + this.containerRef.current.offsetWidth;
        const selectedItemPosition = index * THUMBNAIL_WIDTH;
        const inViewPort = selectedItemPosition >= viewPortStart && (selectedItemPosition + THUMBNAIL_WIDTH) < viewPortEnd;
        if (inViewPort)
            return;
        const scrollLeft = (index * THUMBNAIL_WIDTH)
            - (this.containerRef.current.offsetWidth / 2)
            + (THUMBNAIL_WIDTH / 2);
        if (animation) {
            scrollTo(this.containerRef.current, 'scrollLeft', scrollLeft, animation);
        }
        else {
            this.containerRef.current.scrollLeft = scrollLeft;
        }
    }
    render() {
        const { sx = {}, dataComponentName, index, slides, onChange, } = this.props;
        return (_jsx(Box, { sx: Object.assign({ overflowX: 'scroll', overflowY: 'hidden', boxSizing: 'content-box' }, sx), dataComponentName: dataComponentName, ref: this.containerRef, children: _jsx(Box, { sx: {
                    width: slides.length * THUMBNAIL_WIDTH,
                    fontSize: 0,
                    '& > *': {
                        display: 'inline-block',
                        fontSize: theme => theme.typography.fontSize,
                    },
                }, children: slides.map((slide, slideIndex) => (_jsx(Thumbnail, { index: slideIndex, selected: slideIndex === index, content: slide.renderThumbnail(slideIndex === index), onClick: onChange }, slideIndex))) }) }));
    }
}
