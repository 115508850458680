import {isLocalhost} from "utils-library/dist/web";

import {appConfig} from "./core/application/config/appConfig";

(()=>{
  if (isLocalhost) return; // Do not pollute the dev env
  console.warn("%cWarning", "font-size: 42px; font-weight: bold; color: red; background-color: darkred;");
  console.warn("%cUsing this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS.", "font-size: 24px; font-weight: normal;");
  console.warn("%cDO NOT enter or paste code from people you don't trust!", "font-size: 16px; font-weight: bold;");
  console.warn(`%cContact the owner of the ${appConfig.appName} for more.`, "font-size: 16px; font-weight: bold;");
})();
