import {
  IDynaCMSBlockLinks,
  IDynaCMSBlockLinkItem,
  EDynaCMSBlockLinkType,
} from "server-app/dist/interfaces";

import {Box} from "ui-components/dist/Box";
import {
  Button,
  EButtonSize,
} from "ui-components/dist/Button";
import {
  Link,
  ELinkColor,
} from "ui-components/dist/Link";
import {ButtonLinear} from "ui-components/dist/ButtonLinear";

import {DynaCMSFont} from "../../../public-components";

export interface IViewerLinksProps {
  content: IDynaCMSBlockLinks;
}

export const ViewerLinks = (props: IViewerLinksProps): JSX.Element => {
  const {
    content: {
      viewMode,
      linkType,
      font,
      links,
    },
  } = props;

  const wrapper = (children: any, index: number) => (
    <Box
      key={index}
      sx={{margin: theme => theme.spacing(2)}}
      inline={viewMode === "inline"}
    >
      {children}
    </Box>
  );

  const renderLink = (
    {
      label,
      href,
      openInNewTab,
    }: IDynaCMSBlockLinkItem,
    index: number,
  ): JSX.Element => {
    switch (linkType) {
      case EDynaCMSBlockLinkType.APP_BUTTON:
        return wrapper(
          <Box
            sx={{
              'button': {fontSize: 'inherit'},
              '.button-label': {fontSize: 'inherit'},
            }}
          >
            <Button
              href={href}
              hrefNewWindow={openInNewTab}
              size={EButtonSize.MEDIUM}
              fontFamilyInherit
              fontWeightBold={false}
            >
              {label}
            </Button>
          </Box>,
          index,
        );
      case EDynaCMSBlockLinkType.TEXT_LINK:
        return wrapper(
          <Link
            href={href}
            target={openInNewTab ? '_blank' : undefined}
            color={ELinkColor.INHERIT}
          >
            {label}
          </Link>,
          index,
        );
      case EDynaCMSBlockLinkType.WHITE_BOX_BUTTON:
        return wrapper(
          <Box
            dataComponentName="DEBUG_ME"
            sx={{
              'button': {fontSize: 'inherit'},
              '.button-label': {fontSize: 'inherit'},
            }}
          >
            <ButtonLinear
              href={href}
              hrefNewWindow={openInNewTab}
              fontFamilyInherit
              fontWeightBold={false}
            >
              {label}
            </ButtonLinear>
          </Box>,
          index,
        );
      default:
        return (
          <Box
            sx={{color: theme => theme.palette.error.main}}
            key={index}
          >
            Link type [{linkType}] is not supported by the client.
          </Box>
        );
    }
  };

  return (
    <DynaCMSFont
      dataComponentName="ViewerLinks"
      font={font}
    >
      {links.map(renderLink)}
    </DynaCMSFont>
  );
};
