import { enumInfo } from "utils-library/dist/utils";
import { ETKDynaCMS } from "../intl/dynaCMSHardcodedTranslations";
export var EDynaCMSRights;
(function (EDynaCMSRights) {
    EDynaCMSRights["CMS_SETTINGS_CHANGE"] = "DCMS_CS";
    EDynaCMSRights["ARTICLES_CREATE"] = "DCMS_CA";
    EDynaCMSRights["ARTICLES_UPDATE"] = "DCMS_UA";
    EDynaCMSRights["ARTICLES_VIEW"] = "DCMS_VA";
    EDynaCMSRights["ARTICLES_ARCHIVE"] = "DCMS_AA";
    EDynaCMSRights["ARTICLES_UNARCHIVE"] = "DCMS_UAA";
    EDynaCMSRights["ARTICLES_DELETE"] = "DCMS_DA";
    EDynaCMSRights["ARTICLES_UNDELETE"] = "DCMS_UNA";
    EDynaCMSRights["ARTICLES_PUBLISH"] = "DCMS_PA";
    EDynaCMSRights["ARTICLES_UNPUBLISH"] = "DCMS_UPA";
})(EDynaCMSRights || (EDynaCMSRights = {}));
export const dynaCMSRightsEnumInfo = enumInfo({
    [EDynaCMSRights.CMS_SETTINGS_CHANGE]: {
        label: {
            id: ETKDynaCMS.CMS_SETTINGS_CHANGE__Label,
            defaultMessage: "Change CMS Settings",
        },
        description: {
            id: ETKDynaCMS.CMS_SETTINGS_CHANGE__Description,
            defaultMessage: "Change settings of the content system",
        },
    },
    [EDynaCMSRights.ARTICLES_CREATE]: {
        label: {
            id: ETKDynaCMS.ARTICLES_CREATE__Label,
            defaultMessage: "Create articles",
        },
        description: {
            id: ETKDynaCMS.ARTICLES_CREATE__Description,
            defaultMessage: "Create articles in the system",
        },
    },
    [EDynaCMSRights.ARTICLES_UPDATE]: {
        label: {
            id: ETKDynaCMS.ARTICLES_UPDATE__Label,
            defaultMessage: "Update articles",
        },
        description: {
            id: ETKDynaCMS.ARTICLES_UPDATE__Description,
            defaultMessage: "Can change the content the articles",
        },
    },
    [EDynaCMSRights.ARTICLES_VIEW]: {
        label: {
            id: ETKDynaCMS.ARTICLES_VIEW__Label,
            defaultMessage: "View articles",
        },
        description: {
            id: ETKDynaCMS.ARTICLES_VIEW__Description,
            defaultMessage: "Access to see all articles of the system",
        },
    },
    [EDynaCMSRights.ARTICLES_ARCHIVE]: {
        label: {
            id: ETKDynaCMS.ARTICLES_ARCHIVE__Label,
            defaultMessage: "Archive articles",
        },
        description: {
            id: ETKDynaCMS.ARTICLES_ARCHIVE__Description,
            defaultMessage: "Archive articles for future reference",
        },
    },
    [EDynaCMSRights.ARTICLES_UNARCHIVE]: {
        label: {
            id: ETKDynaCMS.ARTICLES_UNARCHIVE__Label,
            defaultMessage: "Unarchive articles",
        },
        description: {
            id: ETKDynaCMS.ARTICLES_UNARCHIVE__Description,
            defaultMessage: "Unarchive articles from the system",
        },
    },
    [EDynaCMSRights.ARTICLES_DELETE]: {
        label: {
            id: ETKDynaCMS.ARTICLES_DELETE__Label,
            defaultMessage: "Delete articles",
        },
        description: {
            id: ETKDynaCMS.ARTICLES_DELETE__Label,
            defaultMessage: "Move articles to trash bin for later permanent deletion",
        },
    },
    [EDynaCMSRights.ARTICLES_UNDELETE]: {
        label: {
            id: ETKDynaCMS.ARTICLES_UNDELETE__Label,
            defaultMessage: "Undelete articles",
        },
        description: {
            id: ETKDynaCMS.ARTICLES_UNDELETE__Description,
            defaultMessage: "Move articles back from the trash bin",
        },
    },
    [EDynaCMSRights.ARTICLES_PUBLISH]: {
        label: {
            id: ETKDynaCMS.ARTICLES_PUBLISH__Label,
            defaultMessage: "Publish articles",
        },
        description: {
            id: ETKDynaCMS.ARTICLES_PUBLISH__Label,
            defaultMessage: 'Publish articles to the world or schedule them for publish',
        },
    },
    [EDynaCMSRights.ARTICLES_UNPUBLISH]: {
        label: {
            id: ETKDynaCMS.ARTICLES_UNPUBLISH__Label,
            defaultMessage: "Unpublish articles",
        },
        description: {
            id: ETKDynaCMS.ARTICLES_UNPUBLISH__Label,
            defaultMessage: "Remove articles from publicity",
        },
    },
});
