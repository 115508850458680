import {EDynaCMSRights} from "server-app/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";

export interface IRouteArgs {
  countryId: string;
  languageId: string;
  groupId: string;
  articleId: string;
}

export const routeDynaCMSArticlePreViewerPaths: IAppRoute<IRouteArgs> = {
  title: 'Article preview',
  routePath: '/article-preview/:countryId/:languageId/:groupId/:articleId',
  exact: true,
  getRoutePath: (
    {
      countryId,
      languageId,
      groupId,
      articleId,
    } = {
      countryId: '',
      languageId: '',
      groupId: '',
      articleId: '',
    },
  ) =>
    routeDynaCMSArticlePreViewerPaths.routePath
      .replace(':countryId', countryId)
      .replace(':languageId', languageId)
      .replace(':groupId', groupId)
      .replace(':articleId', articleId),
  userHasAllRights: [],
  userHasAnyOfRights: [EDynaCMSRights.ARTICLES_VIEW],
  requiresLatestClientVersion: true,
  render: () => null,
};
