import ReactGA from "react-ga4";

import {waitUntil} from "utils-library/dist/utils";

import {appConfig} from "../application/config/appConfig";

(async () => {
  if (!appConfig.gaTrackingId) return;

  await waitUntil({
    checkCondition: async () => (window as any).gtag !== undefined,
    timeout: 20000,
    intervalTimeout: 100,
  });

  (window as any).gtag('config', appConfig.gaTrackingId);

  ReactGA.initialize([
    {
      trackingId: appConfig.gaTrackingId,
      gaOptions: undefined,
      gtagOptions: undefined,
    },
  ]);
})();
