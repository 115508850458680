import {
  useRef,
  useState,
} from "react";
import {DynaLocalStorageData} from "dyna-local-storage";

import {Typography} from "ui-components/dist/Typography";
import {ModalDialog} from "ui-components/dist/ModalDialog";
import {useGlobalEvent} from "ui-components/dist/useGlobalEvent";

import {DynaCMSIntlProvider} from "../../../dyna-cms/public-components/DynaCMSIntlProvider";
import {
  DynaIntlTranslate,
  useDynaIntl,
} from "../../../dyna-intl/components-public";

import {createIcon} from "ui-components/dist/IconComponent";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AgreeIcon from "@mui/icons-material/CheckCircleOutline";

export interface ICopyToClipboardNotificationModalProps {
  showEveryCopyEvents?: number;
}

interface ICopyCounter {
  counter: number;
  lastAt: number;
}

export const CopyToClipboardNotificationModalCore = (props: ICopyToClipboardNotificationModalProps): JSX.Element | null => {
  const {showEveryCopyEvents = 10} = props;

  const dynaIntl = useDynaIntl();
  const refLS = useRef(
    new DynaLocalStorageData<ICopyCounter>(
      'copy-to-clipboard-counter',
      {
        counter: 0,
        lastAt: 0,
      },
    ),
  );
  const [show, setShow] = useState<boolean>(false);

  useGlobalEvent('copy', () => {
    refLS.current.data.counter++;
    refLS.current.data.lastAt = Date.now();
    refLS.current.save();
    if (refLS.current.data.counter >= showEveryCopyEvents) {
      setShow(true);
      refLS.current.data.counter = 0;
      refLS.current.save();
    }
  });

  const handleModalDialogCloseClick = (): void => {
    setShow(false);
  };

  if (!show) return null;

  return (
    <ModalDialog
      show={show}
      title={dynaIntl.translalate({tk: tks.title})}
      buttons={[
        {
          Icon: createIcon.byMuiIcon(AgreeIcon),
          label: 'Okay!',
          onClick: handleModalDialogCloseClick,
        },
      ]}
      onClose={handleModalDialogCloseClick}
    >
      <ContentCopyIcon
        sx={{
          width: 64,
          height: 64,
        }}
      />
      <p/>
      <Typography variant="body1">
        <DynaIntlTranslate tk={tks.body1}/>
      </Typography>
      <Typography variant="body1">
        <DynaIntlTranslate tk={tks.body2}/>
      </Typography>
    </ModalDialog>
  );
};

const tks = {
  title: 'app-copy-counter--title',
  body1: 'app-copy-counter--body1',
  body2: 'app-copy-counter--body2',
};

export const CopyToClipboardNotificationModal = (props: ICopyToClipboardNotificationModalProps) => (
  <DynaCMSIntlProvider tks={tks}>
    <CopyToClipboardNotificationModalCore {...props}/>
  </DynaCMSIntlProvider>
);
