import * as React from "react";
import {IDynaError} from "dyna-error";

import {IntlProvider} from "react-intl";

import {ErrorBanner} from "ui-components/dist/ErrorBanner";

import {apiDynaIntlTranslationsGet} from "../../api/apiDynaIntlTranslationsGet";

export interface IDynaIntlProviderProps {
  countryId: string;
  languageId: string;
  tks?: { [tk: string]: string };
  children: any;
}

interface IDynaIntlProviderState {
  isInitialized: boolean;
  isLoading: boolean;
  translations: ITranslations;
  loadError: IDynaError | null;
}

interface ITranslations {
  [languageCountryId: string]: {
    [tk: string]: string;
  };
}

export class DynaIntlProvider extends React.Component<IDynaIntlProviderProps, IDynaIntlProviderState> {
  public state: IDynaIntlProviderState = {
    isInitialized: false,
    isLoading: false,
    translations: {},
    loadError: null,
  };

  public componentDidMount() {
    this.loadTranslationsForLanguageId();
  }

  public componentDidUpdate(prevProps: Readonly<IDynaIntlProviderProps>) {
    if (
      prevProps.languageId !== this.props.languageId ||
      prevProps.countryId !== this.props.countryId ||
      Object.keys(prevProps.tks || {}).join('/') !== Object.keys(this.props.tks || {}).join('/')
    ) {
      this.loadTranslationsForLanguageId();
    }
  }

  private loadTranslationsForLanguageId = async (): Promise<void> => {
    const {
      languageId,
      countryId,
      tks = {},
    } = this.props;
    if (!Object.keys(tks).length) {
      this.setState({isInitialized: true});
      return;
    }

    try {
      this.setState({isLoading: true});
      const response = await apiDynaIntlTranslationsGet({
        countryId,
        languageId,
        tks: Object.values(tks),
      });
      this.setState({
        translations: {
          ...this.state.translations,
          [this.getLangaugeCountryId(languageId, countryId)]: {
            ...this.state.translations[languageId],
            ...response.translations,
          },
        },
        isInitialized: true,
        isLoading: false,
      });
    }
    catch (e: any) {
      this.setState({
        loadError: e,
        isInitialized: true,
        isLoading: false,
      });
    }
  };

  private getLangaugeCountryId= (langaugeId: string, countryId: string): string => `${langaugeId}-${countryId}`;

  public render(): JSX.Element | null {
    const {
      languageId,
      countryId,
      children,
    } = this.props;
    const {
      isInitialized,
      isLoading,
      translations,
      loadError,
    } = this.state;

    if (!isInitialized) return null;
    if (isLoading) return null;

    if (loadError) {
      return (
        <ErrorBanner
          title="Cannot load translations"
          error={loadError}
        />
      );
    }

    return (
      // @ts-ignore --> Due to Intl's types error: Property children does not exist on type
      <IntlProvider
        locale={languageId}
        messages={translations[this.getLangaugeCountryId(languageId,countryId)]}
      >
        {children}
      </IntlProvider>
    );
  }
}
