import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { convertJSXElementToHTML } from "../utils";
import { createMetaTags } from "./createMetaTags";
export const openGraphMetaTagsToString = (props) => convertJSXElementToHTML({
    jsxElement: _jsx(OpenGraphMetaTags, Object.assign({}, props)),
    prettify: true,
});
/**
 * Builds the meta tags for Open Graph with meta tags for the <head>.
 * NOTE: this component is not reusable because the content of it should be in the <head>. For component use, use the OpenGraphMetaTags instead.
 */
const OpenGraphMetaTags = (props) => {
    return (_jsx(_Fragment, { children: createMetaTags(props)
            .filter(metaTag => !!metaTag.content)
            .map(({ property, content, }, index) => (_jsx("meta", { property: property, content: content }, index))) }));
};
