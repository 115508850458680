export var EOpenGraphType;
(function (EOpenGraphType) {
    EOpenGraphType["WEBSITE"] = "website";
    EOpenGraphType["ARTICLE"] = "article";
    EOpenGraphType["PRODUCT"] = "product";
    EOpenGraphType["BOOK"] = "book";
    EOpenGraphType["PLACE"] = "place";
    EOpenGraphType["PROFILE"] = "profile";
    EOpenGraphType["MOVIE"] = "video.movie";
})(EOpenGraphType || (EOpenGraphType = {}));
