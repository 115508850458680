import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { asyncCalls, getCache, setCache, } from "utils-library/dist/utils";
import { Box } from "../Box";
import { SlideViewer } from "./components/SlideViewer";
import { Thumbnails } from "./components/Thumbnails";
export class Carousel extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            index: 0,
            performAnimation: false,
        };
        this.handleChange = (index) => {
            const { id, onChange, } = this.props;
            setCache('carousel', id, index);
            this.setState({ index });
            onChange && onChange(index);
        };
    }
    componentDidMount() {
        const { id } = this.props;
        const { index } = this.state;
        if (getCache('carousel', id) === undefined) {
            setCache('carousel', id, index);
        }
        const cachedShowIndex = getCache('carousel', id);
        asyncCalls(done => {
            if (cachedShowIndex !== index) {
                this.setState({ index: cachedShowIndex }, done);
                return;
            }
            done();
        }, done => setTimeout(done, 100), requestAnimationFrame, done => this.setState({ performAnimation: true }, done));
    }
    render() {
        const { dataComponentName, slides, animation: animationDuration = 250, preload = 3, } = this.props;
        const { index, performAnimation, } = this.state;
        const applyAnimationDuration = performAnimation ? animationDuration : 0;
        return (_jsxs(Box, { sx: {
                height: '100%',
                display: "flex",
                flexDirection: 'column',
            }, dataComponentName: [dataComponentName, "Carousel"], children: [_jsx(SlideViewer, { sx: { flex: 'auto' }, index: index, slides: slides, animation: applyAnimationDuration, preload: preload, onChange: this.handleChange }), _jsx(Thumbnails, { sx: { paddingBottom: 19 }, index: index, slides: slides, animation: applyAnimationDuration, onChange: this.handleChange })] }));
    }
}
