import { jsx as _jsx } from "react/jsx-runtime";
import { useInViewPort } from "../useInViewPort";
export const InViewPort = (props) => {
    const { show = true, showOnlyInViewPort = true, delay, rootMargin, remain = true, fullHeight, children, onInViewPortChange, } = props;
    const { ref, inViewPort, viewed, } = useInViewPort({
        delay,
        rootMargin,
        onInViewPortChange,
    });
    if (!show)
        return null;
    return (_jsx("div", { ref: ref, style: { height: fullHeight ? '100%' : undefined }, children: show
            && ((remain && viewed) || (!showOnlyInViewPort || inViewPort))
            ? children
            : null }));
};
