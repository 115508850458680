import { getDefaultIDynaCMSFont, } from "./IDynaCMSFont";
export var EDynaCMSBlockType;
(function (EDynaCMSBlockType) {
    EDynaCMSBlockType["HEADING"] = "HEADING";
    EDynaCMSBlockType["BODY"] = "BODY";
    EDynaCMSBlockType["MARKDOWN"] = "MARKDOWN";
    EDynaCMSBlockType["QUOTE"] = "QUOTE";
    EDynaCMSBlockType["LINKS"] = "LINKS";
    EDynaCMSBlockType["IMAGE"] = "IMAGE";
    EDynaCMSBlockType["IMAGES"] = "IMAGES";
    EDynaCMSBlockType["VIDEO_FILE"] = "VIDEO_FILE";
    EDynaCMSBlockType["VIDEO_YOUTUBE"] = "VIDEO_YOUTUBE";
    EDynaCMSBlockType["VIDEO_VIMEO"] = "VIDEO_VIMEO";
    EDynaCMSBlockType["SOUND_SOUNDCLOUD"] = "SOUND_SOUNDCLOUD";
    EDynaCMSBlockType["MULTIMEDIA"] = "MULTIMEDIA";
    EDynaCMSBlockType["SOCIAL_POST_FACEBOOK"] = "SOCIAL_POST_FACEBOOK";
    EDynaCMSBlockType["SOCIAL_POST_TWITTER"] = "SOCIAL_POST_TWITTER";
    EDynaCMSBlockType["SOCIAL_POST_INSTAGRAM"] = "SOCIAL_POST_INSTAGRAM";
    EDynaCMSBlockType["SOCIAL_POST_LINKEDIN"] = "SOCIAL_POST_LINKEDIN";
    EDynaCMSBlockType["SOCIAL_POST_TIKTOK"] = "SOCIAL_POST_TIKTOK";
})(EDynaCMSBlockType || (EDynaCMSBlockType = {}));
export const getDefaultDynaCMSBlockHeader = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.HEADING, heading: 1, font: getDefaultIDynaCMSFont(), text: "" }, partial));
export const getDefaultDynaCMSBlockBody = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.BODY, font: getDefaultIDynaCMSFont(), headingFirstLetter: false, html: "" }, partial));
export const getDefaultDynaCMSBlockMarkdown = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.MARKDOWN, font: getDefaultIDynaCMSFont(), headingFirstLetter: false, markdown: '' }, partial));
export var EDynaCMSBlockQuoteStyle;
(function (EDynaCMSBlockQuoteStyle) {
    EDynaCMSBlockQuoteStyle["QUOTE_FRONT"] = "QUOTE_FRONT";
    EDynaCMSBlockQuoteStyle["LEFT_LINE"] = "LEFT_LINE";
})(EDynaCMSBlockQuoteStyle || (EDynaCMSBlockQuoteStyle = {}));
export const getDefaultDynaCMSBlockQuote = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.QUOTE, font: getDefaultIDynaCMSFont(), style: EDynaCMSBlockQuoteStyle.QUOTE_FRONT, main: "", author: "", date: "" }, partial));
export var EDynaCMSBlockLinkType;
(function (EDynaCMSBlockLinkType) {
    EDynaCMSBlockLinkType["APP_BUTTON"] = "APP_BUTTON";
    EDynaCMSBlockLinkType["WHITE_BOX_BUTTON"] = "WHITE_BOX_BUTTON";
    EDynaCMSBlockLinkType["TEXT_LINK"] = "TEXT_LINK";
})(EDynaCMSBlockLinkType || (EDynaCMSBlockLinkType = {}));
export const getDefaultDynaCMSLinks = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.LINKS, viewMode: "inline", linkType: EDynaCMSBlockLinkType.TEXT_LINK, font: getDefaultIDynaCMSFont(), links: [] }, partial));
export var EDynaCMSBlockImageLabelPosition;
(function (EDynaCMSBlockImageLabelPosition) {
    EDynaCMSBlockImageLabelPosition["TOP_LEFT"] = "TOP_LEFT";
    EDynaCMSBlockImageLabelPosition["TOP_RIGHT"] = "TOP_RIGHT";
    EDynaCMSBlockImageLabelPosition["BOTTOM_LEFT"] = "BOTTOM_LEFT";
    EDynaCMSBlockImageLabelPosition["BOTTOM_RIGHT"] = "BOTTOM_RIGHT";
})(EDynaCMSBlockImageLabelPosition || (EDynaCMSBlockImageLabelPosition = {}));
export const getDefaultDynaCMSBlockImage = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.IMAGE, title: '', label: '', labelPosition: EDynaCMSBlockImageLabelPosition.TOP_RIGHT, labelForeground: 'whitesmoke', labelBackground: 'red', caption: '', showCaption: false, contrastTextColor: 'white', contrastTextBorderColor: '', imageUrl: '', credits: '', mp: -1, mpQuality: "unknown", orientation: "unknown", imageLens: {
        zoom: {
            active: false,
            xp: 0,
            yp: 0,
            zoomP: 100,
        },
        hMirror: false,
        vMirror: false,
        bw: false,
    } }, partial));
export const getDefaultDynaCMSBlockImages = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.IMAGES, label: '', title: '', subtitle: '', images: [], showMode: 'all' }, partial));
export const getDefaultDynaCMSBlockVideoFile = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.VIDEO_FILE, label: "", title: "", subtitle: "", showTextOnPlay: false, height: 480, previewImageUrl: "", autoStart: false, loop: false, startAtSec: undefined, endAtSec: undefined, showControls: true, muted: false, fullScreenAbility: true, videoFileUrl: '', credits: '', lazyRender: false }, partial));
export const getDefaultDynaCMSBlockVideoYouTube = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.VIDEO_YOUTUBE, label: "", title: "", subtitle: "", showTextOnPlay: false, height: 480, previewImageUrl: "", autoStart: false, loop: false, startAtSec: undefined, endAtSec: undefined, muted: false, showControls: false, fullScreenAbility: true, youTubeVideoCode: '', credits: '@ YouTube', lazyRender: false }, partial));
export const getDefaultDynaCMSBlockVideoVimeo = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.VIDEO_VIMEO, label: "", title: "", subtitle: "", showTextOnPlay: false, height: 480, previewImageUrl: "", autoStart: false, loop: false, startAtSec: undefined, endAtSec: undefined, muted: false, showControls: false, fullScreenAbility: true, vimeoVideoCode: '', credits: '', lazyRender: false }, partial));
export const getDefaultDynaCMSBlockSoundSoundCloud = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.SOUND_SOUNDCLOUD, soundCloudUrl: '', height: 320 }, partial));
export const getDefaultDynaCMSBlockMultimedia = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.MULTIMEDIA, label: '', title: '', subtitle: '', media: [], showMode: 'all' }, partial));
export const getDefaultDynaCMSBlockPostFacebook = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.SOCIAL_POST_FACEBOOK, postUrl: '' }, partial));
export const getDefaultDynaCMSBlockPostTwitter = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.SOCIAL_POST_TWITTER, postUrl: '' }, partial));
export const getDefaultDynaCMSBlockPostInstagram = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.SOCIAL_POST_INSTAGRAM, postUrl: '' }, partial));
export const getDefaultDynaCMSBlockPostLinkedin = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.SOCIAL_POST_LINKEDIN, postUrl: '' }, partial));
export const getDefaultDynaCMSBlockPostTikTok = (partial = {}) => (Object.assign({ type: EDynaCMSBlockType.SOCIAL_POST_TIKTOK, postUrl: '' }, partial));
// #endregion "ZikTok"
// #endregion "Post"
