import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
export const Loading = (props) => {
    const { className, style, isLoading, showCircularIcon = true, children, } = props;
    const classes = useStyles({});
    return (_jsxs("div", { className: [classes.root, className].filter(Boolean).join(' '), style: style, children: [children, isLoading && (_jsx("div", { className: classes.loadingContainer, children: showCircularIcon && (_jsx(CircularProgress, { className: classes.loader, variant: "indeterminate", color: "primary" })) }))] }));
};
const useStyles = makeStyles(() => {
    return createStyles({
        root: { position: 'relative' },
        loadingContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        loader: {
            position: 'absolute',
            top: 'calc(50% - 20px)',
            left: 'calc(50% - 20px)',
        },
    });
});
