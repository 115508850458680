import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "../../Box";
export const Thumbnail = (props) => {
    const { index, selected, content, onClick, } = props;
    const handleClick = () => onClick(index);
    return (_jsx(Box, { sx: {
            width: 100,
            height: 50,
            border: '10px solid transparent',
            cursor: 'pointer',
            borderColor: selected ? 'lightgray' : undefined,
            transition: 'border-color 100ms ease-in-out',
            '&:hover': { borderColor: 'gray' },
        }, dataComponentName: ["Thumbnail"], onClick: handleClick, children: content }));
};
