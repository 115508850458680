import {connect} from "react-dynadux";

import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

import {IDynaCMSIntlProviderProps} from "./DynaCMSIntlProvider";

const DynaCMSIntlProviderLazy = lazy(() => import("./DynaCMSIntlProvider"));

export const DynaCMSIntlProvider = connect((props: IDynaCMSIntlProviderProps) => (
  <LazyLoadComponent>
    <DynaCMSIntlProviderLazy {...props}/>
  </LazyLoadComponent>
));
