import {ReactElement} from "react";

import {
  ETKAppCore,
  ETKUserAuthentication,
} from "server-app/dist/interfaces";

import {INotificationMenuOption} from "ui-components/dist/AppContainerFlow";
import {ProgressIcon} from "ui-components/dist/ProgressIcon";

import {IAppStore} from "../../../state/IAppStore";
import {ESignStatus} from "../../user-authnentication/state/userAuthSection";

import {routeAppSettingsPage} from "../routes/routeAppSettingsPage";

import {createIcon} from "ui-components/dist/IconComponent";
import {getNotificationIcon} from "./utils/getNotificationIcon";
import DefaultUserIcon from "@mui/icons-material/AccountCircle";
import ViewProfileIcon from '@mui/icons-material/AccountBox';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/ExitToApp';

export const getUserNotificationIcon = (
  {
    userAuth: {
      state: {
        signStatus,
        user: {
          avatarUrl,
          displayName,
        },
      },
    },
  }: IAppStore,
): ReactElement => {
  if (signStatus === ESignStatus.SIGNING_IN) {
    return (
      <ProgressIcon size={19}/>
    );
  }

  if (signStatus === ESignStatus.SIGNED_IN) {
    return (
      getNotificationIcon({
        imgUrl: avatarUrl,
        defaultIcon: createIcon.byMuiIcon(DefaultUserIcon),
        alt: displayName,
      })
    );
  }

  return (
    getNotificationIcon({
      defaultIcon: createIcon.byMuiIcon(DefaultUserIcon),
      alt: 'Not signed in',
      opacity: 0.5,
    })
  );
};

export const getUserNotificationIconOptions = (
  {
    app: {actions: {navigateTo}},
    userAuth: {
      state: {signStatus},
      actions: {
        signIn,
        signOut,
      },
    },
  }: IAppStore,
): INotificationMenuOption[] => {
  const signedIn = signStatus === ESignStatus.SIGNED_IN;

  return [
    {
      hidden: signedIn,
      label: 'Sign-in',
      labelTk: ETKUserAuthentication.SIGN_IN,
      Icon: createIcon.byMuiIcon(LoginIcon),
      onClick: () => signIn('/'),
    },
    {
      hidden: !signedIn,
      label: 'Application settings',
      labelTk: ETKAppCore.APP_SETTINGS,
      Icon: createIcon.byMuiIcon(ViewProfileIcon),
      onClick: () => navigateTo({url: routeAppSettingsPage.getRoutePath()}),
    },
    {
      hidden: !signedIn,
      label: 'Sign-out',
      labelTk: ETKUserAuthentication.SIGN_OUT,
      Icon: createIcon.byMuiIcon(LogoutIcon),
      onClick: () => signOut(null),
    },
  ];
};
