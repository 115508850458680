export var ETKDynaCMS;
(function (ETKDynaCMS) {
    ETKDynaCMS["NEW_ITEMS_AVAILABLE_TITLE"] = "NEW_ITEMS_AVAILABLE_TITLE";
    ETKDynaCMS["NEW_ITEMS_AVAILABLE_SUBTITLE"] = "NEW_ITEMS_AVAILABLE_SUBTITLE";
    ETKDynaCMS["CMS_SETTINGS_CHANGE__Label"] = "CMS_SETTINGS_CHANGE__Label";
    ETKDynaCMS["CMS_SETTINGS_CHANGE__Description"] = "CMS_SETTINGS_CHANGE__Description";
    ETKDynaCMS["ARTICLES_CREATE__Label"] = "ARTICLES_CREATE__Label";
    ETKDynaCMS["ARTICLES_CREATE__Description"] = "ARTICLES_CREATE__Description";
    ETKDynaCMS["ARTICLES_UPDATE__Label"] = "ARTICLES_UPDATE__Label";
    ETKDynaCMS["ARTICLES_UPDATE__Description"] = "ARTICLES_UPDATE__Description";
    ETKDynaCMS["ARTICLES_VIEW__Label"] = "ARTICLES_VIEW__Label";
    ETKDynaCMS["ARTICLES_VIEW__Description"] = "ARTICLES_VIEW__Description";
    ETKDynaCMS["ARTICLES_ARCHIVE__Label"] = "ARTICLES_ARCHIVE__Label";
    ETKDynaCMS["ARTICLES_ARCHIVE__Description"] = "ARTICLES_ARCHIVE__Description";
    ETKDynaCMS["ARTICLES_UNARCHIVE__Label"] = "ARTICLES_UNARCHIVE__Label";
    ETKDynaCMS["ARTICLES_UNARCHIVE__Description"] = "ARTICLES_UNARCHIVE__Description";
    ETKDynaCMS["ARTICLES_DELETE__Label"] = "ARTICLES_DELETE__Label";
    ETKDynaCMS["ARTICLES_DELETE__Description"] = "ARTICLES_DELETE__Description";
    ETKDynaCMS["ARTICLES_UNDELETE__Label"] = "ARTICLES_UNDELETE__Label";
    ETKDynaCMS["ARTICLES_UNDELETE__Description"] = "ARTICLES_UNDELETE__Description";
    ETKDynaCMS["ARTICLES_PUBLISH__Label"] = "ARTICLES_PUBLISH__Label";
    ETKDynaCMS["ARTICLES_PUBLISH__Description"] = "ARTICLES_PUBLISH__Description";
    ETKDynaCMS["ARTICLES_UNPUBLISH__Label"] = "ARTICLES_UNPUBLISH__Label";
    ETKDynaCMS["ARTICLES_UNPUBLISH__Description"] = "ARTICLES_UNPUBLISH__Description";
})(ETKDynaCMS || (ETKDynaCMS = {}));
export const dynaCMSHardcodedTranslations = {
    [ETKDynaCMS.NEW_ITEMS_AVAILABLE_TITLE]: {
        en: "View newer",
        el: "Προβολή νέων",
        de: "Neu anzeigen",
    },
    [ETKDynaCMS.NEW_ITEMS_AVAILABLE_SUBTITLE]: {
        en: "There are new articles, click here to view them",
        el: "Υπάρχουν νέα άρθρα πάτηστε εδώ για να τα δείτε",
        de: "Es gibt neue Artikel, klicken Sie hier, um sie zu sehen",
    },
    [ETKDynaCMS.CMS_SETTINGS_CHANGE__Label]: {
        en: "Change CMS Settings",
        el: "Αλλαγές ρυθμίσεων CMS",
        de: "Ändern Sie die CMS-Einstellungen",
    },
    [ETKDynaCMS.CMS_SETTINGS_CHANGE__Description]: {
        en: "Change settings of the content system",
        el: "Αλλαγές ρυθμίσεων το συστήματος περιοχομένου",
        de: "Ändern Sie die Einstellungen des Inhaltssystems",
    },
    [ETKDynaCMS.ARTICLES_CREATE__Label]: {
        en: "Create articles",
        el: "Δημιουργία άρθρων",
        de: "Erstellen Sie Artikel",
    },
    [ETKDynaCMS.ARTICLES_CREATE__Description]: {
        en: "Create articles in the system",
        el: "Δημιουργία νέων άρθρων στο σύστημα",
        de: "Erstellen Sie Artikel im System",
    },
    [ETKDynaCMS.ARTICLES_UPDATE__Label]: {
        en: "Update articles",
        el: "Ενημέρωση άρθρων",
        de: "Aktualisieren Sie Artikel",
    },
    [ETKDynaCMS.ARTICLES_UPDATE__Description]: {
        en: "Can change the content the articles",
        el: "Ενημέρωση & διόρθωση άρθρων",
        de: "Ändern Sie den Inhalt der Artikel",
    },
    [ETKDynaCMS.ARTICLES_VIEW__Label]: {
        en: "View articles",
        el: "Προβολή άρθρων",
        de: "Artikel anzeigen",
    },
    [ETKDynaCMS.ARTICLES_VIEW__Description]: {
        en: "Access to see all articles of the system",
        el: "Πρόσβαση σε όλα τα άρθρα για ανάγνωση",
        de: "Zugriff auf alle Artikel des Systems",
    },
    [ETKDynaCMS.ARTICLES_ARCHIVE__Label]: {
        en: "Archive articles",
        el: "Αρχειοθέτηση άρθρων",
        de: "Archivieren Sie Artikel",
    },
    [ETKDynaCMS.ARTICLES_ARCHIVE__Description]: {
        en: "Archive articles for future reference",
        el: "Αρχειοθέτηση άρθρων για μελλοντική αναφορά",
        de: "Archivieren Sie Artikel als Referenz für die Zukunft",
    },
    [ETKDynaCMS.ARTICLES_UNARCHIVE__Label]: {
        en: "Unarchive articles",
        el: "Επαναφορά άρθρων από το αρχείο",
        de: "Entpacken Sie Artikel",
    },
    [ETKDynaCMS.ARTICLES_UNARCHIVE__Description]: {
        en: "Unarchive articles from the system",
        el: "Επαναφορά άρθρων από το αρχείο στο σύστημα",
        de: "Entpacken Sie Artikel aus dem System",
    },
    [ETKDynaCMS.ARTICLES_DELETE__Label]: {
        en: "Delete articles",
        el: "Διαγραφή άρθρων",
        de: "Löschen Sie Artikel",
    },
    [ETKDynaCMS.ARTICLES_DELETE__Description]: {
        en: "Move articles to trash bin for deletion",
        el: "Μεταφορά άρθρων στον κάδο ανακύκλωσης για διαγραφή",
        de: "Verschieben Sie Artikel in den Papierkorb zum Löschen",
    },
    [ETKDynaCMS.ARTICLES_UNDELETE__Label]: {
        en: "Undelete articles",
        el: "Επαναφορά διαγραμμένων άρθρων",
        de: "Artikel wiederherstellen",
    },
    [ETKDynaCMS.ARTICLES_UNDELETE__Description]: {
        en: "Move articles back from the trash bin",
        el: "Επαναφορά διαγραμμένων άρθρων από τον κάδο ανακύκλωσης",
        de: "Verschieben Sie Artikel aus dem Papierkorb zurück",
    },
    [ETKDynaCMS.ARTICLES_PUBLISH__Label]: {
        en: "Publish articles",
        el: "Δημοσίευση άρθρων",
        de: "Artikel veröffentlichen",
    },
    [ETKDynaCMS.ARTICLES_PUBLISH__Description]: {
        en: "Publish articles to the world or schedule them for publish",
        el: "Δημοσιεύστε άρθρα στο κοινό ή προγραμματίστε τα για δημοσίευση",
        de: "Artikel veröffentlichen oder für die Veröffentlichung planen",
    },
    [ETKDynaCMS.ARTICLES_UNPUBLISH__Label]: {
        en: "Unpublish articles",
        el: "Ακύρωση δημοσίευσης άρθρων",
        de: "Artikel deaktivieren",
    },
    [ETKDynaCMS.ARTICLES_UNPUBLISH__Description]: {
        en: "Remove articles from publicity",
        el: "Απομάκρυνση άρθρων από τη δημοσιότητα",
        de: "Artikel von der Veröffentlichung entfernen",
    },
};
