import {IDynaCMSArticleHeading} from "server-app/dist/interfaces";

import {TUnitOfTime} from "utils-library/dist/utils";
import {useLoadDBEntityDocsDataPlacesCache} from "ui-components/dist/useLoadDBEntityDocsDataPlacesCache";

import {apiArticleHeaderGetSearch} from "../../api/articles/header/apiArticleHeaderGetSearch";

export interface ISearchArticleHeaders {
  countryIds?: string[];
  groupIds?: string[];
  languageId: string;
  searchText?: string;
  tags?: string[];
  tagsMode?: 'any' | 'all';
}

export interface ICacheArticlesHeaders {
  containerName: string;
  maxSizeInBytes: number;
  ignoreCacheOlderThan?: {
    number: number;
    unitOfTime: TUnitOfTime;
  };
  updateSilentlyExistedContent?: boolean;
  applyAllContentIfLoadedWithinMs?: number;
}

const defaultCache: Partial<ICacheArticlesHeaders> = {
  ignoreCacheOlderThan: {
    number: 3,
    unitOfTime: 'days',
  },
  applyAllContentIfLoadedWithinMs: 1200,
};

export const useLoadArticlesHeadersCache = (
  {
    search,
    cache,
  }: {
    search: ISearchArticleHeaders;
    cache: ICacheArticlesHeaders;
  },
) => {
  const {
    isLoading,
    isLoadingMore,
    hasMore,
    dataPlace,
    newItemsAvailable,
    applyUpdatedContent,
  } = useLoadDBEntityDocsDataPlacesCache<IDynaCMSArticleHeading>({
    search,
    cache: {
      ...defaultCache,
      ...cache,
    },
    load: async (
      {
        skip,
        limit,
        publishedBefore,
      },
    ) => {
      const {articles} = await apiArticleHeaderGetSearch({
        ...search,
        publishedBefore,
        skip,
        limit,
      });
      return articles;
    },
  });

  return {
    isLoading,
    isLoadingMore,
    hasMore,
    dataPlace,
    newItemsAvailable,
    applyUpdatedContent,
  };
};
