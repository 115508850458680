import {useState} from "react";
import {IDynaError} from "dyna-error";

import {
  IDynaCMSArticle,
  IDynaCMSFont,
  IDynaCMSContentValidation,
  IDynaCMSLoadArticleResult,
  getDefaultIDynaCMSFont,
  getDefaultDynaCMSArticle,
} from "server-app/dist/interfaces";

import {
  ESource,
  useLoadDataCache,
} from "ui-components/dist/useLoadDataCache";

import {apiArticleGet} from "../../api/articles/article/apiArticleGet";

export const useLoadArticleCache = (
  {
    search,
    cache,
    reloadDep,
  }: {
    search: {
      groupId: string;
      languageId: string;
      countryId: string;
      articleId?: string; // This...
      urlId?: string;     // ... or this is required
      purpose?: "view" | "preview" | "manage";
    };
    cache: {
      containerName: string;
      maxSizeInBytes: number;
      updateSilentlyExistedContent: boolean;
    };
    reloadDep?: any;
  },
): {
  isLoading: boolean;
  article: IDynaCMSArticle;
  defaultArticleFontFamilyGeneral: IDynaCMSFont;
  contentValidation: IDynaCMSContentValidation;
  userCanEdit: boolean;
  source: ESource;
  loadError?: IDynaError;
} => {
  const [defaultArticleFontFamilyGeneral, setDefaultArticleFontFamilyGeneral] = useState<IDynaCMSFont>(getDefaultIDynaCMSFont());
  const [contentValidation, setContentValidation] = useState<IDynaCMSContentValidation>({blocks: {}});
  const [userCanEdit, setUserCanEdit] = useState(false);

  const {
    isLoading,
    data: article,
    source,
    error: loadError,
  } = useLoadDataCache<IDynaCMSArticle, any>({
    defaultData: getDefaultDynaCMSArticle(),
    search,
    cache,
    reloadDep,
    load: async () => {
      const ssrArticleLoadData99123: IDynaCMSLoadArticleResult | null = (window as any).ssrArticleLoadData99123 || null;
      if (
        ssrArticleLoadData99123 &&
        (
          ssrArticleLoadData99123.article.id === search.articleId ||
          ssrArticleLoadData99123.article.urlId === search.urlId
        )
      ) {
        const {
          article,
          defaultArticleFontFamilyGeneral,
          contentValidation,
          userCanEdit,
        } = ssrArticleLoadData99123;
        setDefaultArticleFontFamilyGeneral(defaultArticleFontFamilyGeneral);
        setContentValidation(contentValidation);
        setUserCanEdit(userCanEdit);
        return article;
      }

      const {
        article,
        defaultArticleFontFamilyGeneral,
        contentValidation,
        userCanEdit,
      } = await apiArticleGet({
        groupId: search.groupId,
        languageId: search.languageId,
        countryId: search.countryId,
        articleId: search.articleId,
        urlId: search.urlId,
        purpose: search.purpose,
      });
      setDefaultArticleFontFamilyGeneral(defaultArticleFontFamilyGeneral);
      setContentValidation(contentValidation);
      setUserCanEdit(userCanEdit);
      return article;
    },
  });
  return {
    isLoading,
    article,
    defaultArticleFontFamilyGeneral,
    contentValidation,
    userCanEdit,
    source,
    loadError,
  };
};
