var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { createRef } from "react";
import { dynaDebounce } from "dyna-debounce";
import { syncFunctions } from "dyna-sync";
import { Box } from "../../Box";
import { ENavArrowsMode, } from "../interfaces";
import LeftArrow from '@mui/icons-material/KeyboardArrowLeft';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
const DEFAULT_PRELOAD = 3;
const DEFAULT_ANIMATION = 250;
export class SlideViewer extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = createRef();
        this.state = {
            width: 0,
            slides: this.props.slides.map((_slide, index) => {
                return {
                    left: -window.innerWidth,
                    prepare: this.getPrepare(index),
                    show: index === this.props.index,
                };
            }),
        };
        this.handleWindowResize = () => {
            this.observeWidth();
        };
        this.observeWidth = (...args_1) => __awaiter(this, [...args_1], void 0, function* (delay = 100) {
            yield new Promise(r => setTimeout(r, delay));
            const { current: container } = this.containerRef;
            if (!container)
                return;
            const width = parseFloat(getComputedStyle(container).width);
            if (this.state.width === width)
                return;
            syncFunctions(done => this.setState({ width }, done), done => {
                this.showProperSlide();
                done();
            });
        });
        this.renderSlide = (slide, index) => {
            const { animation = DEFAULT_ANIMATION } = this.props;
            const { slides, width, } = this.state;
            const { left, prepare, show, } = slides[index];
            return (_jsx("div", { style: {
                    position: 'absolute',
                    height: '100%',
                    width,
                    left,
                    transition: `left ${animation}ms ease-in-out`,
                }, children: this.renderSlideContent(slide, prepare, show) }, index));
        };
        this.observeWidth = dynaDebounce(this.observeWidth, 100);
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.observeWidth(0);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.index !== this.props.index)
            this.showProperSlide();
    }
    getLeft(showIndex, testIndex) {
        const { width } = this.state;
        return (testIndex - showIndex) * width;
    }
    getPrepare(testIndex) {
        const { index, preload = DEFAULT_PRELOAD, } = this.props;
        return Math.abs(index - testIndex) < preload;
    }
    showProperSlide() {
        const { index: showIndex, animation = DEFAULT_ANIMATION, } = this.props;
        syncFunctions(done => this.setState({
            slides: this.state.slides.map((slide, index) => {
                const newSlide = Object.assign(Object.assign({}, slide), { prepare: this.getPrepare(index), left: this.getLeft(showIndex, index) });
                if (index === showIndex)
                    newSlide.show = true;
                return newSlide;
            }),
        }, done), done => setTimeout(done, animation), requestAnimationFrame, done => this.setState({
            // This hides the slide that now is hidden, after the animation.
            slides: this.state.slides.map((slide, index) => (Object.assign(Object.assign({}, slide), { show: index === showIndex }))),
        }, done));
    }
    handleArrowClick(direction) {
        const { index, onChange, } = this.props;
        onChange(index - direction);
    }
    renderArrow(style, direction) {
        const { index, slides, } = this.props;
        const potentialIndex = index + (direction * -1);
        const activeButton = potentialIndex >= 0 && potentialIndex < slides.length;
        return (_jsx(Box, { sx: Object.assign(Object.assign(Object.assign({}, style), { height: '100%', display: 'flex', width: '10%', minWidth: 42, justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent', transition: 'background-color 250ms', '& > svg': {
                    color: '#d7d7d7',
                    width: 42,
                    height: 42,
                    opacity: 0.6,
                    transition: 'opacity 250ms',
                } }), (activeButton
                ? {
                    cursor: "pointer",
                    '&:hover': { backgroundColor: '#80808094' },
                    '& > svg': { color: '#868686' },
                    '&:hover > svg': { opacity: 1 },
                }
                : {})), onClick: () => activeButton && this.handleArrowClick(direction), children: direction === 1
                ? _jsx(LeftArrow, {})
                : _jsx(RightArrow, {}) }));
    }
    renderSlideContent(slide, prepare, show) {
        switch (slide.navArrowsMode || ENavArrowsMode.OVERLAY) {
            case ENavArrowsMode.HIDDEN:
                return (_jsx("div", { style: { height: "100%" }, children: slide.renderContent({
                        prepare,
                        show,
                    }) }));
            case ENavArrowsMode.OVERLAY:
                return (_jsxs("div", { style: {
                        height: '100%',
                        position: "relative",
                    }, children: [slide.renderContent({
                            prepare,
                            show,
                        }), this.renderArrow({
                            position: 'absolute',
                            bottom: 0,
                        }, 1), this.renderArrow({
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                        }, -1)] }));
            case ENavArrowsMode.ASIDE:
                return (_jsxs("div", { style: {
                        display: 'flex',
                        height: '100%',
                    }, children: [this.renderArrow({
                            flex: '0 1',
                            minWidth: '10% !important',
                        }, 1), _jsx("div", { style: { flex: '1 1' }, children: slide.renderContent({
                                prepare,
                                show,
                            }) }), this.renderArrow({
                            flex: '0 1',
                            minWidth: '10% !important',
                        }, -1)] }));
        }
    }
    render() {
        const { sx = {}, dataComponentName, slides, } = this.props;
        return (_jsx(Box, { dataComponentName: [dataComponentName, "SlideViewer"], ref: this.containerRef, sx: Object.assign({ overflow: 'hidden', position: 'relative', height: '100%' }, sx), children: slides.map(this.renderSlide) }));
    }
}
