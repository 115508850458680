import { useState, useEffect, } from "react";
export const useWindowResize = () => {
    const [screenSize, setScreenSize] = useState(getScreenSize());
    useEffect(() => {
        const handleResize = () => setScreenSize(getScreenSize());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return screenSize;
};
const getScreenSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
});
