import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { shortGuid } from "dyna-guid";
import { convertEnumToValueLabel } from "utils-library/dist/utils";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import { getDataComponentName, } from "../ui-interfaces";
import { Box } from "../Box";
import { HelperText, EHelperTextType, } from "../HelperText";
import { FlexContainerHorizontal, FlexItemMax, FlexItemMin, } from "../FlexContainer";
import { Button, EButtonSize, } from "../Button";
export var EIInputSelectVariant;
(function (EIInputSelectVariant) {
    EIInputSelectVariant["FILLED"] = "filled";
    EIInputSelectVariant["OUTLINED"] = "outlined";
    EIInputSelectVariant["STANDARD"] = "standard";
})(EIInputSelectVariant || (EIInputSelectVariant = {}));
export const InputSelect = (props) => {
    const { dataComponentName, native = true, variant = EIInputSelectVariant.STANDARD, name = '', nameOnInput = true, label, helperLabel, ariaLabel = label || "Select", Icon, readOnly, disabled, hidden, required, validationError, options: userOptions = [], optionsEnum = {}, groupedOptions = [], listDeprecatedValues = false, deprecatedValues = [], value, buttons = [], onOpen, onChange, } = props;
    const labelId = useMemo(() => "A" + shortGuid(), []);
    const options = [
        ...userOptions,
        ...convertEnumToValueLabel(optionsEnum),
    ];
    const deprecatedValuesDic = deprecatedValues
        .reduce((acc, v) => {
        acc[v] = true;
        return acc;
    }, {});
    const handleChange = (event) => {
        if (disabled)
            return;
        if (readOnly)
            return;
        onChange && onChange(event.target.value, name);
    };
    const renderOptions = (options) => {
        return options
            .map(checkOptionsForDeprecated)
            .map(renderOption);
    };
    const renderOption = ({ show = true, label, richLabel, disabled, value, }, index) => {
        if (!show)
            return null;
        return native
            ? (_jsx("option", { "aria-label": label || 'None', disabled: disabled || deprecatedValuesDic[value], value: value, hidden: !listDeprecatedValues && deprecatedValuesDic[value], children: label }, index))
            : (_jsx(MenuItem, { "aria-label": label, disabled: disabled || deprecatedValuesDic[value], value: value, hidden: !listDeprecatedValues && deprecatedValuesDic[value], children: richLabel || label }, index));
    };
    const renderGroupedOptions = (groupedOptions) => native
        ? groupedOptions
            .map(({ label, options, }, index) => (_jsx("optgroup", { label: label, "aria-label": label, children: options
                .map(checkOptionsForDeprecated)
                .map(renderOption) }, index)))
        : groupedOptions
            .reduce((acc, { label, richLabel, options, }, groupIndex) => {
            acc.push(_jsx(ListSubheader, { "aria-label": label, children: richLabel || label }, groupIndex));
            options
                .map(checkOptionsForDeprecated)
                .map((option, optionIndex) => renderOption(option, [groupIndex, optionIndex].join('---')))
                .forEach(option => {
                if (option)
                    acc.push(option);
            });
            return acc;
        }, []);
    const checkOptionsForDeprecated = (option) => {
        const isDeprecated = deprecatedValuesDic[option.value];
        if (!isDeprecated)
            return option;
        return Object.assign(Object.assign({}, option), { label: option.label + ' (deprecated)', richLabel: option.richLabel
                ? _jsxs("span", { children: [option.richLabel, " (deprecated)"] })
                : undefined });
    };
    return (_jsx(Box, { dataComponentName: getDataComponentName(dataComponentName, 'InputSelect'), hidden: hidden, children: _jsxs(FormControl, { fullWidth: true, variant: variant, required: required, error: !!validationError, children: [_jsxs(FlexContainerHorizontal, { children: [_jsxs(FlexItemMax, { children: [_jsx(InputLabel, { id: labelId, "aria-label": ariaLabel, children: label }), _jsxs(Select, { fullWidth: true, native: native, labelId: labelId, value: value, IconComponent: Icon
                                        ? () => (_jsx(Box, { sx: {
                                                marginRight: 1,
                                                position: 'relative',
                                                top: 2,
                                            }, children: _jsx(Icon, { width: 28 }) }))
                                        : undefined, inputProps: {
                                        name: nameOnInput ? name : undefined,
                                        disabled: disabled || readOnly, // Prop readOnly should be disabling input/select
                                        value, // Use value prop for native, this passes it to options
                                    }, MenuProps: { style: { zIndex: 4000 } }, onClick: onOpen, onChange: handleChange, children: [renderOptions(options), renderGroupedOptions(groupedOptions)] })] }), _jsx(FlexItemMin, { children: buttons.map(({ show = true, Icon, color, label, title, disabled: buttonDisabled, onClick, }, index) => (_jsx(Button, { sx: { ml: 1 }, size: EButtonSize.SMALL, show: show, Icon: Icon, color: color, title: title, children: label, disabled: buttonDisabled || disabled || readOnly, onClick: onClick }, index))) })] }), _jsx(HelperText, { children: helperLabel }), _jsx(HelperText, { type: EHelperTextType.ERROR, children: validationError })] }) }));
};
