import {IIconComponent} from "ui-components/dist/IconComponent";

import {IAppRoute} from "./IAppRoute";

export interface IAppMenu {
  menuId?: string;
  hidden?: boolean;
  navigateTo: string;
  userHasAllRights?: string[];
  userHasAnyOfRights?: string[];
  countryId?: string;
  languageId?: string;
  /**
   * @deprecated Use the Icon instead
   */
  icon?: JSX.Element;
  Icon?: IIconComponent;
  title: string;
  description?: string;
  content?: JSX.Element;
  children?: (IAppMenu | "DIVIDER")[];
}

export const convertRouteToAppMenu = (route: IAppRoute<any>, overrideMenu: Partial<IAppMenu> = {}): IAppMenu => ({
  menuId: route.menuId,
  icon: route.icon,
  Icon: route.Icon,
  title: route.title,
  description: route.description,
  navigateTo: route.routePath,
  userHasAnyOfRights: route.userHasAnyOfRights,
  userHasAllRights: route.userHasAllRights,
  ...overrideMenu,
});
