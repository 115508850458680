import {
  IDynaCMSBlockImages,
} from "server-app/dist/interfaces";

import {Box} from "ui-components/dist/Box";
import {
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "ui-components/dist/FlexContainer";

import {ViewerImage} from "../ViewerImage";
import {DynaCMSLabel} from "../../commons/DynaCMSLabel";

export interface IViewerImagesProps {
  content: IDynaCMSBlockImages;
  showPhotoPortraitSmaller: boolean;
}

export const ViewerImages = (props: IViewerImagesProps): JSX.Element => {
  const {
    content: {
      label,
      title,
      subtitle,
      images,
    },
    showPhotoPortraitSmaller,
  } = props;

  return (
    <Box
      sx={{
        '> *': {
          marginTop: theme => theme.spacing(2),
          marginBottom: theme => theme.spacing(2),
        },
      }}
    >
      <FlexContainerHorizontal alignVertical="middle">
        <FlexItemMax>
          <Box
            component="h2"
            show={!!title}
            sx={{fontWeight: 'bold'}}
          >
            {title}
          </Box>
        </FlexItemMax>
        <FlexItemMin>
          <DynaCMSLabel children={label}/>
        </FlexItemMin>
      </FlexContainerHorizontal>
      <Box
        show={!!subtitle}
        sx={{paddingLeft: theme => theme.spacing(3)}}
      >
        {subtitle}
      </Box>
      {images.map((image, index) => (
        <ViewerImage
          key={index}
          content={image}
          showPortraitsSmaller={showPhotoPortraitSmaller}
        />
      ))}
    </Box>
  );
};
