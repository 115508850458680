import {ReactElement} from "react";

// @ts-ignore
import {FormattedMessage} from "react-intl";
import {useDynaIntl} from "../useDynaIntl";

export interface IDynaIntlTranslateProps {
  tk: string;
  default?: string;
  values?: Record<string, string | number | Date>;
}

/**
 * Translate a translation key using dyna intl with react component
 */
export const DynaIntlTranslate = (props: IDynaIntlTranslateProps): ReactElement | null => {
  const {
    tk,
    default: _default,
    values,
  } = props;

  if (!tk) return null;

  return (
    <FormattedMessage
      id={tk}
      defaultMessage={_default}
      values={values}
    />
  );
};

/**
 * Translate a translation key using dyna intl that returns a string
 */
export const dynaIntlTranslate = (props: IDynaIntlTranslateProps): string => {
  const {
    tk,
    default: _default,
    values,
  } = props;

  const dynaIntl = useDynaIntl();

  return dynaIntl.translalate({
    tk,
    default: _default,
    values,
  });
};
