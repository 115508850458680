import {
  useIntl,
} from "react-intl";


export const useDynaIntl = () => {
  const intl = useIntl();

  return {
    translalate: (
      {
        tk,
        values,
        default: _default,
        description,
      }: {
        tk: string;
        default?: string;
        description?: string;
        values?: Record<string, string | number | Date>;
      },
    ): string => {
      return intl.formatMessage(
        {
          id: tk,
          defaultMessage: _default,
          description,
        },
        values,
      );
    },
  };
};
