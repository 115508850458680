import { jsx as _jsx } from "react/jsx-runtime";
/**
 * UnsortedList renders ul & li cleaning the layout styles
 */
export const UnsortedList = (props) => {
    const { inline = false, children, } = props;
    return (_jsx("ul", { style: {
            margin: 0,
            padding: 0,
        }, children: children
            .map((child, index) => (_jsx("li", { style: {
                listStyleType: 'none',
                display: inline ? 'inline' : undefined,
            }, children: child }, index))) }));
};
