import {createDynaCache} from "dyna-cache";
import {
  API_PATH_ApiDynaCMSSettingsBasicGet,
  IApiDynaCMSSettingsBasicGetRequest,
  IDynaCMSSettingsBasic,
} from "server-app/dist/interfaces";

import {apiFetch} from "../../../../api/apiFetch";

const apiDynaCMSSettingsBasicGetCore = async (args: IApiDynaCMSSettingsBasicGetRequest = {}): Promise<IDynaCMSSettingsBasic> => {
  return apiFetch.request<IApiDynaCMSSettingsBasicGetRequest, null, IDynaCMSSettingsBasic>({
    method: 'GET',
    path: API_PATH_ApiDynaCMSSettingsBasicGet,
    query: args,
  });
};

const cacheEngine = createDynaCache({load: apiDynaCMSSettingsBasicGetCore});

export const apiDynaCMSSettingsBasicGet = async (cached: boolean): Promise<IDynaCMSSettingsBasic> => {
  return cached
    ? cacheEngine.load({cached: true})
    : cacheEngine.load({cached: false}, true);
};
