import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
export const DynaResponsiveImageByScreen = (props) => {
    const { className, imgStyle = {}, srcSet, alt, content, horizontalMirror, verticalMirror, blackAndWhite, zoom, onLoad, onError, } = props;
    const [isLoaded, setIsLoaded] = useState(false);
    const handleLoad = () => {
        setIsLoaded(true);
        onLoad && onLoad();
    };
    if (zoom && (verticalMirror || horizontalMirror)) {
        return (_jsxs("div", { children: ["DynaResponsiveImage: ", _jsx("code", { children: "zoom" }), " cannot work with ", _jsx("code", { children: "horizontalMirror" }), " or ", _jsx("code", { children: "verticalMirror" }), "."] }));
    }
    return (_jsxs("div", { className: className, style: {
            position: 'relative',
            overflow: 'hidden',
        }, "data-component-name": "DynaResponsiveImage", children: [_jsxs("picture", { children: [_jsx("source", { media: "(max-width: 767px)", srcSet: [
                            `${srcSet.W192} 128w`,
                            `${srcSet.W384} 240w`,
                            `${srcSet.W384} 320w`,
                            `${srcSet.W768} 640w`,
                        ]
                            .join(', '), sizes: "100vw" }), _jsx("source", { media: "(min-width: 768px)", srcSet: [
                            `${srcSet.W192} 128w`,
                            `${srcSet.W384} 240w`,
                            `${srcSet.W384} 320w`,
                            `${srcSet.W768} 640w`,
                            `${srcSet.W1024} 960w`,
                            `${srcSet.W2048} 2048w`,
                            `${srcSet.W4096} 4096w`,
                        ]
                            .join(', '), sizes: "50vw" }), _jsx("img", { width: "100%", alt: alt, src: srcSet.main, style: Object.assign({ transform: [
                                horizontalMirror ? 'scaleX(-1)' : '',
                                verticalMirror ? 'scaleY(-1)' : '',
                                zoom ? `scale(${zoom.zoom})` : '',
                            ].filter(Boolean).join(' '), transformOrigin: zoom ? `${zoom.percentageX}% ${zoom.percentageY}%` : undefined, filter: blackAndWhite ? 'grayscale(100%)' : undefined }, imgStyle), onLoad: handleLoad, onError: onError })] }), _jsx("div", { style: {
                    display: isLoaded ? undefined : 'none',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }, children: content })] }));
};
