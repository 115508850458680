import {
  API_PATH_ApiAppOptionsGet,
  IApiAppOptionsGetResponse,
} from "server-app/dist/interfaces";

import {apiFetch} from "../../../../api/apiFetch";

export const apiAppOptionsGet = async (): Promise<IApiAppOptionsGetResponse> => {
  return apiFetch.request<null, null, IApiAppOptionsGetResponse>({
    method: 'GET',
    path: API_PATH_ApiAppOptionsGet,
  });
};
