import {ECompany} from "server-app/dist/interfaces";

import {
  appVersion,
  appCodeName,
  builtAt,
} from "../../core/application/config/appVersion";

import {
  ICompanyConfig,
  EAppMode,
  HOST,
  host,
} from "../ICompanyConfig";

import {EProgressIconType} from "ui-components/dist/ProgressIcon";
import {EThemeName} from "ui-components/dist/ThemeProvider";

export const theGirlGunsProductionConfig: ICompanyConfig = {
  company: ECompany.THE_GIRL_GUNS,
  mode: EAppMode.PRODUCTION,
  appName: 'The Girl Guns',
  appDescription: 'Girls portal, Fashion, Beauty, Sex & Lifestyle',
  appVersion,
  appCodeName,
  progressIconType: EProgressIconType.DOUBLE_PULSE,
  companyId: 'tgg-com',
  lightTheme: EThemeName.FASHIONX_LIGHT,
  darkTheme: EThemeName.FASHIONX_DARK,
  apiBaseUrl: window.location.origin,
  webBaseUrl: window.location.origin,
  googleMapsApiKey: "AIzaSyCK8ups8L2mke1XPN8hUynDrXyNAsApc7g", // Google Maps key is website referrer restricted
  defaultLanguageId: 'en',
  builtAt,
  sentryIoDsn: "",
  workerActive: true,
  signInPageImage: undefined,
  termsOfUseArticles: [],
  aboutArticles: [],
  contactArticles: [],
};

export const companyConfigsTheGirlGuns: Record<HOST, ICompanyConfig> = {
  "www.thegirlguns.com": theGirlGunsProductionConfig,
  "staging.thegirlguns.com": {
    ...theGirlGunsProductionConfig,
    appName: 'STG The Girl Guns',
  },
  "localhost:3640": {
    ...theGirlGunsProductionConfig,
    appName: 'DEV The Girl Guns',
    workerActive: false,
    apiBaseUrl: 'http://localhost:4020',
    webBaseUrl: `http://${host}`,
  },
};
