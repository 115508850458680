import {ECompany} from "server-app/dist/interfaces";

import {
  appVersion,
  appCodeName,
  builtAt,
} from "../../core/application/config/appVersion";

import {
  ICompanyConfig,
  EAppMode,
  HOST,
  host,
} from "../ICompanyConfig";

import {EProgressIconType} from "ui-components/dist/ProgressIcon";
import {EThemeName} from "ui-components/dist/ThemeProvider";

export const anelCompanyProductionConfig: ICompanyConfig = {
  company: ECompany.ANEL_COMPANY,
  mode: EAppMode.PRODUCTION,
  appName: 'Anel company',
  appDescription: 'Software development engineering company',
  appVersion,
  appCodeName,
  progressIconType: EProgressIconType.DOUBLE_PULSE,
  companyId: 'net-fashion',
  lightTheme: EThemeName.FASHIONX_LIGHT,
  darkTheme: EThemeName.FASHIONX_DARK,
  apiBaseUrl: window.location.origin,
  webBaseUrl: window.location.origin,
  googleMapsApiKey: "AIzaSyCK8ups8L2mke1XPN8hUynDrXyNAsApc7g", // Google Maps key is website referrer restricted
  defaultLanguageId: 'en',
  builtAt,
  initialIsLoadingPage: true,
  workerActive: true,
  sentryIoDsn: "",
  signInPageImage: undefined,
  termsOfUseArticles: [],
  aboutArticles: [],
  contactArticles: [],
};

export const companyConfigsAnelCompany: Record<HOST, ICompanyConfig> = {
  "www.anel.co": anelCompanyProductionConfig,
  "staging.anel.co": {
    ...anelCompanyProductionConfig,
    appName: 'STG Anel company',
  },
  "localhost:3670": {
    ...anelCompanyProductionConfig,
    appName: 'DEV Anel company',
    workerActive: false,
    apiBaseUrl: 'http://localhost:4020',
    webBaseUrl: `http://${host}`,
  },
};
