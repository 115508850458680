import {
  IDynaCMSArticleHeading,
  EPublishedFilter,
  EDynaCMSHeadingStyle,
  EDynaCMSFontFamily,
  IApiArticleHeaderGetSearchBodyRequestV2,
} from "server-app/dist/interfaces";
import {shuffleArray} from "dyna-loops";

import {arrayIndices} from "utils-library/dist/array";

import {Box} from "ui-components/dist/Box";
import {Collapse} from "ui-components/dist/Collapse";
import {UnsortedList} from "ui-components/dist/UnsortedList";
import {Condition} from "ui-components/dist/Condition";
import {
  GridContainer,
  GridItem,
} from "ui-components/dist/Grid";
import {IsLoadingBox} from "ui-components/dist/IsLoadingBox";
import {IconText} from "ui-components/dist/IconText";
import {useBreakpointDevice} from "ui-components/dist/useBreakpointDevice";
import {useLoadDataCache} from "ui-components/dist/useLoadDataCache";

import {IAppStore} from "../../../../state/IAppStore";

import {DynaCMSFont} from "../DynaCMSFont";
import {DynaCMSFontFamily} from "../DynaCMSFontFamily";
import {DynaCMSArticleLink} from "../DynaCMSArticleLink";
import {DynaCMSArticleHeaderViewerEdem} from "../DynaCMSArticleHeaderViewerEdem";

import {TinySmallHeadingViewer} from "../../components/article-header-viewers/HeaderViewerEva/components/TinySmallHeadingViewer";

import {apiArticleHeaderGetSearch} from "../../api/articles/header/apiArticleHeaderGetSearch";

import {createIcon} from "ui-components/dist/IconComponent";
import BulletIcon from '@mui/icons-material/GraphicEq';

export interface IDynaCMSRelatedArticlesProps {
  store: IAppStore;
  h: number;  // Heading number for h1, h2, etc..
  sourceArticle: IDynaCMSArticleHeading;
  loadPublishedBeforeSourceArticle: boolean;
  header?: string;
  topBottomSpacing: number;
  format: ERelatedArticlesFormat;
}

export enum ERelatedArticlesFormat {
  SIX_PACK = "SIX_PACK",
  TWO_READ_MORE_INLINE = "TWO_READ_MORE_INLINE",
  TINY_SMALL_PHOTO_TEXT = "TINY_SMALL_PHOTO_TEXT",
}

export const DynaCMSRelatedArticles = (props: IDynaCMSRelatedArticlesProps): JSX.Element | null => {
  const {
    store: {
      dynaCMS: {
        state: {
          settings: {defaultArticleFontFamilyGeneral},
          languageId,
          countryIds,
        },
      },
    },
    h,
    header,
    topBottomSpacing,
    sourceArticle,
    loadPublishedBeforeSourceArticle,
    format,
  } = props;
  const {isMobile} = useBreakpointDevice();

  const ignoreTags = [
    'front-page',
    'top-article',
    'feed-article',
  ];

  const {
    data: relatedArticles,
    isLoading,
  } = useLoadDataCache<IDynaCMSArticleHeading[], IApiArticleHeaderGetSearchBodyRequestV2>({
    defaultData: [],
    reloadDep: [sourceArticle.id],
    cache: {
      containerName: `DynaCMSRelatedArticles`,
      maxSizeInBytes: 100000,
      updateSilentlyExistedContent: false,
    },
    search: {
      groupIds:
        sourceArticle.tags.length
          ? []
          : [sourceArticle.groupId],
      languageId,
      countryIds,
      searchText: '',
      tags:
        sourceArticle
          .tags
          .filter(tag => !ignoreTags.includes(tag)),
      tagsMode: "any",
      published: EPublishedFilter.PUBLISHED,
      publishedBefore: loadPublishedBeforeSourceArticle ? sourceArticle.publishedAt : undefined,
      publishedInLastDays: 15,
      deleted: false,
      archived: false,
      skip: 0,
      limit: 20,
    },
    load: async search => {
      if (!sourceArticle.id) return []; // Exit, the source article is not yet loaded
      const {articles} = await apiArticleHeaderGetSearch(search);
      return shuffleArray(
        articles
          .filter(a => a.id !== sourceArticle.id),
      );
    },
  });

  const renders: Record<ERelatedArticlesFormat, () => JSX.Element> = {
    [ERelatedArticlesFormat.SIX_PACK]: () => (
      <GridContainer
        spacing={isMobile ? 2 : 3}
        verticalAlign="end"
      >
        {arrayIndices(isMobile ? 3 : 6)
          .map(index => {
            const article = relatedArticles[index];
            if (!article) return null;
            return (
              <GridItem
                tablet={4}
                key={index}
              >
                <DynaCMSFont font={defaultArticleFontFamilyGeneral}>
                  <DynaCMSArticleHeaderViewerEdem
                    h={h + 1}
                    headingStyleList={EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_PHOTO_TEXT}
                    articleHeading={relatedArticles[index]}
                  />
                </DynaCMSFont>
              </GridItem>
            );
          })
        }
      </GridContainer>
    ),
    [ERelatedArticlesFormat.TWO_READ_MORE_INLINE]: () => (
      <UnsortedList>
        {relatedArticles
          .slice(0, 2)
          .map((article, index) => (
            <DynaCMSArticleLink
              key={index}
              article={article}
            >
              <Box
                sx={{
                  marginBottom: '1px',
                  width: isMobile ? undefined : '70%',
                  fontSize: theme => theme.typography.fontSize * 1.2,
                  padding: theme => theme.spacing(1),
                  borderLeft: theme => `2px solid ${theme.palette.grayShades.gray7}`,
                  backgroundColor: theme => theme.palette.grayShades.gray1,
                }}
              >
                <IconText Icon={createIcon.byMuiIcon(BulletIcon)}>
                  <b>{article.title}</b>
                  {article.description ? " " : null}
                  <Box
                    component="i"
                    sx={{fontSize: theme => theme.typography.fontSize}}
                  >
                    {article.description}
                  </Box>
                </IconText>
              </Box>
            </DynaCMSArticleLink>
          ))
        }
      </UnsortedList>
    ),
    [ERelatedArticlesFormat.TINY_SMALL_PHOTO_TEXT]: () => (
      <UnsortedList>
        {relatedArticles
          .slice(0, 2)
          .map((article, index) => (
            <DynaCMSArticleLink
              key={index}
              article={article}
            >
              <TinySmallHeadingViewer
                article={article}
                showPhoto
              />
            </DynaCMSArticleLink>
          ))
        }
      </UnsortedList>
    ),
  };

  return (
    <Box
      dataComponentName="RelatedArticles"
      component="aside"
      sx={{
        marginTop: theme => theme.spacing(topBottomSpacing),
        marginBottom: theme => theme.spacing(topBottomSpacing),
      }}
    >
      <Box sx={{textAlign: 'center'}}>
        <IsLoadingBox
          isLoading={isLoading}
          iconSize={4}
          inline
        />
      </Box>

      <Condition if={!!header}>
        <Collapse expanded={!isLoading}>
          <DynaCMSFontFamily
            sx={{
              opacity: 0.8,
              margin: theme => theme.spacing(2),
            }}
            component={`h${h}` as any}
            fontFamily={EDynaCMSFontFamily.F186_FASHION_EMPIRE_FS}
            fontSize={64}
            bold
            letterSpacing={8}
          >
            {header}
          </DynaCMSFontFamily>
        </Collapse>
      </Condition>

      {renders[format]()}

    </Box>
  );
};
