import { encrypt, decrypt, } from "dyna-crypt";
import { JSONParse } from "../utils/JSONParse";
/**
 * XenCrypt uses AES-128 to encrypt and decrypt text or objects, and the ciphertext can be easily copy-pasted and shared in URLs without any extra formatting.
 *
 * The ciphertext has a minimum length of 60 characters.
 */
export class XenCrypt {
    constructor(config) {
        this.config = config;
    }
    encryptText(plainText) {
        if (!plainText)
            return "";
        return encodeText(encrypt(plainText, this.config.encryptionKey));
    }
    decryptText(cipherText) {
        return decrypt(decodeText(cipherText), this.config.encryptionKey) || null;
    }
    encryptObject(plainObject) {
        return encodeText(encrypt(JSON.stringify(plainObject), this.config.encryptionKey));
    }
    decryptData(cipherObject) {
        const decrypted = decrypt(decodeText(cipherObject), this.config.encryptionKey);
        if (!decrypted)
            return null;
        const { data, error, } = JSONParse({
            serialized: decrypted,
            default: {},
        });
        if (error) {
            console.error(`XenCrypt.decryptData error 51996785418690: Data decrypted as success but cannot be parsed!`, {
                decrypted,
                error,
            });
            return null;
        }
        return data;
    }
}
export const isXenCipher = (value) => {
    if (typeof value !== "string")
        return "Xen cipher should be a string";
    if (value.length < 50)
        return "Xen cipher should bigger text";
    if (!value.startsWith(prefixer))
        return "Is not a Xen cipher";
    return "";
};
const prefixer = 'xen';
const splitter = 'hx73c';
const encodeText = (sourceText) => prefixer
    + encodeURIComponent(sourceText)
        .replace(/%/g, splitter);
const decodeText = (encodedText) => decodeURIComponent(encodedText
    .slice(prefixer.length)
    .replace(new RegExp(splitter, 'g'), '%'));
