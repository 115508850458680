import { useState, } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useResizeDetector } from "react-resize-detector";
import { useIsMounted } from "./useIsMounted";
export const useResizeEvent = ({ refreshRate = 500, minRefreshRate = refreshRate, skipOnMount = true, leading = true, trailing = true, onResize = () => undefined, } = {}) => {
    const [mountEventCall, setMountEventCall] = useState(false);
    const [dimension, setDimension] = useState({
        width: 0,
        height: 0,
    });
    const getIsMounted = useIsMounted();
    const getDimension = (width, height) => ({
        width,
        height,
    });
    const handleContainerResize = useDebouncedCallback((width, height) => {
        if (width === undefined)
            return; // 4TS
        if (height === undefined)
            return; // 4TS
        const currentDimension = getDimension(width, height);
        if (width === 0 && height === 0)
            return; // Ignore this useDebouncedCallback's call
        if (!mountEventCall) {
            setMountEventCall(true);
            setDimension(currentDimension);
            if (!skipOnMount) {
                onResize({
                    width,
                    height,
                    diffPercentage: 0,
                });
            }
            return;
        }
        const widthDiffPercentage = Math.abs(100 * (width - dimension.width) / dimension.width);
        const heightDiffPercentage = Math.abs(100 * (height - dimension.height) / dimension.height);
        const diffPercentage = (widthDiffPercentage + heightDiffPercentage) / 2;
        const sameDimension = diffPercentage === 0;
        setDimension(currentDimension);
        if (!sameDimension && getIsMounted()) {
            onResize({
                width,
                height,
                diffPercentage,
            });
        }
    }, refreshRate, {
        leading,
        trailing,
        maxWait: minRefreshRate,
    });
    const { ref } = useResizeDetector({
        skipOnMount: false,
        onResize: handleContainerResize,
    });
    return Object.assign({ ref: ref }, dimension);
};
