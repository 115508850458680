import {useRef} from "react";

import {DynaResponsiveImage} from "dyna-image";

import {IDynaCMSBlockImage} from "server-app/dist/interfaces";

import {Box} from "ui-components/dist/Box";

import {useBreakpointDevice} from "ui-components/dist/useBreakpointDevice";
import {useKeyStatus} from "ui-components/dist/useKeyStatus";

import {
  Theme,
  SxProps,
} from "ui-components/dist/ThemeProvider";

import {getImageSrcSet} from "ui-components/dist/ui-interfaces";

export interface IDynaCMSImageCoreProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  content: IDynaCMSBlockImage;
  children?: any;
  onMouseMove?: (xPercentage: number, yPercentage: number) => void;
  onClick?: (xPercentage: number, yPercentage: number) => void;
}

export const DynaCMSImageCore = (props: IDynaCMSImageCoreProps): JSX.Element => {
  const {
    sx = {},
    dataComponentName,
    content: {
      imageUrl,
      label,
      caption,
      imageLens,
    },
    children,
    onMouseMove,
    onClick,
  } = props;

  const refRoot = useRef<HTMLDivElement>(null);
  const {isMobile} = useBreakpointDevice();
  const {altKeyDown} = useKeyStatus();

  const handleMouseMove = (event: React.MouseEvent): void => {
    if (!onMouseMove) return;
    if (!refRoot.current) return;
    if (event.buttons !== 1) return; // The mouse is not down (clicked)

    const {
      x,
      y,
    } = getPercentageXY(refRoot.current, event);
    onMouseMove(x, y);
  };

  const handleClick = (event: React.MouseEvent): void => {
    if (altKeyDown) window.open(imageUrl, '_blank');

    if (!onClick) return;
    if (!refRoot.current) return;

    const {
      x,
      y,
    } = getPercentageXY(refRoot.current, event);
    onClick(x, y);
  };

  const getPercentageXY = (element: HTMLDivElement, event: React.MouseEvent): { x: number; y: number } => {
    const elementRect = element.getBoundingClientRect();
    return {
      x: 100 * (event.clientX - elementRect.x) / element.clientWidth,
      y: 100 * (event.clientY - elementRect.y) / element.clientHeight,
    };
  };

  return (
    <Box
      sx={{
        userSelect: 'none',
        ...sx,
      }}
      dataComponentName={[dataComponentName, "DynaCMSImageCore"]}
      ref={refRoot}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      <DynaResponsiveImage
        relation={isMobile ? "screen" : "container"}
        srcSet={getImageSrcSet(imageUrl)}
        content={<>{children}</>}
        alt={[caption, label].join(' ').trim()}
        horizontalMirror={imageLens?.hMirror}
        verticalMirror={imageLens?.vMirror}
        blackAndWhite={imageLens?.bw}
        zoom={
          imageLens?.zoom
          && imageLens.zoom.active
            ? {
              percentageX: imageLens?.zoom.xp,
              percentageY: imageLens?.zoom.yp,
              zoom: imageLens?.zoom.zoomP / 100,
            }
            : undefined
        }
      />
    </Box>
  );
};
