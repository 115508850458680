var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemo, useState, } from "react";
import { hashMd5 } from "utils-library/dist/hash-md5";
import { DataLimitedContainerMemory } from "utils-library/dist/data-limited-container-memory";
import { useLoadData } from "../useLoadData";
import { usePerformanceMemo } from "../usePerformanceMemo";
export var ESource;
(function (ESource) {
    ESource["DEFAULT"] = "DEFAULT";
    ESource["CACHE"] = "CACHE";
    ESource["ACTUAL"] = "ACTUAL";
})(ESource || (ESource = {}));
export const useLoadDataCache = ({ defaultData, search, cache: { containerName, maxSizeInBytes, updateSilentlyExistedContent = true, }, reloadDep, load, }) => {
    const [source, setSource] = useState(ESource.DEFAULT);
    const cache = useMemo(() => new DataLimitedContainerMemory({
        containerName,
        maxSizeInBytes,
    }), []);
    const searchId = usePerformanceMemo(() => hashMd5(search), search);
    const { data: cachedDoc } = useLoadData({
        defaultData: null,
        load: () => __awaiter(void 0, void 0, void 0, function* () {
            const result = yield cache.load(searchId);
            return (result === null || result === void 0 ? void 0 : result.data) || null;
        }),
        onLoad: data => {
            if (!data)
                return;
            setSource(ESource.CACHE);
        },
        reloadDep,
    });
    const { isLoading, data: actualDoc, error, } = useLoadData({
        defaultData: null,
        load: () => load(search),
        onLoad: data => {
            if (!data)
                return;
            setSource(ESource.ACTUAL);
            cache
                .save(searchId, data)
                .catch(error => console.error('useLoadDataCache error 20240208095920: Error saving to cache', error));
        },
        reloadDep,
    });
    return {
        isLoading: !cachedDoc && isLoading,
        data: (updateSilentlyExistedContent
            ? actualDoc || cachedDoc
            : cachedDoc || actualDoc) || defaultData,
        source,
        error: cachedDoc ? undefined : error,
    };
};
