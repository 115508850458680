import {
  EDynaCMSRights,
  EPublishedFilter,
} from "server-app/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";

import NewspaperIcon from "@mui/icons-material/Newspaper";

export interface IDynaCMSArticleListSearchObject {
  countryId: string;
  languageId: string;
  groupIds: string[];
  searchText: string;
  tags: string[];
  published: EPublishedFilter;
  archived: boolean | "both";
  deleted: boolean | "both";
}

export const routeDynaCMSArticlesMgtmPaths: IAppRoute<{ searchObject: string }> = {
  title: 'Articles management',
  description: "Create, edit, publish, delete or archive articles",
  icon: <NewspaperIcon/>,
  routePath: '/dyna-cms/search-articles/:searchObject?',
  getRoutePath: ({searchObject} = {searchObject: ''}) =>
    routeDynaCMSArticlesMgtmPaths.routePath
      .replace(':searchObject?', searchObject),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EDynaCMSRights.ARTICLES_CREATE,
    EDynaCMSRights.ARTICLES_UPDATE,
    EDynaCMSRights.ARTICLES_DELETE,
    EDynaCMSRights.ARTICLES_UNDELETE,
    EDynaCMSRights.ARTICLES_ARCHIVE,
    EDynaCMSRights.ARTICLES_UNARCHIVE,
    EDynaCMSRights.ARTICLES_PUBLISH,
    EDynaCMSRights.ARTICLES_UNPUBLISH,
  ],
  requiresLatestClientVersion: true,
  render: () => null,
};
