import {EUserAuthenticationRights} from "server-app/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import UsersIcon from "@mui/icons-material/PeopleAlt";

export const routeListUsersPaths: IAppRoute<{
  search?: string;
}> = {
  menuId: 'users-management',
  title: 'Users',
  description: 'User Management',
  icon: <UsersIcon/>,
  routePath: '/users/list/:search?/',
  getRoutePath: ({search = ""} = {}) =>
    routeListUsersPaths.routePath
      .replace(':search?', search),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EUserAuthenticationRights.ADMINISTRATOR,
    EUserAuthenticationRights.SYSTEM_ADMINISTRATOR,
  ],
  requiresLatestClientVersion: true,
  render: () => null,
};
