var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { dynaError } from "dyna-error";
import { Canvas2Image } from "./Canvas2Image";
export const cropImage = (img, percentageX1, percentageY1, percentageX2, percentageY2) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        img.src = "url(" + cropImageCore(img, percentageX1, percentageY1, percentageX2, percentageY2) + ")";
    }
    catch (e) {
        const error = dynaError(e);
        console.error('Dyna Image: crop error: ' + error.message, error);
    }
});
export const cropDivBackgroundImage = (divWithBackgroundImage, percentageX1, percentageY1, percentageX2, percentageY2) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const imageURL = divWithBackgroundImage.style.backgroundImage.split('"')[1];
        const croppedImage = yield cropImageByUrl(imageURL, percentageX1, percentageY1, percentageX2, percentageY2);
        divWithBackgroundImage.style.backgroundImage = "url(" + croppedImage + ")";
    }
    catch (e) {
        const error = dynaError(e);
        console.error('Dyna Image: crop error: ' + error.message, error);
    }
});
const cropImageByUrl = (imageURL, percentageX1, percentageY1, percentageX2, percentageY2) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageURL;
        image.setAttribute('crossorigin', 'anonymous');
        image.onload = (() => {
            resolve(cropImageCore(image, percentageX1, percentageY1, percentageX2, percentageY2));
        });
        image.onerror = reject;
    });
};
const cropImageCore = (image, percentageX1, percentageY1, percentageX2, percentageY2) => {
    const x1 = getPxValue(percentageX1, image.width);
    const x2 = getPxValue(percentageX2, image.width);
    const y1 = getPxValue(percentageY1, image.height);
    const y2 = getPxValue(percentageY2, image.height);
    const width = x2 - x1;
    const height = y2 - y1;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(image, x1, // Copy from x
    y1, // Copy from y
    width, // Copy width
    height, // Copy height
    0, // Paste on x
    0, // Paste on y
    width, // Paste width
    height);
    const newImage = Canvas2Image.convertToPNG(canvas, canvas.width, canvas.height);
    return newImage === null || newImage === void 0 ? void 0 : newImage.src;
};
const getPxValue = (percentage, size) => size * percentage / 100;
