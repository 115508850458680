// The config of the app is ALSO the `env_variables.sh`!
// Here we have some standards only configs.
import { getHost } from "utils-library/dist/utils";
import { arrayUnique } from "utils-library/dist/array";
import { ECompany } from "./ECompany";
import { dynaError } from "dyna-error";
export var EAppMode;
(function (EAppMode) {
    EAppMode["PRODUCTION"] = "PRODUCTION";
    EAppMode["DEVELOPMENT"] = "DEVELOPMENT";
})(EAppMode || (EAppMode = {}));
export const getDefaultAppConfigCompany = () => ({
    companyId: '',
    serverAddress: '',
    clientAddress: '',
    appName: '',
    appShortName: '',
    appDescription: '',
    headScript: '',
});
const processEnv = typeof process === "undefined"
    ? {}
    : process.env;
export const appInfoPerCompanyId = {
    [ECompany.XDOC_NO]: {
        appName: 'xDoc.no',
        appShortName: 'xdoc',
        appDescription: 'Billing app',
        // Similar code to headScript at #1083504624 packages/web-app/public/index.html
        headScript: ``,
    },
    [ECompany.ADORE_GR]: {
        appName: 'Adore GR',
        appShortName: 'adore.gr',
        appDescription: 'Μόδα, Ομορφιά, Διακοσμήση, Celebs και Lifestyle',
        // Similar code to headScript at #1083504624 packages/web-app/public/index.html
        headScript: `
      <!-- GoogleAdSense -->
      <script
        async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6486460928215437"
        crossorigin="anonymous"
      ></script>
      <!-- monetag -->
      <meta name="monetag" content="17958504474e78d3d03898da33b7b4a5">
    `,
    },
    [ECompany.ANEL_COMPANY]: {
        appName: 'Anel invent',
        appShortName: 'Anel',
        appDescription: 'Software development engineering company',
        // Similar code to headScript at #1083504624 packages/web-app/public/index.html
        headScript: ``,
    },
    [ECompany.THE_GIRL_GUNS]: {
        appName: 'The girl guns',
        appShortName: 'The girl guns',
        appDescription: 'Girls portal - Fashion, Beauty, Sex & Lifestyle',
        // Similar code to headScript at #1083504624 packages/web-app/public/index.html
        headScript: ``,
    },
    [ECompany.NET_FASHION]: {
        appName: 'net.fashion',
        appShortName: 'net.fashion',
        appDescription: 'Fashion portal - Beauty, Deco, Celebs & Lifestyle',
        // Similar code to headScript at #1083504624 packages/web-app/public/index.html
        headScript: ``,
    },
    [ECompany.UNKNOWN]: {
        appName: 'Error Unknown Company',
        appShortName: 'Unknown Company',
        appDescription: 'Internal error - Company project is not recognized',
        headScript: ``,
    },
};
const companies = (processEnv.COMPANIES || '')
    .split(' ')
    .filter(Boolean)
    .map((companyServerAddress) => {
    var _a, _b, _c, _d;
    const companyId = companyServerAddress.split('|')[0] || ECompany.UNKNOWN;
    if (!Object.values(ECompany).includes(companyId)) {
        throw dynaError({
            code: 51980859125400,
            message: `Configuration error: In process.env.COMPANIES var there is a companyId [${companyId}] that it is not supported by the app, is not in ECompany enum!`,
        });
    }
    return {
        companyId,
        serverAddress: (() => {
            const address = companyServerAddress.split('|')[1] || '';
            if (address && !address.endsWith('/'))
                return address + '/';
            return address;
        })(),
        clientAddress: (() => {
            return companyServerAddress.split('|')[2] || '';
        })(),
        appName: ((_a = appInfoPerCompanyId[companyId]) === null || _a === void 0 ? void 0 : _a.appName) || `Unknown company ${companyId}`,
        appShortName: ((_b = appInfoPerCompanyId[companyId]) === null || _b === void 0 ? void 0 : _b.appShortName) || `Unknown company`,
        appDescription: ((_c = appInfoPerCompanyId[companyId]) === null || _c === void 0 ? void 0 : _c.appDescription) || `Unknown company ${companyId}`,
        headScript: ((_d = appInfoPerCompanyId[companyId]) === null || _d === void 0 ? void 0 : _d.headScript) || '',
    };
})
    .filter(company => !!company.companyId);
// Note: The main source of the server's config, are the env_variables.sh, not this const.
export const appConfig = {
    companies,
    mode: processEnv.PRODUCTION === "true"
        ? EAppMode.PRODUCTION
        : EAppMode.DEVELOPMENT,
    allowCORSForAddresses: [
    // Company domains are added automatically later in this file.
    ],
    // # This is the only place to set up the CORS of the application.
    //
    // The same contentSecurityPolicyDirectives are applied on the client side on app's startup by the updateAppHtml.ts
    //
    // These are applied on
    // - all server's responses as headers
    // - web-app updated <meta http-equiv="Content-Security-Policy"> with updateAppHtml.ts
    contentSecurityPolicyDirectives: {
        // For default-src
        defaultSrc: [
            "'self'",
            'blob:',
            'localhost',
            // Sentry
            "*.sentry.io",
            // General 3rd party dependencies
            '*.google.com',
            '*.google.co.in',
            '*.google-analytics.com',
            '*.ampproject.org',
            '*.googleapis.com',
            // Double click
            'doubleclick.net',
            '*.doubleclick.net',
            '*.gstatic.com',
            // Youtube
            'youtu.be',
            '*.youtu.be',
            '*.youtube.com',
            '*.vimeo.com',
            "*.typekit.net",
            '*.cookie-script.com',
            // Polyfill
            'https://cdnjs.cloudflare.com/polyfill/v3/',
            // For leaflet maps
            '*.openstreetmap.org',
            '*.tiles.stadiamaps.com',
            // Ad providers
            // Google AdSense
            '*.adtrafficquality.google',
            'googlesyndication.com',
            'googletagmanager.com',
            'adservice.google.com',
            'adservice.google.co.uk',
            'adservice.google.at',
            'adservice.google.de',
            'adservice.google.gr',
            '*.googlesyndication.com',
            '*.googletagmanager.com',
            '*.adservice.google.com',
            '*.adservice.google.co.uk',
            '*.adservice.google.at',
            '*.adservice.google.de',
            '*.adservice.google.gr',
            '*.googlesyndication.com',
            '*.googleadservices.com',
            '*.googleadservices.at', // Todo: We have to add this for every country!
            '*.googleadservices.be',
            '*.googletagservices.com',
            '*.googleusercontent.com',
            // Monetag
            '*.linkwi.se',
            'inklinkor.com',
            'ibrapush.com',
            'fleraprt.com',
            'nanouwho.com',
            '*.rtmark.net',
            'betotodilea.com',
            'offerimage.com',
            // Adsterra
            'https://www.profitabledisplaynetwork.com',
            // Social networks
            "tiktok.com",
            "*.tiktok.com",
            "ttwstatic.com", // For tiktok content
            "*.ttwstatic.com", // For tiktok content
            "*.facebook.com",
            "*.facebook.net",
            "*.instagram.com",
            "*.twitter.com",
            "linkedin.com",
            "*.linkedin.com",
        ],
        // For script-src
        scriptSrc: [
            // Google ads
            "'self'",
            "blob:",
            "'unsafe-inline'",
            '*.gstatic.com',
            '*.adtrafficquality.google',
            'https://cdnjs.cloudflare.com/polyfill/v3/',
            'https://cdnjs.cloudflare.com/ajax/libs/js-beautify/',
            'https://cdnjs.cloudflare.com/ajax/libs/ace/',
            '*.google.com',
            '*.googleapis.com',
            '*.youtube.com',
            '*.googlesyndication.com',
            '*.googletagmanager.com',
            '*.vimeo.com',
            'https://www.googletagmanager.com',
            '*.instagram.com',
        ],
        styleSrc: [
            "'self'",
            "'unsafe-inline'",
        ],
        // For style-src-elem
        styleSrcElem: [
            "'self'",
            "'unsafe-inline'",
            "https://use.fontawesome.com/releases/v5.0.13/",
            "fonts.googleapis.com",
        ],
        // For font-src
        fontSrc: [
            "'self'",
            "data:",
            '*.fontawesome.com',
            'fonts.gstatic.com',
        ],
        // For img-src
        imgSrc: [
            "'self'",
            // General
            'data:',
            'blob:',
            // Core App
            'https://dyna-cms.s3.amazonaws.com',
            'https://dyna-cms-dev.s3.amazonaws.com',
            // Google ads (and not only)
            '*.adtrafficquality.google',
            '*.googletagmanager.com',
            '*.google.com',
            // Google oAuth
            '*.googleusercontent.com',
            '*.googlesyndication.com',
            // Google Analytics
            '*.google-analytics.com',
            // Youtube images
            '*.ytimg.com',
            // Maps
            '*.googleapis.com',
            "maps.gstatic.com",
            '*.githubusercontent.com',
            '*.openstreetmap.org',
            '*.tile.openstreetmap.org',
        ],
        // For media-src
        mediaSrc: [
            "'self'",
            'dyna-cms.s3.amazonaws.com',
            'https://archive.org/',
        ],
        // For connect-src
        connectSrc: [
            "'self'",
            // Note the company's related should be applied on web-app's side manually!
            // This is because at the time of the evaluation of this object, the companies are empty array since companies are coming from env vars.
            ...companies.map(c => getHost(c.serverAddress)),
            ...companies.map(c => '*.' + getHost(c.serverAddress)),
            ...companies.map(c => {
                if (c.serverAddress.startsWith('http'))
                    return c.serverAddress.replace('http', 'ws');
                return 'ws://' + c.serverAddress;
            }),
            '*.adtrafficquality.google',
            '*.google.com',
            '*.googleapis.com',
            '*.gstatic.com',
            '*.fontawesome.com',
            '*.sentry.io',
            '*.openstreetmap.org',
            '*.tile.openstreetmap.org',
            '*.googlesyndication.com',
            "*.google-analytics.com",
            'vimeo.com',
            '*.vimeo.com',
            'region1.google-analytics.com',
            'https://archive.org/',
            '*.archive.org',
        ].filter(Boolean),
        // For frame-ancestors
        frameAncestors: [
            "'self'",
            "*.linkwi.se",
            '*.youtube.com',
        ],
        // For form-action
        formAction: [
            "'self'",
        ],
    },
};
appConfig.companies.forEach(({ clientAddress }) => appConfig.allowCORSForAddresses.push(clientAddress));
appConfig.allowCORSForAddresses = arrayUnique(appConfig.allowCORSForAddresses);
// Validate the content of the COMPANIES
appConfig.companies.forEach(companyConfig => {
    if (!companyConfig.companyId)
        console.error(`COMPANIES var has error, a company has no companyId`, companyConfig);
    if (!companyConfig.serverAddress)
        console.error(`COMPANIES var has error, a company has no serverAddress`, companyConfig);
    if (!companyConfig.clientAddress)
        console.error(`COMPANIES var has error, a company has no clientAddress`, companyConfig);
});
