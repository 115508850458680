import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

import {IPresetButtonsProps} from "./PresetButtons";

const PresetButtonsLazy = lazy(() => import("./PresetButtons"));

export const PresetButtons = connect((props: IPresetButtonsProps) => (
  <LazyLoadComponent>
    <PresetButtonsLazy {...props}/>
  </LazyLoadComponent>
));
