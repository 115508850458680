export const sxTextShadow = ({ strong = 1, blur = 2, color = "white", } = {}) => {
    const shadows = [];
    for (let i = 0; i < strong; i++) {
        shadows.push(getStyle(-i, -i, blur, color));
        shadows.push(getStyle(+i, -i, blur, color));
        shadows.push(getStyle(-i, +i, blur, color));
        shadows.push(getStyle(+i, +i, blur, color));
    }
    return shadows.join(', ');
};
const getStyle = (x, y, blur, color) => `${x}px ${y}px ${blur}px ${color}`;
