import {IDynaCMSBlockSoundSoundCloud} from "server-app/dist/interfaces";
import {SoundPlayerSoundCloud} from "ui-components/dist/SoundPlayerSoundCloud";
import {Box} from "ui-components/dist/Box";

export interface IViewerSoundCloudItemProps {
  content: IDynaCMSBlockSoundSoundCloud;
}

export const ViewerSoundCloudItem = (props: IViewerSoundCloudItemProps): JSX.Element => {
  const {
    content: {
      soundCloudUrl,
      height,
    },
  } = props;

  return (
    <Box sx={{height: height + 'px'}}>
      <SoundPlayerSoundCloud
        url={soundCloudUrl}
      />
    </Box>
  );
};
