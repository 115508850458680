import * as React from "react";

import {ETKDynaCMS} from "server-app/dist/interfaces";

import {GridItem} from "ui-components/dist/Grid";
import {
  Button,
  EButtonSize,
} from "ui-components/dist/Button";

import {DynaCMSIntlProvider} from "../DynaCMSIntlProvider";
import {
  DynaIntlTranslate,
} from "../../../dyna-intl/components-public";

import {createIcon} from "ui-components/dist/IconComponent";
import NewItemsIcon from "@mui/icons-material/Campaign";

export interface IDynaCMSButtonApplyUpdatedContentProps {
  newItemsAvailable: boolean;
  onApplyUpdatedContent: () => void;
}

const tks = {
  newItemsAvailableTitle: ETKDynaCMS.NEW_ITEMS_AVAILABLE_TITLE,
  newItemsAvailableSubtitle: ETKDynaCMS.NEW_ITEMS_AVAILABLE_SUBTITLE,
};

export const DynaCMSButtonApplyUpdatedContent: React.FC<IDynaCMSButtonApplyUpdatedContentProps> = (
  {
    newItemsAvailable,
    onApplyUpdatedContent,
  },
) => {
  return (
    <DynaCMSIntlProvider tks={tks}>
      <GridItem
        sx={{textAlign: 'center'}}
        show={newItemsAvailable}
      >
        <Button
          ghost
          size={EButtonSize.XLARGE}
          Icon={createIcon.byMuiIcon(NewItemsIcon)}
          children={<DynaIntlTranslate tk={tks.newItemsAvailableTitle}/>}
          description={<DynaIntlTranslate tk={tks.newItemsAvailableSubtitle}/>}
          onClick={onApplyUpdatedContent}
        />
      </GridItem>
    </DynaCMSIntlProvider>
  );
};
