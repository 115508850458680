import {
  HOST,
  ICompanyConfig,
} from "./ICompanyConfig";

import {companyConfigsXDoc} from "./xdoc-no/companyConfigs";
import {companyConfigsAdoreGr} from "./adore-gr/companyConfigs";
import {companyConfigsAnelCompany} from "./anel-company/companyConfigs";
import {companyConfigsNetDotFashion} from "./net-fashion/companyConfigs";
import {companyConfigsTheGirlGuns} from "./tgg-com/companyConfigs";

export const appCompaniesConfig: Record<HOST, ICompanyConfig> = {
  ...companyConfigsXDoc,
  ...companyConfigsAdoreGr,
  ...companyConfigsAnelCompany,
  ...companyConfigsNetDotFashion,
  ...companyConfigsTheGirlGuns,
};
