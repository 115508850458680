import {
  EThemeName,
  Theme,
} from "ui-components/dist/ThemeProvider";

export const themeChange = (themeName: EThemeName, theme: Theme): void => {
  if (themeName === EThemeName.MUI4_DARK) {
    theme.palette.primary.main = theme.palette.primary.main + ""; // 4TS
  }
};
