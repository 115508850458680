export var ETKUserAuthentication;
(function (ETKUserAuthentication) {
    ETKUserAuthentication["SIGN_IN"] = "dyna-core-app--SIGN_IN";
    ETKUserAuthentication["SIGN_OUT"] = "dyna-core-app--SIGN_OUT";
    ETKUserAuthentication["LOGIN_WITH_YOUR_FAVORITE_ACCOUNT"] = "dyna-core-app--LOGIN_WITH_YOUR_FAVORITE_ACCOUNT";
    ETKUserAuthentication["NO_NEED_TO_CREATE_ACCOUNT"] = "dyna-core-app--NO_NEED_TO_CREATE_ACCOUNT";
})(ETKUserAuthentication || (ETKUserAuthentication = {}));
export const userAuthenticationHardcodedTranslations = {
    [ETKUserAuthentication.SIGN_IN]: {
        en: "Sign-in",
        el: "Σύνδεση",
        de: "Anmeldung",
    },
    [ETKUserAuthentication.SIGN_OUT]: {
        en: "Sign-out",
        el: "Αποσύνδεση",
        de: "Abmeldung",
    },
    [ETKUserAuthentication.LOGIN_WITH_YOUR_FAVORITE_ACCOUNT]: {
        en: 'Sign-in with your favorite account',
        el: 'Συνδεθείτε με τον αγαπημένο σας λογαριασμό',
        de: "Mit Ihrem bevorzugten Konto anmelden",
    },
    [ETKUserAuthentication.NO_NEED_TO_CREATE_ACCOUNT]: {
        en: 'No need to create an account. Just use an existing and secure account.',
        el: 'Δεν χρειάζεται να δημιουργήσετε λογαριασμό. Απλώς χρησιμοποιήστε έναν υπάρχοντα και ασφαλή λογαριασμό.',
        de: "Es ist nicht erforderlich, ein Konto zu erstellen. Verwenden Sie einfach ein vorhandenes und sicheres Konto.",
    },
};
