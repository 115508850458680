import {
  IDynaCMSArticle,
  IDynaCMSBlockHeader,
} from "server-app/dist/interfaces";

import {useTheme} from "ui-components/dist/ThemeProvider";

import {IAppStore} from "../../../../../state/IAppStore";

import {DynaCMSFont} from "../../../public-components";
import {getContentPaddingSx} from "../utils/getContentPadding";

export interface IViewerHeadingProps {
  store: IAppStore;
  article: IDynaCMSArticle;
  content: IDynaCMSBlockHeader;
}

export const ViewerHeading = (props: IViewerHeadingProps): JSX.Element => {
  const {
    store: {
      dynaCMS: {
        state: {
          settings: {
            defaultArticleFontFamilyHeader,
            defaultArticleFontFamilyHeaders,
          },
        },
      },
    },
    article: {fontFamilyHeaders},
    content: {
      heading,
      text,
      font,
    },
  } = props;
  const theme = useTheme();

  return (
    <DynaCMSFont
      sx={{
        ...getContentPaddingSx(heading),
        marginLeft: (theme.spacing(heading - 1)),
      }}
      component={`h${heading + 1}` as any}
      font={[
        defaultArticleFontFamilyHeader,
        defaultArticleFontFamilyHeaders,
        fontFamilyHeaders,
        font,
      ]}
    >
      {text}
    </DynaCMSFont>
  );
};
