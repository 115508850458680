import {IDynaCMSArticleHeading} from "server-app/dist/interfaces";

import {
  IRouteArgs,
  routeDynaCMSArticleViewerByUrlIdPaths,
} from "./routeDynaCMSArticleViewerByUrlId.paths";

import {IAppRoute} from "../../application/config/IAppRoute";

import {ArticleViewer} from "../pages/ArticleViewer";

export const routeDynaCMSArticleViewerByUrlId: IAppRoute<IRouteArgs> = {
  ...routeDynaCMSArticleViewerByUrlIdPaths,
  render: (
    {
      pathParams: {
        countryId,
        languageId,
        groupId,
        urlId,
      },
    },
  ) => (
    <ArticleViewer
      countryId={countryId}
      languageId={languageId}
      groupId={groupId}
      idType="urlId"
      id={urlId}
      loadPurpose="view"
    />
  ),
};

export const getDynaCMSArticleViewerLinkByArticle = (article: IDynaCMSArticleHeading): string => {
  return routeDynaCMSArticleViewerByUrlId.getRoutePath({
    groupId: article.groupId,
    countryId: article.countryId,
    languageId: article.languageId,
    urlId: article.urlId,
  });
};
