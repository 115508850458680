import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState, useEffect, } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Box } from "../Box";
import { FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { ContainerDetectResize } from "../ContainerDetectResize";
import { CenterContainer } from "../CenterContainer";
import { useBreakpointDevice } from "../useBreakpointDevice";
import { sxTransition, ECSSDuration, ECSSEasing, } from "../sxTransition";
import LeftIcon from '@mui/icons-material/ArrowBackIosNew';
import RightIcon from '@mui/icons-material/ArrowForwardIos';
import DocIcon from '@mui/icons-material/FiberManualRecord';
export const ContainerSlider = (props) => {
    const { defaultSlideNo = 0, showArrows = true, showThumbnails = true, allowUserScroll = true, children: userChildren, } = props;
    const { isMobile } = useBreakpointDevice();
    const refScrollContainer = useRef(null);
    const [childNoInViewport, setChildNoInViewport] = useState(-1);
    const arrowWidth = isMobile ? 32 : 64;
    useEffect(() => {
        if (!refScrollContainer.current)
            return;
        refScrollContainer.current.scrollLeft = 0;
        updateChildNoInViewport();
        scrollToNumber(defaultSlideNo);
    }, [refScrollContainer.current]);
    const scrollToDirection = (direction) => {
        if (!refScrollContainer.current)
            return; // 4TS
        refScrollContainer.current.scrollTo({
            left: refScrollContainer.current.scrollLeft + (parseInt(getComputedStyle(refScrollContainer.current).width) * direction),
            behavior: 'smooth',
        });
    };
    const scrollToNumber = (no) => {
        if (!refScrollContainer.current)
            return; // 4TS
        refScrollContainer.current.scrollTo({
            left: parseInt(getComputedStyle(refScrollContainer.current).width) * no,
            behavior: 'smooth',
        });
    };
    const updateChildNoInViewport = () => {
        if (!refScrollContainer.current)
            return;
        const no = Math.round(refScrollContainer.current.scrollLeft / (parseInt(getComputedStyle(refScrollContainer.current).width)));
        setChildNoInViewport(no);
    };
    const scrollBarHeight = (() => {
        if (!refScrollContainer.current)
            return 0;
        return refScrollContainer.current.offsetHeight - refScrollContainer.current.clientHeight + 1;
    })();
    const handleLeftClick = () => scrollToDirection(-1);
    const handleRightClick = () => scrollToDirection(+1);
    const handleScroll = useDebouncedCallback(updateChildNoInViewport, 100, { maxWait: 100 });
    const sxArrowButton = {
        minWidth: arrowWidth,
        cursor: 'pointer',
        transition: theme => sxTransition(theme, ['background', 'opacity'], ECSSDuration.SHORTEST),
        ':hover': { background: theme => theme.palette.grayShades.gray1 },
    };
    const children = typeof userChildren === "function"
        ? userChildren({
            slideLeft: handleLeftClick,
            slideRight: handleRightClick,
            slideTo: scrollToNumber,
        })
        : userChildren;
    return (_jsxs(Box, { dataComponentName: "ContainerSlider", children: [_jsx(ContainerDetectResize, { children: ({ width }) => {
                    const itemWidth = width - (showArrows ? (arrowWidth * 2) : 0);
                    return (_jsxs(FlexContainerHorizontal, { children: [_jsx(FlexItemMin, { show: showArrows, sx: Object.assign(Object.assign({}, sxArrowButton), (childNoInViewport === 0
                                    ? {
                                        opacity: 0.3,
                                        pointerEvents: 'none',
                                    }
                                    : {})), onClick: handleLeftClick, children: _jsx(CenterContainer, { fullHeight: true, children: _jsx(LeftIcon, {}) }) }), _jsxs(FlexItemMax, { sx: {
                                    minWidth: '1px',
                                    position: 'relative',
                                }, children: [_jsx(Box, { sx: {
                                            display: 'flex',
                                            overflowX: allowUserScroll ? "scroll" : "hidden",
                                            scrollSnapType: 'x mandatory',
                                            userSelect: 'none',
                                            paddingTop: `${scrollBarHeight}px`,
                                        }, ref: refScrollContainer, onScroll: handleScroll, children: children.map((item, index) => (_jsx(Box, { sx: {
                                                flex: `0 0 ${itemWidth}px`,
                                                width: itemWidth,
                                                scrollMarginLeft: 0,
                                                scrollSnapAlign: 'start',
                                            }, children: item }, index))) }), _jsx(Box, { dataComponentName: "Hide the scrollbar", sx: {
                                            position: 'absolute',
                                            height: `${scrollBarHeight}px`,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: theme => theme.palette.background.default,
                                        } })] }), _jsx(FlexItemMin, { show: showArrows, sx: Object.assign(Object.assign({}, sxArrowButton), (childNoInViewport === children.length - 1
                                    ? {
                                        opacity: 0.3,
                                        pointerEvents: 'none',
                                    }
                                    : {})), onClick: handleRightClick, children: _jsx(CenterContainer, { fullHeight: true, children: _jsx(RightIcon, {}) }) })] }));
                } }), _jsx(Box, { show: showThumbnails, sx: { textAlign: 'center' }, children: Array(children.length)
                    .fill(null)
                    .map((v, index) => v || index)
                    .map(no => (_jsx(Box, { inline: true, sx: { cursor: 'pointer' }, onClick: () => scrollToNumber(no), children: _jsx(Box, { sx: {
                            opacity: no === childNoInViewport ? undefined : 0.6,
                            transform: `scale(${no === childNoInViewport ? 0.9 : 0.6})`,
                            transition: theme => sxTransition(theme, ['opacity', 'transform'], ECSSDuration.COMPLEX, ECSSEasing.IN_OUT),
                        }, children: _jsx(DocIcon, {}) }) }, no))) })] }));
};
