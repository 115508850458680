import { useEffect, useState, } from "react";
import { useInView } from "react-intersection-observer";
export const useInViewPort = ({ delay, rootMargin, onInViewPortChange, } = {}) => {
    const { ref, inView: inViewPort, } = useInView({
        delay,
        rootMargin: rootMargin === undefined ? undefined : rootMargin + 'px',
    });
    const [viewed, setViewed] = useState(inViewPort);
    useEffect(() => {
        if (inViewPort && !viewed)
            setViewed(true);
        onInViewPortChange && onInViewPortChange(inViewPort);
    }, [
        inViewPort,
        viewed,
        onInViewPortChange,
    ]);
    return {
        ref,
        inViewPort,
        viewed,
    };
};
