import {consoleOnce} from "utils-library/dist/utils";

import {
  API_PATH_ApiAppVersionGet,
  IApiAppVersionGetResponse,
} from "server-app/dist/interfaces";

import {apiFetch} from "../../../../api/apiFetch";
import {appConfig} from "../../config/appConfig";

export const apiAppVersionGet = async (): Promise<IApiAppVersionGetResponse> => {
  const versionInfo = await apiFetch.request<null, void, IApiAppVersionGetResponse>({
    method: 'GET',
    path: API_PATH_ApiAppVersionGet,
  });
  const upToDate = versionInfo.version === appConfig.appVersion;
  consoleOnce.info(`Dyna-CMS Version: server: ${versionInfo.version} client: ${appConfig.appVersion} - ${upToDate ? "up to date [V]" : "OUTDATED [!]"}`);
  if (!upToDate) consoleOnce.warn("  The client version is a few versions behind. This is not an issue, but in case you need to check or test the latest version, you should also update the client to the latest version. To do that, please close all browser tabs with this address and then reopen it.");
  return versionInfo;
};
