import {connect} from "react-dynadux";
import {
  EDynaCMSRights,
  IDynaCMSArticle,
  IDynaCMSArticleBlock,
  EDynaCMSBlockType,
  EViewWidthType,
  getDefaultDynaCMSArticle,
} from "server-app/dist/interfaces";

import {
  Alert,
  EAlertType,
} from "ui-components/dist/Alert";

import {IAppStore} from "../../../../state/IAppStore";

import {ViewerHeading} from "./ViewerHeading";
import {ViewerBody} from "./ViewerBody";
import {ViewerMarkdown} from "./ViewerMarkdown";
import {ViewerQuote} from "./ViewerQuote";
import {ViewerLinks} from "./ViewerLinks";
import {ViewerImage} from "./ViewerImage";
import {ViewerImages} from "./ViewerImages";
import {ViewerVideoPlayerYouTube} from "./ViewerVideoPlayerYouTube";
import {ViewerVideoPlayerVimeo} from "./ViewerVideoPlayerVimeo";
import {ViewerVideoPlayerFile} from "./ViewerVideoPlayerFile";
import {ViewerSoundCloudItem} from "./ViewerSoundCloudItem";
import {ViewerMultimedia} from "./ViewerMultimedia";
import {ViewerSocialPost} from "./ViewerSocialPost";

export interface IBlockViewerProps {
  article?: IDynaCMSArticle;
  store: IAppStore;
  block: IDynaCMSArticleBlock | null | undefined;
  contentSpacing?: number;
  viewWidthType?: EViewWidthType;
  showPhotoTexts?: boolean;
  showPhotoCredits?: boolean;
  showPhotoPortraitSmaller?: boolean;
  showVideoPreviewOnly?: boolean;
}

export const BlockViewer = connect((props: IBlockViewerProps): JSX.Element | null => {
  if (!props.block) return null;
  const {
    store: {userAuth: {utils: {userHasAnyOfRights}}},
    article = getDefaultDynaCMSArticle(),
    block: {content: _content},
    contentSpacing = 0,
    viewWidthType = EViewWidthType.EASY_READ,
    showPhotoTexts = true,
    showPhotoCredits = true,
    showPhotoPortraitSmaller = false,
    showVideoPreviewOnly = false,
  } = props;
  const content: any = _content;

  switch (content.type) {
    case EDynaCMSBlockType.HEADING:
      return (
        <ViewerHeading
          article={article}
          content={content}
        />
      );
    case EDynaCMSBlockType.BODY:
      return (
        <ViewerBody
          article={article}
          content={content}
          viewWidthType={viewWidthType}
        />
      );
    case EDynaCMSBlockType.MARKDOWN:
      return (
        <ViewerMarkdown
          article={article}
          content={content}
          contentSpacing={contentSpacing}
          viewWidthType={viewWidthType}
        />
      );
    case EDynaCMSBlockType.QUOTE:
      return <ViewerQuote content={content}/>;
    case EDynaCMSBlockType.LINKS:
      return <ViewerLinks content={content}/>;
    case EDynaCMSBlockType.IMAGE:
      return (
        <ViewerImage
          content={content}
          showTexts={showPhotoTexts}
          showCredits={showPhotoCredits}
          showPortraitsSmaller={showPhotoPortraitSmaller}
        />
      );
    case EDynaCMSBlockType.IMAGES:
      return (
        <ViewerImages
          content={content}
          showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        />
      );
    case EDynaCMSBlockType.VIDEO_YOUTUBE:
      return (
        <ViewerVideoPlayerYouTube
          content={content}
          previewOnly={showVideoPreviewOnly}
        />
      );
    case EDynaCMSBlockType.VIDEO_VIMEO:
      return (
        <ViewerVideoPlayerVimeo
          content={content}
          previewOnly={showVideoPreviewOnly}
        />
      );
    case EDynaCMSBlockType.VIDEO_FILE:
      return (
        <ViewerVideoPlayerFile
          content={content}
          previewOnly={showVideoPreviewOnly}
        />
      );
    case EDynaCMSBlockType.SOUND_SOUNDCLOUD:
      return <ViewerSoundCloudItem content={content}/>;
    case EDynaCMSBlockType.MULTIMEDIA:
      return (
        <ViewerMultimedia
          content={content}
          showPortraitsSmaller={showPhotoPortraitSmaller}
        />
      );
    case EDynaCMSBlockType.SOCIAL_POST_FACEBOOK:
    case EDynaCMSBlockType.SOCIAL_POST_TWITTER:
    case EDynaCMSBlockType.SOCIAL_POST_INSTAGRAM:
    case EDynaCMSBlockType.SOCIAL_POST_LINKEDIN:
    case EDynaCMSBlockType.SOCIAL_POST_TIKTOK:
      return <ViewerSocialPost content={content}/>;
    default:
      console.error(`Block type [${content.type}] is not supported or not implemented in <BlockViewer>`);
      if (!userHasAnyOfRights(Object.values(EDynaCMSRights))) return null;
      return (
        <Alert
          title={`Block type [${content.type}] is not supported`}
          type={EAlertType.ERROR}
        >
          <p>Either your app is outdated either this kind of block is no longer support.</p>
          <p>Try to close all tabs (or Apps) of {window.location.host} and retry.</p>
          <p>Error code: <code>202204191923</code></p>
        </Alert>
      );
  }
});
