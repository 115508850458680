import {EDynaCMSRights} from "server-app/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {DynaCMSIconMui} from "../public-components";

export const routeDynaCMSMainPagePaths: IAppRoute = {
  title: 'Dyna CMS',
  description: "Dyna Content Management System",
  icon: <DynaCMSIconMui/>,
  routePath: '/dyna-cms',
  getRoutePath: () => routeDynaCMSMainPagePaths.routePath,
  exact: true,
  userHasAllRights: [],
  userHasAnyOfRights: Object.values(EDynaCMSRights),
  requiresLatestClientVersion: true,
  render: () => null,
};
