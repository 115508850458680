import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { useEffect, useState, } from 'react';
export const RefreshComponent = ({ refreshEveryMinutes, children, }) => {
    const [key, setKey] = useState(Math.random());
    useEffect(() => {
        if (refreshEveryMinutes > 0) {
            const timerId = setInterval(() => setKey(Math.random()), refreshEveryMinutes * 60 * 1000);
            return () => clearInterval(timerId);
        }
        else
            return undefined;
    }, [refreshEveryMinutes]);
    const childrenWithKey = React.Children.map(children, child => React.isValidElement(child)
        ? React.cloneElement(child, { key })
        : child);
    return _jsx(_Fragment, { children: childrenWithKey });
};
