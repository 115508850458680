(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("server-app", [], factory);
	else if(typeof exports === 'object')
		exports["server-app"] = factory();
	else
		root["server-app"] = factory();
})(global, () => {
return 