import { getDefaultDBEntityDocument, } from "utils-library/dist/db-entity-interfaces";
import { getDefaultIDynaCMSFont, } from "./IDynaCMSFont";
export var EDynaCMSArticleChangeFrequency;
(function (EDynaCMSArticleChangeFrequency) {
    // Source: https://www.sitemaps.org/protocol.html
    EDynaCMSArticleChangeFrequency["ALWAYS"] = "always";
    EDynaCMSArticleChangeFrequency["HOURLY"] = "hourly";
    EDynaCMSArticleChangeFrequency["DAILY"] = "daily";
    EDynaCMSArticleChangeFrequency["WEEKLY"] = "weekly";
    EDynaCMSArticleChangeFrequency["MONTHLY"] = "monthly";
    EDynaCMSArticleChangeFrequency["YEARLY"] = "yearly";
    EDynaCMSArticleChangeFrequency["NEVER"] = "never";
})(EDynaCMSArticleChangeFrequency || (EDynaCMSArticleChangeFrequency = {}));
export var EShowDate;
(function (EShowDate) {
    EShowDate["INHERIT"] = "INHERIT";
    EShowDate["YES"] = "YES";
    EShowDate["NO"] = "NO";
})(EShowDate || (EShowDate = {}));
export var EViewWidthType;
(function (EViewWidthType) {
    EViewWidthType["FULL"] = "FULL";
    EViewWidthType["EASY_READ"] = "EASY_READ";
})(EViewWidthType || (EViewWidthType = {}));
export var EDynaCMSHeadingStyle;
(function (EDynaCMSHeadingStyle) {
    EDynaCMSHeadingStyle["HIDDEN"] = "HIDDEN";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT"] = "CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT_NEGATIVE_ALL"] = "CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT_NEGATIVE_ALL";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT_NEGATIVE_TEXTS"] = "CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT_NEGATIVE_TEXTS";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO"] = "CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO_NEGATIVE_ALL"] = "CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO_NEGATIVE_ALL";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO_NEGATIVE_TEXTS"] = "CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO_NEGATIVE_TEXTS";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_VERTICAL_PHOTO_TEXT"] = "CLEAN_PLAIN_VERTICAL_PHOTO_TEXT";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_VERTICAL_PHOTO_TEXT_NEGATIVE_ALL"] = "CLEAN_PLAIN_VERTICAL_PHOTO_TEXT_NEGATIVE_ALL";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_VERTICAL_PHOTO_TEXT_NEGATIVE_TEXTS"] = "CLEAN_PLAIN_VERTICAL_PHOTO_TEXT_NEGATIVE_TEXTS";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_VERTICAL_TEXT_PHOTO"] = "CLEAN_PLAIN_VERTICAL_TEXT_PHOTO";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_VERTICAL_TEXT_PHOTO_NEGATIVE_ALL"] = "CLEAN_PLAIN_VERTICAL_TEXT_PHOTO_NEGATIVE_ALL";
    EDynaCMSHeadingStyle["CLEAN_PLAIN_VERTICAL_TEXT_PHOTO_NEGATIVE_TEXTS"] = "CLEAN_PLAIN_VERTICAL_TEXT_PHOTO_NEGATIVE_TEXTS";
    EDynaCMSHeadingStyle["TINY_SMALL_PHOTO_TEXT"] = "TINY_SMALL_PHOTO_TEXT";
    EDynaCMSHeadingStyle["PHOTO_TILE"] = "PHOTO_TILE";
    EDynaCMSHeadingStyle["EXCEPTIONAL"] = "EXCEPTIONAL";
    EDynaCMSHeadingStyle["EXCEPTIONAL_NEGATIVE_ALL"] = "EXCEPTIONAL_NEGATIVE_ALL";
    EDynaCMSHeadingStyle["EXCEPTIONAL_NEGATIVE_TEXTS"] = "EXCEPTIONAL_NEGATIVE_TEXTS";
})(EDynaCMSHeadingStyle || (EDynaCMSHeadingStyle = {}));
export const getDefaultDynaCMSArticleHeading = () => getDefaultDBEntityDocument({
    urlId: '',
    shortUrl: '',
    groupId: '',
    languageId: '',
    countryId: '',
    title: '',
    subtitle: '',
    label: '',
    description: '',
    showPublishDateInList: EShowDate.INHERIT,
    showUpdateDateInList: EShowDate.INHERIT,
    showPublishDateInView: EShowDate.INHERIT,
    showUpdateDateInView: EShowDate.INHERIT,
    showAds: true,
    showShareButtons: true,
    showRelatedInsideBody: true,
    showRelatedAtTheEnd: true,
    tags: [],
    seoAvailable: true,
    usedAsPage: false,
    changeFrequency: EDynaCMSArticleChangeFrequency.NEVER,
    seoKeywords: '',
    canonicalHref: '',
    coverMediaBlockId: '',
    coverMediaBlockCopy: null,
    fontFamilyGeneral: getDefaultIDynaCMSFont(),
    fontFamilyHeader: getDefaultIDynaCMSFont(),
    fontFamilyHeaders: getDefaultIDynaCMSFont(),
    viewWidthType: EViewWidthType.EASY_READ,
    headingStyleList: EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT,
    headingStyleView: EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_PHOTO_TEXT,
    eventStart: undefined,
    eventEnd: undefined,
    geolocation: undefined,
    mapAddress: undefined,
    isContentValid: true,
    publishAt: 0,
    publishedAt: 0,
    stats: {
        words: 0,
        headings: 0,
        blocks: {},
        size: 0,
        seoKeywordsCoveragePercentage: 0,
        seoKeywordsCoverageAnalysis: [],
    },
});
export const getDefaultDynaCMSArticle = () => (Object.assign(Object.assign({}, getDefaultDynaCMSArticleHeading()), { content: {
        order: [],
        blocks: {},
    } }));
