import {
  EDynaCMSBlockType,
  IDynaCMSBlockSocialPostFacebook,
  IDynaCMSBlockSocialPostTwitter,
  IDynaCMSBlockSocialPostInstagram,
  IDynaCMSBlockSocialPostLinkedIn,
  IDynaCMSBlockSocialPostTikTok,
} from "server-app/dist/interfaces";

import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

import {Condition} from "ui-components/dist/Condition";
import {CheckForAdBlocker} from "ui-components/dist/CheckForAdBlocker";

const FacebookPostViewer = lazy(() => import("./components/FacebookPostViewer"));
const TwitterPostViewer = lazy(() => import("./components/TwitterPostViewer"));
const InstagramPostViewer = lazy(() => import("./components/InstagramPostViewer"));
const LinkedInPostViewer = lazy(() => import("./components/LinkedInPostViewer"));
const TikTokPostViewer = lazy(() => import("./components/TikTokPostViewer"));

export interface IViewerSocialPostProps {
  content:
    | IDynaCMSBlockSocialPostFacebook
    | IDynaCMSBlockSocialPostTwitter
    | IDynaCMSBlockSocialPostInstagram
    | IDynaCMSBlockSocialPostLinkedIn
    | IDynaCMSBlockSocialPostTikTok;
}

enum ESocialNetwork {
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  TIKTOK = "TIKTOK",
  UNKNOWN = "UNKNOWN",
}

export const ViewerSocialPost = (props: IViewerSocialPostProps): JSX.Element => {
  const {
    content: {
      type,
      postUrl,
    },
  } = props;

  const socialNetwork = (() => {
    if (type === EDynaCMSBlockType.SOCIAL_POST_FACEBOOK) return ESocialNetwork.FACEBOOK;
    if (type === EDynaCMSBlockType.SOCIAL_POST_TWITTER) return ESocialNetwork.TWITTER;
    if (type === EDynaCMSBlockType.SOCIAL_POST_INSTAGRAM) return ESocialNetwork.INSTAGRAM;
    if (type === EDynaCMSBlockType.SOCIAL_POST_LINKEDIN) return ESocialNetwork.LINKEDIN;
    if (type === EDynaCMSBlockType.SOCIAL_POST_TIKTOK) return ESocialNetwork.TIKTOK;
    return ESocialNetwork.UNKNOWN;
  })();

  return (
    <CheckForAdBlocker messageType="blocked-content-by-mistake">
      <Condition if={socialNetwork === ESocialNetwork.FACEBOOK}>
        <LazyLoadComponent>
          <FacebookPostViewer postUrl={postUrl}/>
        </LazyLoadComponent>
      </Condition>
      <Condition if={socialNetwork === ESocialNetwork.TWITTER}>
        <LazyLoadComponent>
          <TwitterPostViewer postUrl={postUrl}/>
        </LazyLoadComponent>
      </Condition>
      <Condition if={socialNetwork === ESocialNetwork.INSTAGRAM}>
        <LazyLoadComponent>
          <InstagramPostViewer postUrl={postUrl}/>
        </LazyLoadComponent>
      </Condition>
      <Condition if={socialNetwork === ESocialNetwork.LINKEDIN}>
        <LazyLoadComponent>
          <LinkedInPostViewer postUrl={postUrl}/>
        </LazyLoadComponent>
      </Condition>
      <Condition if={socialNetwork === ESocialNetwork.TIKTOK}>
        <LazyLoadComponent>
          <TikTokPostViewer postUrl={postUrl}/>
        </LazyLoadComponent>
      </Condition>
      <Condition if={socialNetwork === ESocialNetwork.UNKNOWN}>
        DynaCMSSocialPostViewer: unknown social post block type "{type}"
      </Condition>
    </CheckForAdBlocker>
  );
};
