import {EUserAuthenticationRights} from "server-app/dist/interfaces";

import {IAppRoute} from "../config/IAppRoute";

import AdminIcon from "@mui/icons-material/Dns";

export const routeBackOfficePagePaths: IAppRoute = {
  title: 'Back office',
  icon: <AdminIcon/>,
  menuId: '##backoffice',
  routePath: '/back-office',
  getRoutePath: () => routeBackOfficePagePaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [EUserAuthenticationRights.ADMINISTRATOR],
  exact: true,
  render: () => null,
};
