// Help: https://www.conductor.com/academy/open-graph
export const createMetaTags = ({ country, langauge, type, title, description, url, siteName, articleSection, articlePublisherUrl, imageUrl, }) => [
    {
        property: 'og:locale',
        content: `${langauge.toLowerCase()}_${country.toUpperCase()}`,
    },
    {
        property: 'og:type',
        content: type,
    },
    {
        property: 'article:section',
        content: articleSection,
    },
    {
        property: 'article:publisher',
        content: articlePublisherUrl,
    },
    {
        property: 'og:title',
        content: title,
    },
    {
        property: 'og:description',
        content: description,
    },
    {
        property: 'og:url',
        content: url,
    },
    {
        property: 'og:site_name',
        content: siteName,
    },
    {
        property: 'og:image',
        content: imageUrl,
    },
];
