import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { convertJSXElementToHTML } from "../utils";
import { createMetaTags } from "./createMetaTags";
export const twitterMetaCardContentToString = (props) => convertJSXElementToHTML({
    jsxElement: _jsx(TwitterMetaCardContentToString, Object.assign({}, props)),
    prettify: true,
});
/**
 * Builds the meta tags for Twitter card with meta tags for the <head>.
 * NOTE: this component is not reusable because the content of it should be in the <head>. For component use, use the TwitterMetaCard instead.
 */
const TwitterMetaCardContentToString = (props) => {
    return (_jsx(_Fragment, { children: createMetaTags(props)
            .filter(metaTag => !!metaTag.content)
            .map(({ name, content, }, index) => (_jsx("meta", { name: name, content: content }, index))) }));
};
