import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

import {EUserAuthenticationRights} from "server-app/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";

const UserCRUDPage = lazy(() => import("../pages/UserCRUDPage/UserCRUDPage"));

export const routeUserEdit: IAppRoute<{
  userId: string;
}> = {
  title: 'Edit User',
  routePath: '/user-management/edit/:userId',
  getRoutePath: ({userId} = {userId: ''}) =>
    routeUserEdit.routePath
      .replace(':userId', userId),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EUserAuthenticationRights.ADMINISTRATOR,
    EUserAuthenticationRights.SYSTEM_ADMINISTRATOR,
  ],
  exact: true,
  requiresLatestClientVersion: true,
  render: ({pathParams: {userId}}) => (
    <LazyLoadComponent>
      <UserCRUDPage userId={userId}/>
    </LazyLoadComponent>
  ),
};
