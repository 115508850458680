import { useEffect, useRef, } from "react";
export const useGlobalEvent = (eventName, cb) => {
    const savedCallback = useRef(cb);
    useEffect(() => {
        savedCallback.current = cb;
    }, [cb]);
    useEffect(() => {
        const eventListener = (event) => {
            savedCallback.current(event);
        };
        window.addEventListener(eventName, eventListener);
        return () => {
            window.removeEventListener(eventName, eventListener);
        };
    }, [eventName]);
};
