import * as React from "react";
import {
  useState,
  useEffect,
} from "react";

import {consoleOnce} from "utils-library/dist/utils";
import {isURLReachable} from "utils-library/dist/node";

import {HtmlContent} from "ui-components/dist/HtmlContent";

import {appConfig} from "../../../application/config/appConfig";

import {EDynaAdOrientation} from "../../interfaces";

export interface IAdsterraProps {
  orientation: EDynaAdOrientation;
}

const asdterraAdsIFrameScripts: Record<EDynaAdOrientation, string> = {
  [EDynaAdOrientation.AUTO]: appConfig.adsterra?.squareBannerIFrameScript || '',
  [EDynaAdOrientation.VERTICAL]: appConfig.adsterra?.portraitBannerIFrameScript || '',
  [EDynaAdOrientation.RECTANGLE]: appConfig.adsterra?.squareBannerIFrameScript || '',
  [EDynaAdOrientation.HORIZONTAL]: appConfig.adsterra?.landscapeBannerIFrameScript || '',
};

export const Adsterra: React.FC<IAdsterraProps> = ({orientation}) => {
  const [show, setShow] = useState<boolean>(false);
  const iframeScript = asdterraAdsIFrameScripts[orientation];

  useEffect(() => {
    (async () => {
      if (!iframeScript) {
        consoleOnce.error(`Adsterra: Internal error, script is not resolved for the orientation [${orientation}]`);
        return;
      }

      const testIframeUrl = getHrefFromIFrameScript(iframeScript) + '/any.js';
      const isProvideAccessible = await isURLReachable(testIframeUrl);
      if (isProvideAccessible.networkError) {
        // If network error, then looks to be blocked by the an antivirus protection
        consoleOnce.warn(
          'AdSterra warn 20230909123001: Provider blocked',
          {isProvideAccessible},
        );
      }
      else {
        setShow(true);
      }
    })();
  }, []);

  if (!show) return null;

  return (
    <HtmlContent
      html={iframeScript}
      sanitize={false}
      style={{textAlign: 'center'}}
    />
  );
};

const getHrefFromIFrameScript = (iframeScript: string): string => {
  const srcRegex = /<iframe\s+src="([^"]+)"/;
  const match = iframeScript.match(srcRegex);

  if (match && match.length > 1) {
    return match[1];
  }
  else {
    return '';
  }
};
