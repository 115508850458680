import {ETKUserAuthentication} from "server-app/dist/interfaces";

import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";

const SignInPage = lazy(() => import("../pages/SignInPage"));

export const routeSignInPage: IAppRoute = {
  title: 'Sign-in',
  titleTk: ETKUserAuthentication.SIGN_IN,
  exact: true,
  routePath: '/sign-in',
  getRoutePath: () => routeSignInPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: () => (
    <LazyLoadComponent>
      <SignInPage/>
    </LazyLoadComponent>
  ),
};
