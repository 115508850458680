import { jsx as _jsx } from "react/jsx-runtime";
import { Button, EButtonVariant, EButtonColor, } from "../Button";
export const ButtonLinear = (props) => {
    const { sx, href, hrefNewWindow, show, fontFamilyInherit, fontWeightBold, children, onClick, } = props;
    return (_jsx(Button, { sx: Object.assign({ color: theme => theme.palette.text.primary, padding: theme => theme.spacing(2), borderWidth: '2px', borderStyle: 'solid', fontWeight: 'normal', borderColor: theme => theme.palette.text.primary, ':hover': {
                borderWidth: '2px',
                borderColor: theme => theme.palette.text.primary,
                backgroundColor: theme => theme.palette.action.hover,
                textDecoration: 'none !important',
            } }, sx), variant: EButtonVariant.TRANSPARENT, color: EButtonColor.WHITE, href: href, hrefNewWindow: hrefNewWindow, show: show, fontFamilyInherit: fontFamilyInherit, fontWeightBold: fontWeightBold, children: children, onClick: onClick }));
};
