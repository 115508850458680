import * as React from "react";

import {convertEnumKeyToHumanReadable} from "utils-library/dist/utils";

import {CenterContainer} from "ui-components/dist/CenterContainer";
import {Box} from "ui-components/dist/Box";

import {
  DynaCMSFontFamily,
  EDynaCMSFontFamily,
  EFontSize,
} from "../../../../dyna-cms/public-components";

import {EAdProvider} from "../../../public-components";
import {EDynaAdOrientation} from "../../../interfaces";

import {
  SxProps,
  Theme,
} from "ui-components/dist/ThemeProvider";

export interface IDemoAdPlaceholderProps {
  adProvider: EAdProvider;
  orientation: EDynaAdOrientation;
}

export const DemoAdPlaceholder: React.FC<IDemoAdPlaceholderProps> = (
  {
    adProvider,
    orientation: userOrientation,
  },
) => {
  const orientation =
    userOrientation === EDynaAdOrientation.AUTO
      ? EDynaAdOrientation.HORIZONTAL
      : userOrientation;
  return (
    <Box sx={sxPerOrientation[orientation]}>
      <CenterContainer textAlign="center">
        <DynaCMSFontFamily
          fontFamily={EDynaCMSFontFamily.F135_MODERN_BASE_NOTO}
          fontSize={EFontSize.PX24}
          bold
        >
          {convertEnumKeyToHumanReadable(adProvider)}
        </DynaCMSFontFamily>
        <DynaCMSFontFamily
          fontFamily={EDynaCMSFontFamily.F140_TIMES_BOOK_GENTIUM_PLUS}
          fontSize={EFontSize.PX14}
        >
          {convertEnumKeyToHumanReadable(orientation)}
          {userOrientation === EDynaAdOrientation.AUTO ? " (auto)" : null}
        </DynaCMSFontFamily>
      </CenterContainer>
    </Box>
  );
};

const sxBase: SxProps<Theme> = {
  border: '16px solid orange',
  margin: 'auto',
};

const sxPerOrientation: Record<EDynaAdOrientation, SxProps<Theme>> = {
  [EDynaAdOrientation.AUTO]: {
    ...sxBase,
    width: '90%',
    height: 240,
  },
  [EDynaAdOrientation.HORIZONTAL]: {
    ...sxBase,
    height: 120,
  },
  [EDynaAdOrientation.VERTICAL]: {
    ...sxBase,
    width: 240,
    height: 720,
  },
  [EDynaAdOrientation.RECTANGLE]: {
    ...sxBase,
    width: 520,
    height: 520,
  },
};
