import {ECompany} from "server-app/dist/interfaces";

import {EThemeName} from "ui-components/dist/ThemeProvider";
import {EProgressIconType} from "ui-components/dist/ProgressIcon";

import {
  appVersion,
  appCodeName,
  builtAt,
} from "../../core/application/config/appVersion";

import {
  HOST,
  ICompanyConfig,
  EAppMode,
  host,
} from "../ICompanyConfig";

const xDocProductionConfig: ICompanyConfig = {
  contactArticles: [],
  googleMapsApiKey: "",
  sentryIoDsn: "",
  company: ECompany.XDOC_NO,
  mode: EAppMode.PRODUCTION,
  appName: 'xdoc',
  appDescription: 'Billing application',
  lightTheme: EThemeName.MUI5_LIGHT,
  darkTheme: EThemeName.MUI5_DARK,
  progressIconType: EProgressIconType.DOUBLE_PULSE,
  appVersion,
  appCodeName,
  companyId: ECompany.XDOC_NO,
  apiBaseUrl: window.location.origin,
  webBaseUrl: window.location.origin,
  defaultLanguageId: 'no',
  builtAt,
  workerActive: true,
  termsOfUseArticles: [], // Todo. implement me
  aboutArticles: [],      // Todo. implement me
  onSignOutNavigateTo: '/',
};

export const companyConfigsXDoc: Record<HOST, ICompanyConfig> = {
  "www.xdoc.no": xDocProductionConfig,
  "xdoc.no": xDocProductionConfig,
  "staging.xdoc.no": {
    ...xDocProductionConfig,
    mode: EAppMode.STAGING,
    appName: 'STG xdoc.no',
    sentryIoDsn: '',
  },
  "localhost:3636": {
    ...xDocProductionConfig,
    appName: 'DEV xdoc.no',
    sentryIoDsn: '',
    mode: EAppMode.DEVELOPMENT,
    apiBaseUrl: 'http://localhost:4020',
    webBaseUrl: `http://${host}`,
    workerActive: false,
  },
};
