import {EDynaCMSRights} from "server-app/dist/interfaces";

import {buildUrlByRoutePath} from "utils-library/dist/utils";

import {IAppRoute} from "../../application/config/IAppRoute";

export const routeDynaCMSArticleCreatePaths: IAppRoute<{
  countryId: string;
  languageId: string;
}> = {
  title: 'Create Article',
  routePath: '/dyna-cms/create-article/:countryId/:languageId',
  getRoutePath: (
    {
      countryId,
      languageId,
    } = {
      languageId: '',
      countryId: '',
    },
  ) =>
    buildUrlByRoutePath({
      routePath: routeDynaCMSArticleCreatePaths.routePath,
      pathParams: {
        countryId,
        languageId,
      },
    }),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EDynaCMSRights.ARTICLES_CREATE,
  ],
  requiresLatestClientVersion: true,
  render: () => null,
};
