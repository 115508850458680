import {
  API_PATH_ApiArticleHeaderGetSearchV2,
  IApiArticleHeaderGetSearchBodyRequestV2,
  IApiArticleHeaderGetSearchResponseV2,
} from "server-app/dist/interfaces";

import {apiFetch} from "../../../../../api/apiFetch";

export const apiArticleHeaderGetSearch = async (args: IApiArticleHeaderGetSearchBodyRequestV2): Promise<IApiArticleHeaderGetSearchResponseV2> => {
  return apiFetch.request<IApiArticleHeaderGetSearchBodyRequestV2, null, IApiArticleHeaderGetSearchResponseV2>({
    method: 'GET',
    path: API_PATH_ApiArticleHeaderGetSearchV2,
    query: args,
  });
};
