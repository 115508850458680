import {EThemeName} from "ui-components/dist/ThemeProvider";
import {EProgressIconType} from "ui-components/dist/ProgressIcon";

import {
  IDynaCMSArticleBlockAddress,
  IDynaCMSArticleAddress,
  ECompany,
} from "server-app/dist/interfaces";


import {EDynaAdOrientation} from "../core/dyna-ads/interfaces";

export enum EAppMode {
  DEVELOPMENT = "DEVELOPMENT",
  STAGING = "STAGING",
  PRODUCTION = "PRODUCTION",
}

export interface ICompanyConfig {
  // Basics
  company: ECompany;
  mode: EAppMode;

  appName: string;
  appDescription: string;   // Should be 45chars!

  lightTheme: EThemeName;
  darkTheme: EThemeName;
  progressIconType: EProgressIconType;

  appVersion: string;
  appCodeName: string;
  companyId: string;        // For which client company this app working on
  webBaseUrl: string;
  apiBaseUrl: string;
  googleMapsApiKey: string;
  defaultLanguageId: string;
  builtAt: string;
  workerActive: boolean;
  onSignOutNavigateTo?: string;

  // UI
  initialIsLoadingPage?: boolean;

  // Pages
  notFoundImage?: IDynaCMSArticleBlockAddress;
  signInPageImage?: IDynaCMSArticleBlockAddress;
  termsOfUseArticles: IDynaCMSArticleAddress[];     // Multi language article
  aboutArticles: IDynaCMSArticleAddress[];          // Multi language article
  contactArticles: IDynaCMSArticleAddress[];        // Multi language article

  // Sentry
  sentryIoDsn: string;

  // Google Analytics
  gaTrackingId?: string;

  // Ad providers
  googleAdSense?: IGoogleAdSenseConfig;
  monetag?: {               // Needs also script in html body, checkout the #1083504624 places
    multiTagScript?: string;
    plainTag?: string;
  };
  adsterra?: IAdsterraConfig;
}

export interface IAdsterraConfig {
  squareBannerIFrameScript?: string;
  landscapeBannerIFrameScript?: string;
  portraitBannerIFrameScript?: string;
}

export interface IGoogleAdSenseConfig {         // Needs also script in html body, checkout the #1083504624 places
  googleAdId: string;     // The id should be applied also wherever the <!-- GoogleAdSense --> script is loaded
  orientationSetup: Record<EDynaAdOrientation, { dataAdSlot: string }>;
}

export type HOST = string;
export const host = window.location.host;
