export const monitorCPUDelays = (intervalMs) => {
    let timerHandler = null;
    let lastTimestamp = Date.now();
    let stopped = false;
    const checkDelays = () => {
        const now = Date.now();
        const elapsed = now - lastTimestamp;
        const diff = elapsed - intervalMs;
        diff <= intervalMs
            ? console.log('monitorCPUDelays: no delays')
            : console.log(`monitorCPUDelays: DELAYED ${diff - intervalMs}ms`);
        lastTimestamp = now;
        if (!stopped)
            timerHandler = setTimeout(checkDelays, intervalMs);
    };
    timerHandler = setTimeout(checkDelays, intervalMs);
    return {
        stop: () => {
            clearTimeout(timerHandler);
            stopped = true;
        },
    };
};
