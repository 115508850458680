import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useMemo, } from "react";
import { useContainerResizeEvent } from "../useContainerResizeEvent";
import { getDataComponentName } from "../web-utils";
/**
 * Container that based on container (and not screen!) width, calls the render.
 *
 * The render function is called with the current width and height.
 *
 * If `TEBreakpoint` and the `breakpoints` are provided, the current breakpoint will be provided too making easier the conditional rendering.
 * @param props
 * @constructor
 */
export const ContainerSizeMonitor = (props) => {
    const { dataComponentName, fullHeight = false, breakpoints: _breakpoints = {}, render, } = props;
    const breakpoints = useMemo(() => Object.entries(_breakpoints)
        .map(([_enum, startsAtPx]) => ({
        startsAtPx: startsAtPx,
        enum: _enum,
    }))
        .sort((a, b) => a.startsAtPx - b.startsAtPx), [_breakpoints]);
    const [divSize, setDivSize] = useState({
        width: 0,
        height: 0,
    });
    const { width, height, } = divSize;
    const { ref } = useContainerResizeEvent({
        refreshRate: 100,
        skipOnMount: false,
        onResize: ({ width, height, }) => setDivSize({
            width,
            height,
        }),
    });
    const breakpoint = (() => {
        if (!breakpoints.length)
            return null;
        return breakpoints.reduce((acc, breakpoint) => {
            if (divSize.width >= breakpoint.startsAtPx)
                return breakpoint;
            return acc;
        }, breakpoints[0]);
    })();
    const isIn = (testBreakpoint) => {
        return testBreakpoint === (breakpoint === null || breakpoint === void 0 ? void 0 : breakpoint.enum);
    };
    const isInAndBelow = (testBreakpoint) => {
        // @ts-ignore
        return width < _breakpoints[testBreakpoint];
    };
    const isInAndAbove = (testBreakpoint) => {
        // @ts-ignore
        return width >= _breakpoints[testBreakpoint];
    };
    return (_jsx("div", { "data-component-name": getDataComponentName(dataComponentName, "ContainerSizeMonitor"), ref: ref, style: { height: fullHeight ? '100%' : undefined }, children: render({
            width,
            height,
            breakpoint: breakpoint ? breakpoint.enum : null,
            isIn,
            isInAndBelow,
            isInAndAbove: isInAndAbove,
        }) }));
};
