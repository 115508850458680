var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class DataLimitedContainer {
    //#region "Public"
    constructor(config) {
        this.config = config;
        this._metaDataLoaded = false;
        this._metaData = {};
    }
    save(id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const { maxSizeInBytes, containerName, storage: { onSave }, } = this.config;
            const rawData = JSON.stringify(data);
            if (rawData.length > maxSizeInBytes) {
                console.error(`DataLimitedContainer.save error 20240123193638: Item with id [${id}] exceeds the maxSizeInBytes: ${maxSizeInBytes} and cannot be saved`, { data });
                return;
            }
            yield this.makeSpaceIfNeeded(id, rawData.length);
            yield onSave(containerName, id, rawData);
            yield this.metaAddUpdateItem(id, rawData);
        });
    }
    load(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const { containerName, storage: { onLoad }, } = this.config;
            const rawData = yield onLoad(containerName, id);
            if (!rawData)
                return null;
            this.metaSetUsedItem(id).catch(error => console.error(error)); // Set it in parallel for faster resolve
            const metaData = yield this.loadMetaData();
            const meta = metaData[id];
            if (!meta)
                throw new Error(`Internal error 20240125192929: meta not found for this id: [${id}] but data exist. Meta data looks to be broken`);
            return {
                data: JSON.parse(rawData),
                meta,
            };
        });
    }
    getItemMeta(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const metaData = yield this.loadMetaData();
            return metaData[id] || null;
        });
    }
    delete(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const { containerName, storage: { onDelete }, } = this.config;
            yield onDelete(containerName, id);
            yield this.metaDeleteItem(id);
        });
    }
    deleteAll() {
        return __awaiter(this, void 0, void 0, function* () {
            const metaData = yield this.loadMetaData();
            const ids = Object.keys(metaData);
            for (const id of ids)
                yield this.delete(id);
        });
    }
    //#endregion "Public"
    //#region "Utils"
    stats() {
        return __awaiter(this, void 0, void 0, function* () {
            const { maxSizeInBytes } = this.config;
            const metaData = yield this.loadMetaData();
            const occupiedBytes = Object.values(metaData).reduce((acc, item) => acc + item.size, 0);
            return {
                itemsMeta: Object.values(metaData)
                    .sort((itemA, itemB) => itemA.readAt - itemB.readAt),
                space: {
                    max: this.config.maxSizeInBytes,
                    occupied: {
                        bytes: occupiedBytes,
                        percentage: 100 * occupiedBytes / maxSizeInBytes,
                    },
                    free: {
                        bytes: maxSizeInBytes - occupiedBytes,
                        percentage: 100 * (maxSizeInBytes - occupiedBytes) / maxSizeInBytes,
                    },
                },
            };
        });
    }
    //#endregion "Utils"
    //#region "Internal utils"
    makeSpaceIfNeeded(id, neededSpace) {
        return __awaiter(this, void 0, void 0, function* () {
            const { maxSizeInBytes } = this.config;
            const metaData = yield this.loadMetaData();
            const items = Object.values(metaData)
                .sort((itemA, itemB) => itemA.readAt - itemB.readAt);
            const occupiedSpace = items
                .filter(item => item.id !== id) // Do not add this as occupied, since it is going to be added again
                .reduce((acc, item) => acc + item.size, 0);
            const spaceToFreeUp = Math.max(neededSpace + occupiedSpace - maxSizeInBytes, 0);
            if (spaceToFreeUp === 0)
                return; // Exit, no need to free up space
            let freedSpace = 0;
            while (freedSpace < spaceToFreeUp && items.length) {
                const item = items.shift();
                if (item) {
                    yield this.delete(item.id);
                    freedSpace += item.size;
                }
            }
        });
    }
    //#endregion "Internal utils"
    //#region "Meta handling"
    metaAddUpdateItem(id, dataRaw) {
        return __awaiter(this, void 0, void 0, function* () {
            const metaData = yield this.loadMetaData();
            const itemMeta = metaData[id];
            const now = Date.now();
            if (itemMeta) {
                itemMeta.size = dataRaw.length;
                itemMeta.updatedAt = now;
            }
            else {
                metaData[id] = {
                    id,
                    size: dataRaw.length,
                    createdAt: now,
                    updatedAt: now,
                    readAt: now,
                };
            }
            yield this.saveMetaData();
        });
    }
    metaDeleteItem(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const metaData = yield this.loadMetaData();
            const itemMeta = metaData[id];
            if (!itemMeta)
                throw new Error(`internal error 20240123115047: Cannot deleteItemMeta, item not found with id`);
            delete metaData[id];
            yield this.saveMetaData();
        });
    }
    metaSetUsedItem(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const metaData = yield this.loadMetaData();
            const itemMeta = metaData[id];
            if (!itemMeta)
                throw new Error(`internal error 20240123115048: Cannot setUsed, item not found with id`);
            itemMeta.readAt = Date.now();
            yield this.saveMetaData();
        });
    }
    loadMetaData() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._metaDataLoaded)
                return this._metaData;
            const { containerName, storage: { onLoad }, } = this.config;
            const raw = yield onLoad(containerName + '__meta', 'items');
            this._metaDataLoaded = true;
            return this._metaData = raw ? JSON.parse(raw) : {};
        });
    }
    saveMetaData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { containerName, storage: { onSave }, } = this.config;
            yield onSave(containerName + '__meta', 'items', JSON.stringify(this._metaData || []));
        });
    }
}
