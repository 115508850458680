import {
  FlexContainerHorizontal,
  FlexItemMin,
} from "ui-components/dist/FlexContainer";
import {Box} from "ui-components/dist/Box";
import {InputSelect} from "ui-components/dist/InputSelect";
import {
  InputMultiSelect,
  EIInputMultiSelectVariant,
} from "ui-components/dist/InputMultiSelect";
import {
  Theme,
  SxProps,
} from "ui-components/dist/ThemeProvider";

import {IAppStore} from "../../../../state/IAppStore";

import {createIcon} from "ui-components/dist/IconComponent";
import WorldIcon from '@mui/icons-material/Public';
import LoadingIcon from '@mui/icons-material/TravelExplore';
import LanguageIcon from '@mui/icons-material/Translate';

export interface IDynaCMSCountriesLanguageSelectProps {
  store: IAppStore;
  showLanguages?: boolean; // Default is true
  showCountries?: boolean; // Default is true
}

export const DynaCMSCountriesLanguageSelect = ((props: IDynaCMSCountriesLanguageSelectProps): JSX.Element => {
  const {
    store: {
      dynaCMS: {
        state: {
          isLoading,
          settings,
          countryIds,
          languageId,
        },
        actions: {
          setCountries,
          setLanguage,
        },
      },
    },
    showLanguages = true,
    showCountries = true,
  } = props;

  const sxBlock: SxProps<Theme> = {
    backgroundColor: theme => theme.palette.grayShades.gray2,
    padding: theme => theme.spacing(1),
  };

  const handleCountriesChange = (countryIds: string[]) => setCountries(countryIds);
  const handleCountriesClose = () => window.location.reload();
  const handleLanguageChange = (languageId: string) => {
    setLanguage(languageId);
    window.location.reload();
  };

  return (
    <FlexContainerHorizontal
      alignVertical="middle"
      sx={{'>*:not(:first-of-type)': {marginLeft: theme => theme.spacing(1)}}}
    >
      <FlexItemMin
        sx={sxBlock}
        show={showCountries && settings.countries.length > 1}
      >
        <InputMultiSelect
          sx={{width: 128}}
          label="Country"
          variant={EIInputMultiSelectVariant.FILLED}
          options={
            settings.countries
              .map(country => ({
                value: country.id,
                label: country.id.toUpperCase(),
              }))
          }
          disabled={isLoading}
          icon={
            isLoading
              ? <LoadingIcon/>
              : <WorldIcon/>
          }
          value={
            countryIds
              .filter(countryId => settings.countries.find(c => c.id === countryId))
          }
          onChange={handleCountriesChange}
          onClose={handleCountriesClose}
        />
      </FlexItemMin>
      <FlexItemMin
        sx={sxBlock}
        show={showLanguages && settings.languages.length > 1}
      >
        <Box sx={{width: 128}}>
          <InputSelect
            label="Language"
            Icon={createIcon.byMuiIcon(LanguageIcon)}
            ariaLabel="Language"
            options={settings.languages.map(language => ({
              value: language.id,
              label: language.label,
            }))}
            value={languageId}
            onChange={handleLanguageChange}
          />
        </Box>
      </FlexItemMin>
    </FlexContainerHorizontal>
  );
});
