import {
  createStore,
  dynaduxDebugMiddleware,
} from "dynadux";

import {INavigateTo} from "../core/application/interfaces/INavigateTo";

import {createAppSection} from "../core/application";
import {createUserAuthenticationSection} from "../core/user-authnentication";
import {createDynaCMSSection} from "../core/dyna-cms";

const getStoreDebugMode = ()=> localStorage.getItem('_debug_appStore') === 'true';

(window as any)._debug_appStoreActivation = (activate?: boolean)=> {
  if (activate === undefined) {
    console.log('_debug_appStore is', getStoreDebugMode());
    return;
  }
  localStorage.setItem('_debug_appStore', activate.toString());
  console.log('_debug_appStore set to', getStoreDebugMode());
};

export const createAppStore = (
  {navigateTo}: {
    navigateTo: (navigateTo: INavigateTo) => void;
  },
) => {
  const store = createStore({
    middlewares: [
      getStoreDebugMode() && dynaduxDebugMiddleware(
        {
          debuggerStoreName: '_debug_appStore',
          consoleDispatch: true,
          consolePayload: true,
          consoleState: true,
        },
      ),
    ],
  });

  const appStore = {
    provider: store.provider,

    app: createAppSection({
      store,
      navigateTo,
    }),
    userAuth: createUserAuthenticationSection(store),
    dynaCMS: createDynaCMSSection({store}),
  };

  if (getStoreDebugMode()) {
    (window as any)._debug_dynaduxStore = store;
  }

  return appStore;
};
