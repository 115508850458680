import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, useRef, } from "react";
import objectHash from "object-hash";
import { Loading } from "./Loading";
import { cropDivBackgroundImage } from "./utils/cropDivBackgroundImage";
import * as styles from "./DynaImage.module.less";
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { cn } from "./utils/cn";
export var EImageMode;
(function (EImageMode) {
    EImageMode["FIT"] = "FIT";
    EImageMode["FILL"] = "FILL";
})(EImageMode || (EImageMode = {}));
export const DynaImage = (props) => {
    const { className, style: userStyle, imgStyle = {}, src, mode = EImageMode.FIT, alt, content, showLoadingSpinner = false, showBrokenImageOnFail = true, crop, horizontalMirror, verticalMirror, blackAndWhite, onLoad, onError, } = props;
    const refDivWithBackgroundImage = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loadFailed, setLoadFailed] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        setLoadFailed(false);
    }, [src]);
    const style = Object.assign({ backgroundImage: `url(${src})`, backgroundSize: (() => {
            switch (mode) {
                case EImageMode.FIT:
                    return 'contain';
                case EImageMode.FILL:
                    return 'cover';
            }
        })(), transform: [
            horizontalMirror ? 'scaleX(-1)' : '',
            verticalMirror ? 'scaleY(-1)' : '',
        ].filter(Boolean).join(' '), filter: blackAndWhite ? 'grayscale(100%)' : undefined }, imgStyle);
    const key = objectHash(Object.assign(Object.assign({}, props), { content: undefined }));
    const handleLoad = () => {
        if (crop && refDivWithBackgroundImage.current) {
            cropDivBackgroundImage(refDivWithBackgroundImage.current, crop.percentageX1, crop.percentageY1, crop.percentageX2, crop.percentageY2);
        }
        setIsLoading(false);
        onLoad && onLoad();
    };
    const handleError = (e) => {
        setIsLoading(false);
        setLoadFailed(true);
        onError && onError(e);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Loading, { className: cn(styles.root, className), style: userStyle, isLoading: showLoadingSpinner && isLoading, children: _jsxs("div", { className: styles.imageContainer, ref: refDivWithBackgroundImage, style: style, children: [showBrokenImageOnFail && !!loadFailed && (_jsx("div", { className: styles.loadFailedContainer, children: _jsx(BrokenImageIcon, { className: styles.brokenIcon }) })), !!content && (_jsx("div", { className: styles.contentContainer, children: content }))] }, key) }), _jsx("img", { hidden: true, src: src, alt: alt, onLoad: handleLoad, onError: handleError })] }));
};
