import {ECompany} from "server-app/dist/interfaces";

import {
  appVersion,
  appCodeName,
  builtAt,
} from "../../core/application/config/appVersion";

import {
  ICompanyConfig,
  EAppMode,
  HOST,
  host,
} from "../ICompanyConfig";

import {EProgressIconType} from "ui-components/dist/ProgressIcon";
import {EThemeName} from "ui-components/dist/ThemeProvider";

export const netDotFashionProductionConfig: ICompanyConfig = {
  company: ECompany.THE_GIRL_GUNS,
  mode: EAppMode.PRODUCTION,
  appName: 'net.fashion',
  appDescription: 'Fashion portal - Beauty, Deco, Celebs & Lifestyle',
  appVersion,
  appCodeName,
  progressIconType: EProgressIconType.DOUBLE_PULSE,
  companyId: 'net-fashion',
  lightTheme: EThemeName.FASHIONX_LIGHT,
  darkTheme: EThemeName.FASHIONX_DARK,
  apiBaseUrl: window.location.origin,
  webBaseUrl: window.location.origin,
  googleMapsApiKey: "AIzaSyCK8ups8L2mke1XPN8hUynDrXyNAsApc7g", // Google Maps key is website referrer restricted
  defaultLanguageId: 'en',
  builtAt,
  workerActive: true,
  sentryIoDsn: "",
  signInPageImage: undefined,
  termsOfUseArticles: [],
  aboutArticles: [],
  contactArticles: [],
};

export const companyConfigsNetDotFashion: Record<HOST, ICompanyConfig> = {
  "www.net.fashion": netDotFashionProductionConfig,
  "staging.net.fashion": {
    ...netDotFashionProductionConfig,
    appName: 'STG net.fashion',
  },
  "localhost:3620": {
    ...netDotFashionProductionConfig,
    appName: 'DEV net.fashion',
    workerActive: false,
    apiBaseUrl: 'http://localhost:4020',
    webBaseUrl: `http://${host}`,
  },
};
