import {buildUrlByRoutePath} from "utils-library/dist/utils";

import {IAppRoute} from "../../application/config/IAppRoute";

export interface IRouteArgs {
  countryId: string;
  languageId: string;
  groupId: string;
  urlId: string;
}

export const routeDynaCMSArticleViewerByUrlIdPaths: IAppRoute<IRouteArgs> = {
  title: 'Article view',
  routePath: '/article/:countryId/:languageId/:groupId/:urlId',
  exact: true,
  getRoutePath: (  // Dev note: You can create the link by getDynaCMSArticleViewerByArticle()
    pathParams = {
      countryId: '',
      languageId: '',
      groupId: '',
      urlId: '',
    },
  ) =>
    buildUrlByRoutePath({
      routePath: routeDynaCMSArticleViewerByUrlIdPaths.routePath,
      pathParams,
    }),
  userHasAllRights: [],
  userHasAnyOfRights: [],
  requiresLatestClientVersion: false,
  render: () => null,
};
