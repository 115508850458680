import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, } from "react";
import { useResizeEvent } from "./utils/useResizeEvent";
export const DynaResponsiveImageByContainer = (props) => {
    const { className, imgStyle = {}, srcSet, alt, content, horizontalMirror, verticalMirror, blackAndWhite, zoom, onLoad, onError, } = props;
    const imageVersions = Object.entries(srcSet)
        .filter(([width]) => width !== "main")
        .map(([_width, url]) => ({
        width: parseInt(_width.slice(1)),
        url,
    }))
        .sort((a, b) => a.width - b.width);
    const [imageUrl, setImageUrl] = useState('');
    const [containerWidth, setContainerWidth] = useState(0);
    const { ref } = useResizeEvent({
        skipOnMount: false,
        onResize: ({ width: containerWidth }) => setContainerWidth(containerWidth),
    });
    useEffect(() => {
        const imageVersion = imageVersions.find(version => version.width >= containerWidth);
        const url = imageVersion
            ? imageVersion.url
            : imageVersions[imageVersions.length - 1].url;
        setImageUrl(url);
    }, [containerWidth, srcSet.main]);
    if (zoom && (verticalMirror || horizontalMirror)) {
        return (_jsxs("div", { children: ["DynaResponsiveImage: ", _jsx("code", { children: "zoom" }), " cannot work with ", _jsx("code", { children: "horizontalMirror" }), " or ", _jsx("code", { children: "verticalMirror" }), "."] }));
    }
    return (_jsx("div", { ref: ref, className: className, "data-component-name": "DynaResponsiveImage", style: {
            position: 'relative',
            overflow: 'hidden',
        }, children: !!imageUrl && (_jsxs(_Fragment, { children: [_jsx("img", { width: "100%", alt: alt, src: imageUrl, style: Object.assign({ transform: [
                            horizontalMirror ? 'scaleX(-1)' : '',
                            verticalMirror ? 'scaleY(-1)' : '',
                            zoom ? `scale(${zoom.zoom})` : '',
                        ].filter(Boolean).join(' '), transformOrigin: zoom ? `${zoom.percentageX}% ${zoom.percentageY}%` : undefined, filter: blackAndWhite ? 'grayscale(100%)' : undefined }, imgStyle), onLoad: onLoad, onError: onError }), _jsx("div", { style: {
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }, children: content })] })) }));
};
