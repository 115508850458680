import {
  useRef,
  useMemo,
  useEffect,
} from "react";
import * as qs from "qs";

import {connect} from "react-dynadux";

import {
  Route,
  Switch,
} from "react-router-dom";

import {arrayRemoveConsecutiveDuplicates} from "utils-library/dist/array";
import {useLoadData} from "ui-components/dist/useLoadData";

import {IAppStore} from "../../../state/IAppStore";
import {
  appNotificationIcons,
  getUserNotificationIcon,
  getUserNotificationIconOptions,
} from "../notification-icons";

import {apiDynaIntlTranslationsGet} from "../../dyna-intl/api/apiDynaIntlTranslationsGet";

import {TAppWrapperComponent} from "../config/IAppWrapperProps";
import {IAppRoute} from "../config/IAppRoute";
import {IAppMenu} from "../config/IAppMenu";

import {route404} from "../routes/route404";

import {getAppWrapper} from "../config/company/getAppWrapper";
import {getAppRoutes} from "../config/company/getAppRoutes";
import {getAppMenusMain} from "../config/company/getAppMenus";

import {CheckAppVersion} from "../components/CheckAppVersion";

import {AppRoute} from "./AppRoute";
import {AccessPageCheck} from "./AccessPageCheck";

export interface IAppRouterProps {
  store: IAppStore;
}

export const AppRouterSwitch = connect((props: IAppRouterProps): JSX.Element => {
  const {
    store,
    store: {
      userAuth: {
        state: {
          user: {
            displayName,
            firstName,
          },
        },
        actions: {clearUserAuthError},
        utils: {
          userHasAllRights,
          userHasAnyOfRights,
        },
      },
      dynaCMS: {
        state: {
          countryIds,
          languageId,
        },
      },
    },
  } = props;

  const {
    data: appRoutes,
    isLoading: isLoadingAppRoutes,
  } = useLoadData<IAppRoute[]>({
    defaultData: [],
    load: () => getAppRoutes(),
  });

  const refAppWrapper = useRef<TAppWrapperComponent>(() => <span/>);
  useEffect(() => {
    (async () => refAppWrapper.current = await getAppWrapper())();
  }, []);
  const AppWrapper = refAppWrapper.current;

  const {data: translations} = useLoadData<Record<string, string>>({
    defaultData: {},
    reloadDep: {appRoutes},
    load: async () => {
      if (!appRoutes.length) return {};
      const {translations} = await apiDynaIntlTranslationsGet({
        countryId: countryIds[0] || 'gr',
        languageId,
        tks: [
          ...appRoutes.map(appRoute => appRoute.titleTk || "").filter(Boolean),
          ...getUserNotificationIconOptions(store).map(option => option.labelTk || ""),
        ],
      });
      return translations;
    },
  });

  const userOptions =
    getUserNotificationIconOptions(store)
      .map(option => ({
        ...option,
        label: translations[option.labelTk || ""] || option.label,
      }));

  const {data: appMenusMain} = useLoadData<Array<IAppMenu | "DIVIDER">>({
    defaultData: [],
    load: getAppMenusMain,
  });

  const handleLogoClick = (): void => {
    clearUserAuthError();
  };

  const routes = useMemo<JSX.Element[]>(() => {
    const topRoutes: IAppRoute[] = [];
    const restRoutes: IAppRoute[] = [];
    appRoutes.forEach(route => {
      if (route.routePath === '/') topRoutes.push(route); else restRoutes.push(route);
    });

    const routes = [
      ...topRoutes,
      ...restRoutes,
    ];

    if (!isLoadingAppRoutes) routes.push(route404);

    return routes
      .map((
        {
          title,
          titleTk = "",
          menuId,
          routePath,
          exact = false,
          userHasAllRights,
          userHasAnyOfRights,
          requiresLatestClientVersion,
          render,
        }: IAppRoute,
        index: number = 0,
      ): JSX.Element => {
        const applyTitle = translations[titleTk] || title;
        return (
          <Route
            key={index}
            path={routePath}
            exact={exact}
            render={(route) => (
              <AccessPageCheck
                pageTitle={applyTitle}
                userHasAllRights={userHasAllRights}
                userHasAnyOfRights={userHasAnyOfRights}
              >
                <AppRoute
                  appTitle={applyTitle}
                  menuId={menuId}
                >
                  <CheckAppVersion check={!!requiresLatestClientVersion}/>
                  {render({
                    pathParams:
                      route.match.params as any,
                    queryParams:
                      window.location.search
                        ? qs.parse(window.location.search.substring(1)) as any
                        : {} as any,
                  })}
                </AppRoute>
              </AccessPageCheck>
            )}
          />
        );
      });
  }, [
    appRoutes,
    isLoadingAppRoutes,
    Object.values(translations),
  ]);

  const userName = displayName || firstName || undefined;

  return (
    <AppWrapper
      store={store}

      notificationIcons={appNotificationIcons(store)}

      userIcon={getUserNotificationIcon(store)}
      userName={userName}
      userOptions={userOptions}

      menus={
        (() => {
          const outputA = appMenusMain
            .filter(appMenu => (
              appMenu === "DIVIDER" ||
              (
                userHasAllRights(appMenu.userHasAllRights) &&
                userHasAnyOfRights(appMenu.userHasAnyOfRights) &&
                (appMenu.countryId === undefined || countryIds.includes(appMenu.countryId)) &&
                (appMenu.languageId === undefined || appMenu.languageId === languageId)
              )
            ));
          const outputB = arrayRemoveConsecutiveDuplicates(outputA);
          if (outputB[outputB.length - 1] === "DIVIDER") outputB.splice(-1);
          return outputB;
        })()
      }

      onAppLogoClick={handleLogoClick}
    >
      <Switch>
        {routes}
      </Switch>
    </AppWrapper>
  );
});
