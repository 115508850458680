import { DataLimitedContainer } from "../data-limited-container";
import { memoryStorageApi } from "./memoryStorageApi";
export class DataLimitedContainerMemory extends DataLimitedContainer {
    constructor(config) {
        super({
            containerName: config.containerName,
            maxSizeInBytes: config.maxSizeInBytes,
            storage: memoryStorageApi,
        });
    }
}
