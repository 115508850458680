import {ECompany} from "server-app/dist/interfaces";

import {EThemeName} from "ui-components/dist/ThemeProvider";
import {EProgressIconType} from "ui-components/dist/ProgressIcon";

import {
  appVersion,
  appCodeName,
  builtAt,
} from "../../core/application/config/appVersion";

import {EDynaAdOrientation} from "../../core/dyna-ads/interfaces";

import {
  EAppMode,
  ICompanyConfig,
  HOST,
  IGoogleAdSenseConfig,
  IAdsterraConfig,
  host,
} from "./../ICompanyConfig";

const adoreGoogleAdSenseConfig: IGoogleAdSenseConfig = {
  googleAdId: 'ca-pub-6486460928215437',
  orientationSetup: {
    [EDynaAdOrientation.AUTO]: {dataAdSlot: '3675801375'},
    [EDynaAdOrientation.HORIZONTAL]: {dataAdSlot: '6092642770'},
    [EDynaAdOrientation.VERTICAL]: {dataAdSlot: '6301964715'},
    [EDynaAdOrientation.RECTANGLE]: {dataAdSlot: '3675801375'},
  },
};

// DCMS-00599 Norton blocks us completely due to Adsterra ads
// Temporary (any maybe permanently) disabled due to DCMS-00599 issue.
const adoreAdsterraConfigActualConfig: IAdsterraConfig = {
  squareBannerIFrameScript: '<iframe src="//www.profitabledisplaynetwork.com/watchnew?key=02543b2e82cc6f4b3db9994553a0bd9a" width="300" height="250" frameborder="0" scrolling="no"></iframe>',
  landscapeBannerIFrameScript: '<iframe src="//www.profitabledisplaynetwork.com/watchnew?key=67c873b6fb2867e80e8ca1e4603f144f" width="728" height="90" frameborder="0" scrolling="no"></iframe>',
  portraitBannerIFrameScript: '<iframe src="//www.profitabledisplaynetwork.com/watchnew?key=7c749334208e945d501ba34926327961" width="160" height="300" frameborder="0" scrolling="no"></iframe>',
};
adoreAdsterraConfigActualConfig;
const adoreAdsterraConfig = undefined;

const adoreGrProductionConfig: ICompanyConfig = {
  company: ECompany.ADORE_GR,
  mode: EAppMode.PRODUCTION,
  appName: 'adore.gr',
  appDescription: 'Μόδα, Ομορφιά, Διακοσμήση, Celebs και Lifestyle',
  lightTheme: EThemeName.FASHIONX_LIGHT,
  darkTheme: EThemeName.FASHIONX_DARK,
  progressIconType: EProgressIconType.DOUBLE_PULSE,
  appVersion,
  appCodeName,
  companyId: ECompany.ADORE_GR,
  apiBaseUrl: window.location.origin,
  webBaseUrl: window.location.origin,
  googleMapsApiKey: "AIzaSyCK8ups8L2mke1XPN8hUynDrXyNAsApc7g",      // Google Maps key is website referrer restricted
  defaultLanguageId: 'el',
  builtAt,
  workerActive: true,
  sentryIoDsn: "https://0f3d0df7432879dfca32e9d441291f8a@o4506004075905024.ingest.sentry.io/4506066751586304",
  googleAdSense: adoreGoogleAdSenseConfig,
  notFoundImage: {
    groupId: 'internal',
    countryId: 'gr',
    languageId: 'el',
    articleId: '6dbd79b9-2f848f9107e04117ae',
    blockId: 'b1c7139642f84906b2e3293ba84',
  },
  signInPageImage: {
    groupId: 'internal',
    countryId: 'gr',
    languageId: 'el',
    articleId: '6dbd79b9-2f848f9107e04117ae',
    blockId: 'b2633aa1a2f848f9108849c140f',
  },
  termsOfUseArticles: [{
    groupId: 'page',
    countryId: 'gr',
    languageId: 'el',
    articleId: '49ae1d73-3c48e311e2af026a03',
  }],
  aboutArticles: [{
    groupId: 'page',
    countryId: 'gr',
    languageId: 'el',
    articleId: '45041890-2f848e4cebf7bb14e4',
  }],
  contactArticles: [
    {
      groupId: 'page',
      countryId: 'gr',
      languageId: 'el',
      articleId: '2ff1f666-2f8490e2dddf5e1bcf',
    },
  ],
  gaTrackingId: 'G-0M2K5D3FB0',
  adsterra: adoreAdsterraConfig,
  onSignOutNavigateTo: '/',
};

export const companyConfigsAdoreGr: Record<HOST, ICompanyConfig> = {
  "www.adore.gr": adoreGrProductionConfig,
  "staging.adore.gr": {
    ...adoreGrProductionConfig,
    mode: EAppMode.STAGING,
    appName: 'STG adore.gr',
    sentryIoDsn: '',
    // Todo: Note: it uses Articles of production DB
  },
  "localhost:3630": {
    ...adoreGrProductionConfig,
    appName: 'DEV adore.gr',
    sentryIoDsn: '',
    mode: EAppMode.DEVELOPMENT,
    apiBaseUrl: 'http://localhost:4020',
    webBaseUrl: `http://${host}`,
    workerActive: false,
    // Todo: Note: it uses Articles of production DB
  },
};
