export const getDefaultDynaCMSArticleAddress = () => ({
    countryId: '',
    languageId: '',
    groupId: '',
    articleId: '',
});
export const validateDynaCMSArticleAddress = ({ articleAddress, allowEmpty, }) => {
    return validateFields(articleAddress, allowEmpty);
};
export const getDefaultDynaCMSArticleBlockAddress = () => (Object.assign(Object.assign({}, getDefaultDynaCMSArticleAddress()), { blockId: '' }));
export const validateDynaCMSArticleBlockAddress = ({ articleBlockAddress, allowEmpty, }) => {
    return validateFields(articleBlockAddress, allowEmpty);
};
const validateFields = (value, allowEmpty) => {
    if (allowEmpty && Object.values(value).filter(Boolean).length === 0)
        return '';
    return Object.entries(value)
        .map(([key, value]) => {
        if (!value)
            return `${key} is required`;
        return "";
    })
        .filter(Boolean)
        .join(', ');
};
