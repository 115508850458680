import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

import {EFileStorageRights} from "server-app/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";

const UploadPublicFileTestPage = lazy(() => import('../pages/UploadPublicFileTestPage'));

export const routeUploadPublicFileTestPage: IAppRoute = {
  menuId: '',
  title: 'Upload file test page',
  routePath: '/test/upload-file-test-page',
  getRoutePath: () => '/test/upload-file-test-page',
  userHasAllRights: [],
  userHasAnyOfRights: [EFileStorageRights.UPLOAD_TEST_FILE],
  render: () => (
    <LazyLoadComponent>
      <UploadPublicFileTestPage/>
    </LazyLoadComponent>
  ),
};
