var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, } from "react";
import { Paper } from "../Paper";
import { Condition } from "../Condition";
import { ContainerSection } from "../ContainerSection";
import { checkForAdBlocker } from "./checkForAdBlocker.util";
import AdBlockedIcon from '@mui/icons-material/ViewInAr';
import { createIcon } from "../IconComponent";
export const CheckForAdBlocker = (props) => {
    const { messageType, children, } = props;
    const [hasBlocker, setHasBlocker] = useState(false);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const has = yield checkForAdBlocker();
            setHasBlocker(has);
        }))();
    }, []);
    if (!hasBlocker)
        return children;
    return (_jsxs(Paper, { children: [_jsx(ContainerSection, { h: 2, Icon: createIcon.byMuiIcon(AdBlockedIcon), title: "Uh oh. Looks you are using ad blocker.", subtitle: [
                    messageType === "allow-ads" && "We charge advertisers instead of our audience.",
                    messageType === "blocked-content-by-mistake" && "Ad-blockers are not blocking only ads but other valuable content also!",
                ].filter(Boolean).join() }), _jsx(Condition, { if: messageType === "allow-ads", children: _jsxs("p", { children: ["Please disable the ad block to show your support for ", window.location.host, "."] }) }), _jsx(Condition, { if: messageType === "blocked-content-by-mistake", children: _jsxs("p", { children: ["Please disable the ad block for the ", window.location.host, " to have full access."] }) })] }));
};
