/**
 * Convert an HTML script to plain text.
 *
 * Technically it replaces html tags.
 * Pros:
 * - Is available in the node and web environment.
 * - Very fast, no DOM use or other deps.
 * Cons:
 * - No tested in very complex HTML scripts.
 *
 * @param html
 */
export const convertHTMLScriptToPlainText = (html) => html
    .replace(regExpHTMLTags, '')
    .split('\n')
    .map(t => t.trim())
    .join('\n');
const regExpHTMLTags = /<[^>]*>/g;
// Const regExpSpaceBeforeNewLine = /[ \t]+\n/g;
