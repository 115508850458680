var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { guid } from "dyna-guid";
import { getRuntimeStack } from "../utils";
import { getDurationString } from "../utils";
const global = typeof window !== "undefined" ? window : process;
if (!global._debug_)
    global._debug_ = {};
export const createDebugSetup = (setupName) => {
    const globalName = getDebugSetupKey(setupName);
    if (global[globalName])
        console.warn(`createDebug("${setupName}") already called! If this didn't happen by mistake and it's just one line of code creating the debug setup, it’s a sign that the file is being loaded more than once in memory by your bundler.`);
    // Use the existed setup or create a new one
    const debugSetup = global[globalName]
        || {
            get enabled() {
                return getSetupActive(setupName);
            },
            set enabled(enabled) {
                setSetupActive(setupName, enabled);
            },
            get consoleLogs() {
                return getSetupConsoleEnabled(setupName);
            },
            set consoleLogs(console) {
                setSetupConsoleEnabled(setupName, console);
            },
            operations: [],
            lastOperation: null,
            startOperation: (operationConfig = {}) => {
                const debugInstance = new DebugOperation(Object.assign({ setupName }, operationConfig));
                if (getSetupActive(setupName)) {
                    debugSetup.operations.push(debugInstance);
                    debugSetup.lastOperation = debugInstance;
                }
                return debugInstance;
            },
        };
    // Update the global _debug_.nnnDebugSetup
    global._debug_[setupName] = debugSetup;
    // Global API
    global._debug_.list = () => Object.keys(global._debug_)
        .reduce((acc, key) => {
        if (key === 'list')
            return acc;
        acc.push({
            setupName: key,
            enabled: global._debug_[key].enabled,
            consoleEnabled: global._debug_[key].consoleEnabled,
            operations: global._debug_[key].operations,
            setup: global._debug_[key],
        });
        return acc;
    }, []);
    global._debug_.logAll = (message, data) => {
        global._debug_
            .list()
            .forEach((item) => { var _a; return (_a = item.setup.lastOperation) === null || _a === void 0 ? void 0 : _a.log(message, data); });
    };
    // Update the global _debug_nnnDebugSetup
    global[globalName] = debugSetup;
    return debugSetup;
};
export class DebugOperation {
    constructor(config) {
        this.config = config;
        //#region "Private"
        this.lastLogAt = Date.now();
        this.logs = [];
        //#endregion "Private"
        //#region "Log methods"
        /**
         * Logs a message with optional data that can be hard-copied! (by default) or not.
         *
         * Captured data of onLogCaptureData are always hard-copied!.
         */
        this.log = (message, _data, hardcopyData = true) => {
            if (!this.enabled)
                return;
            const data = _data === undefined
                ? undefined
                : hardcopyData
                    ? hardCopy(_data)
                    : Object.assign(Object.assign({}, _data), { "Debug operation": "Warning: This data is not hard-copied!" });
            const now = Date.now();
            const log = {
                after: (now - this.lastLogAt > 1000 ? "S<" : "  ") // Indicate the elapsed time that is greater than second
                    + getDurationString(this.lastLogAt, now, 1).padStart(8),
                message,
                data,
                capturedData: this.config.onLogCaptureData
                    ? hardCopy(this.config.onLogCaptureData())
                    : { "Debug operation": "Info: No onLogCaptureData provided" },
                time: formatDate(new Date(now)),
                timeValue: now,
                logIndex: this.logs.length,
                stack: getRuntimeStack().slice(2),
            };
            this.lastLogAt = now;
            if (log.data === undefined)
                delete log.data;
            this.logs.push(log);
            if (getSetupConsoleEnabled(this.config.setupName)) {
                console.log(`${getDebugSetupKey(this.config.setupName)}:`, `after: ${log.after.trim()}`, log.message, log);
            }
        };
        this.logValue = (message, value) => {
            this.log(message, value);
            return value;
        };
        this.timeKeys = {};
        this.time = (timeKey) => {
            if (!this.enabled)
                return;
            if (this.timeKeys[timeKey]) {
                console.error(`DebugOperation.time(): time key [${timeKey}] already initialized (is not timeEnd()ed)`);
            }
            this.timeKeys[timeKey] = performance.now();
        };
        this.timeEnd = (timeKey) => {
            if (!this.enabled)
                return;
            if (!this.timeKeys[timeKey]) {
                console.error(`DebugOperation.timeEnd(): time key [${timeKey}] doesn't exist`);
                return;
            }
            this.log(`Time: [${timeKey}] ${performance.now() - this.timeKeys[timeKey]}ms`);
            delete this.timeKeys[timeKey];
        };
        this.promiseTimeCounter = (title, promisedMethod) => __awaiter(this, void 0, void 0, function* () {
            if (!this.enabled)
                return promisedMethod();
            const start = performance.now();
            const resolve = yield promisedMethod();
            this.log(`promiseTimeCounter: [${title}] ${performance.now() - start}ms`);
            return resolve;
        });
        this.logMethod = (message, method) => {
            const methodId = guid(1);
            return (...args) => {
                this.log(message + '>before', {
                    methodId,
                    args,
                });
                const started = Date.now();
                const output = method(...args);
                this.log(message + '> after', {
                    args,
                    methodId,
                    output,
                    duration: getDurationString(started, Date.now()),
                });
                return output;
            };
        };
        /**
         * Monitor and log all class's method calls
         *
         * Note: args are not hard-copied but cbCaptureData's data are always hard-copied.
         */
        this.logInstanceMethods = ({ instance, logPrefix, _this = instance, }) => {
            if (!this.enabled)
                return;
            global.classInstance = instance;
            const keys = Object.keys(instance)
                .concat(Object.getOwnPropertyNames(instance.__proto__))
                .concat("setState");
            this.log('logInstanceMethods started', { monitoringMethods: keys.filter(key => typeof instance[key] === 'function') });
            for (const key of keys) {
                if (typeof instance[key] === 'function') {
                    const originalMethod = instance[key];
                    // eslint-disable-next-line @typescript-eslint/no-this-alias
                    const self = this;
                    const prefix = logPrefix ? `${logPrefix}>` : "";
                    instance[key] = (...args) => {
                        self.log(`${prefix}before > ${key}()`, {
                            args,
                            "Debug operation": "Warning: These args are not hard-copied!",
                        }, false);
                        const output = originalMethod.apply(_this, args);
                        self.log(`${prefix} after > ${key}()`, {
                            methodArgs: args,
                            methodOutput: output,
                            "Debug operation": "Warning: Output is not hard-copied!",
                        }, false);
                        return output;
                    };
                }
            }
        };
        if (!config.operationName)
            config.operationName = '---';
        this.log([
            'Operation',
            this.config.operationName && `[${this.config.operationName}]`,
            'started',
            this.enabled ? '' : `but currently is not enabled, nothing will be collected, call: \`${getDebugSetupKey(this.config.setupName)}.enabled=true\` to start`,
        ]
            .filter(Boolean)
            .join(' ')
            + '.');
    }
    get enabled() {
        return getSetupActive(this.config.setupName);
    }
    //#endregion "Log methods"
    //#region "Children operations"
    startChildOperation(operationConfig = {}) {
        const childOperation = new DebugOperation(Object.assign({ setupName: this.config.setupName }, operationConfig));
        this.log(`Start CHILD operation${operationConfig.operationName ? `: ${operationConfig.operationName}` : ""}`, childOperation, false);
        return childOperation;
    }
}
// Utils
const getDebugSetupKey = (setupName) => `_debug_${setupName}`;
const setSetupActive = (setupName, active) => {
    setLs(`${getDebugSetupKey(setupName)}/active`, active);
};
const getSetupActive = (setupName) => {
    return getLs(`${getDebugSetupKey(setupName)}/active`, false);
};
const setSetupConsoleEnabled = (setupName, console) => {
    setLs(`${getDebugSetupKey(setupName)}/console`, console);
};
const getSetupConsoleEnabled = (setupName) => {
    return getLs(`${getDebugSetupKey(setupName)}/console`, true);
};
const getLs = (lsKey, defaultValue) => {
    const lsData = localStorage.getItem(lsKey);
    if (!lsData) {
        return defaultValue;
    }
    const data = JSON.parse(lsData);
    if (data === null) {
        return defaultValue;
    }
    return data;
};
const setLs = (lsKey, value) => {
    const data = JSON.stringify(value);
    localStorage.setItem(lsKey, data);
};
const formatDate = (date = new Date()) => {
    const year = date.getFullYear().toString()
        .padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString()
        .padStart(2, '0');
    const hours = date.getHours().toString()
        .padStart(2, '0');
    const minutes = date.getMinutes().toString()
        .padStart(2, '0');
    const seconds = date.getSeconds().toString()
        .padStart(2, '0');
    const milliseconds = date.getMilliseconds().toString()
        .padStart(3, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}:${milliseconds}`;
};
const hardCopy = (obj) => JSON.parse(debugJsonStringify(obj));
const debugJsonStringify = (obj, spacing = 0) => {
    const cache = new WeakMap();
    const replacer = (_key, value) => {
        if (value === global)
            return "[global]";
        if (value instanceof Error)
            return `[Error] ${value.message || "Unknown error"}`;
        if (typeof value === 'object' && value !== null) {
            if (cache.has(value))
                return '[circular-ref]';
            cache.set(value, true);
        }
        if (typeof value === 'function')
            return `[function] ${value.toString()}`;
        return value;
    };
    return JSON.stringify(obj, replacer, spacing);
};
