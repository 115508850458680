import {EShowDate} from "server-app/dist/interfaces";

export const resolveShowDate = (default_: boolean, ...options: (EShowDate | undefined)[]): boolean => {
  let optionsResult: null | boolean = null;
  options
    .reverse()
    .filter(Boolean)
    .forEach(option => {
      if (option !== EShowDate.INHERIT && optionsResult === null) {
        optionsResult = option === EShowDate.YES;
      }
    });
  return optionsResult === null ? default_ : optionsResult;
};
