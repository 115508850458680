// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qpa2t3RsZFQMX7IMLzoS {
  width: 100%;
  height: 100%;
}
.HUpusUk4umoKdu2TPNfp {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.dGnuJeqiLHPxj3UlG482 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.cCWDD9GsNJFG02IgyQMV {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  color: gray;
}
.iZyKQffkfOfq1_uvFStC {
  width: 20% !important;
  height: 20% !important;
}
`, "",{"version":3,"sources":["webpack://./src/DynaImage.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AAEA;EACE,WAAA;EACA,YAAA;EAKA,kCAAA;EACA,4BAAA;EAEA,kBAAA;AALF;AAQA;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AANF;AASA;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,oBAAA;EAAA,aAAA;EACA,qBAAA;MAAA,uBAAA;EACA,sBAAA;MAAA,mBAAA;EACA,WAAA;AAPF;AAUA;EACE,qBAAA;EACA,sBAAA;AARF","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n}\n\n.imageContainer {\n  width: 100%;\n  height: 100%;\n\n  // background-image: url(images/background-photo.jpg); // Updated from JS\n  // background-size: cover; // Updated from JS\n\n  background-position: center center;\n  background-repeat: no-repeat;\n\n  position: relative;\n}\n\n.contentContainer {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n\n.loadFailedContainer {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: gray;\n}\n\n.brokenIcon {\n  width: 20% !important;\n  height: 20% !important;;\n}\n"],"sourceRoot":""}]);
// Exports
export var root = `qpa2t3RsZFQMX7IMLzoS`;
export var imageContainer = `HUpusUk4umoKdu2TPNfp`;
export var contentContainer = `dGnuJeqiLHPxj3UlG482`;
export var loadFailedContainer = `cCWDD9GsNJFG02IgyQMV`;
export var brokenIcon = `iZyKQffkfOfq1_uvFStC`;
export default ___CSS_LOADER_EXPORT___;
