import {
  ETextAlign,
  EItalic,
  IDynaCMSArticleHeading,
  IApiArticleHeaderGetSearchBodyRequestV2,
} from "server-app/dist/interfaces";

import {Box} from "ui-components/dist/Box";
import {Collapse} from "ui-components/dist/Collapse";
import {ContainerSlider} from "ui-components/dist/ContainerSlider";
import {useLoadDataCache} from "ui-components/dist/useLoadDataCache";

import {IAppStore} from "../../../../state/IAppStore";

import {
  EDynaCMSFontFamily,
  DynaCMSFontFamily,
} from "../DynaCMSFontFamily";
import {ArticleDateViewer} from "../../components/article-header-viewers/HeaderViewerEva/components/components/ArticleDateViewer";

import {apiArticleHeaderGetSearch} from "../../api/articles/header/apiArticleHeaderGetSearch";
import {useBreakpointDevice} from "ui-components/dist/useBreakpointDevice";
import {
  DynaCMSArticleLink,
  ELinkDisplay,
} from "../DynaCMSArticleLink";

export interface IDynaCMSLatestSliderProps {
  store: IAppStore;
  h: number;
}

export const DynaCMSLatestSlider = (props: IDynaCMSLatestSliderProps): JSX.Element => {
  const {
    store: {
      dynaCMS: {
        state: {
          countryIds,
          languageId,
          settings: {groups},
        },
      },
    },
    h,
  } = props;
  const {isMobile} = useBreakpointDevice();

  const {data: articles} = useLoadDataCache<IDynaCMSArticleHeading[], IApiArticleHeaderGetSearchBodyRequestV2>({
    defaultData: [],
    search: {
      groupIds:
        groups
          .filter(group => group.hasContent)
          .map(group => group.id),
      languageId: languageId,
      countryIds,
      skip: 0,
      limit: 5,
    },
    cache: {
      containerName: 'DynaCMSLatestSlider',
      maxSizeInBytes: 100000,
      updateSilentlyExistedContent: true,
    },
    load: async search => {
      const {articles} = await apiArticleHeaderGetSearch(search);
      return articles;
    },
  });
  return (
    <Collapse expanded={!!articles.length}>
      <DynaCMSFontFamily
        component={`h${h}` as any}
        sx={{
          margin: 0,
          padding: 0,
        }}
        textAlign={ETextAlign.CENTER}
        fontFamily={EDynaCMSFontFamily.F186_FASHION_EMPIRE_FS}
        fontSize={64}
      >
        LATEST
      </DynaCMSFontFamily>
      <ContainerSlider>
        {articles
          .map((article, index) => (
            <DynaCMSArticleLink
              key={index}
              display={ELinkDisplay.BLOCK}
              article={article}
            >
              <Box
                sx={{
                  width: '90%',
                  maxWidth: 640,
                  margin: 'auto',
                  textAlign: 'center',
                  '>*': {margin: theme => theme.spacing(1)},
                }}
              >
                <DynaCMSFontFamily
                  show={!!article.title}
                  fontFamily={EDynaCMSFontFamily.F140_TIMES_BOOK_GENTIUM_PLUS}
                  italic={EItalic.ITALIC}
                  fontSize={22}
                >
                  {article.title}
                </DynaCMSFontFamily>
                <DynaCMSFontFamily
                  show={!!article.subtitle && !isMobile}
                  fontFamily={EDynaCMSFontFamily.F138_ELEGANT_BASE_ROBOTO_FLEX}
                  fontSize={18}
                >
                  {article.subtitle}
                </DynaCMSFontFamily>
                <DynaCMSFontFamily
                  show={!!article.description && !isMobile && !article.subtitle}
                  fontFamily={EDynaCMSFontFamily.F138_ELEGANT_BASE_ROBOTO_FLEX}
                  fontSize={16}
                >
                  {article.description}
                </DynaCMSFontFamily>
                <ArticleDateViewer
                  articleHeading={article}
                  target="list"
                />
              </Box>
            </DynaCMSArticleLink>
          ))}
      </ContainerSlider>
    </Collapse>
  );
};
