import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import MarkdownIt from 'markdown-it';
import { Box } from "../Box";
import { HtmlContent } from "../HtmlContent";
const md = new MarkdownIt();
export const MarkdownViewer = ({ sx = {}, markdownText = "", increaseHeadings, noMarginForTexts = false, }) => {
    const markdown = increaseHeadings
        ? markdownText
            .split('\n')
            .map(increaseHeadingLevel)
            .join('\n')
        : markdownText;
    const html = useMemo(() => md.render(markdown), [markdown]);
    return (_jsx(Box, { dataComponentName: "MarkdownViewer", sx: Object.assign({ h1: {
                fontFamily: 'inherit',
                margin: noMarginForTexts ? 0 : undefined,
            }, h2: {
                fontFamily: 'inherit',
                margin: noMarginForTexts ? 0 : undefined,
            }, h3: {
                fontFamily: 'inherit',
                margin: noMarginForTexts ? 0 : undefined,
            }, h4: {
                fontFamily: 'inherit',
                margin: noMarginForTexts ? 0 : undefined,
            }, h5: {
                fontFamily: 'inherit',
                margin: noMarginForTexts ? 0 : undefined,
            }, h6: {
                fontFamily: 'inherit',
                margin: noMarginForTexts ? 0 : undefined,
            }, "& p": { margin: noMarginForTexts ? 0 : undefined }, blockquote: {
                borderLeft: '8px solid',
                paddingLeft: 1,
            }, a: { color: theme => theme.palette.info.main }, 
            // Add styles for your table here
            table: {
                borderCollapse: 'collapse',
                marginBottom: '20px', // Example margin for spacing
            }, th: {
                backgroundColor: theme => theme.palette.grayShades.gray1,
                border: '1px solid',
                padding: '8px',
                textAlign: 'left',
            }, td: {
                backgroundColor: theme => theme.palette.grayShades.gray0,
                border: '1px solid', // Example border for data cells
                padding: '8px',
                textAlign: 'left',
            } }, sx), children: _jsx(HtmlContent, { html: html }) }));
};
const increaseHeadingLevel = (markdownLine) => {
    const regex = /^(#+)(.*)/;
    const match = markdownLine.match(regex);
    if (match) {
        const originalLevel = match[1];
        const headingText = match[2];
        const newLevel = originalLevel + '#'; // Increase the heading level by one
        return newLevel + headingText;
    }
    else {
        return markdownLine; // Return unchanged if it's not a valid heading line
    }
};
