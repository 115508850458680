import {dynaSwitchEnum} from "dyna-switch";

import {ECompany} from "server-app/dist/interfaces";

import {ICompanyElements} from "./ICompanyElements";
import {appConfig} from "../core/application/config/appConfig";

export const getCompanyElements = async (): Promise<ICompanyElements> => {
  const company: ECompany = appConfig.company;
  const loader =
    dynaSwitchEnum<ECompany, () => Promise<ICompanyElements>>(
      company,
    {
      [ECompany.XDOC_NO]: async () => {
        const {companyElements} = await import("./xdoc-no/companyElements");
        return companyElements();
      },
      [ECompany.ADORE_GR]: async () => {
        const {companyElements} = await import("./adore-gr/companyElements");
        return companyElements();
      },
      [ECompany.ANEL_COMPANY]: async () => {
        const {companyElements} = await import("./anel-company/companyElements");
        return companyElements();
      },
      [ECompany.NET_FASHION]: async () => {
        const {companyElements} = await import("./net-fashion/companyElements");
        return companyElements();
      },
      [ECompany.THE_GIRL_GUNS]: async () => {
        const {companyElements} = await import("./tgg-com/companyElements");
        return companyElements();
      },
      [ECompany.UNKNOWN]: async () => {
        const {companyElements} = await import("./unknown/companyElements");
        return companyElements();
      },
    },
    );
  return loader();
};
