import {IDynaCMSArticleHeading} from "server-app/dist/interfaces";

import {
  Link,
  ELinkColor,
  ELinkUnderline,
  ELinkDisplay,
} from "ui-components/dist/Link";

import {
  SxProps,
  Theme,
} from "ui-components/dist/ThemeProvider";

import {getDynaCMSArticleViewerLinkByArticle} from "../../routes/routeDynaCMSArticleViewerByUrlId";

export interface IDynaCMSArticleLinkProps {
  sx?: SxProps<Theme>;
  display?: ELinkDisplay;
  article: IDynaCMSArticleHeading;
  children: any;
}

export const DynaCMSArticleLink = (props: IDynaCMSArticleLinkProps): JSX.Element => {
  const {
    article,
    display = ELinkDisplay.BLOCK,
    children,
  } = props;

  return (
    <Link
      display={display}
      color={ELinkColor.INHERIT}
      underline={ELinkUnderline.NONE}
      href={getDynaCMSArticleViewerLinkByArticle(article)}
    >
      {children}
    </Link>
  );
};
