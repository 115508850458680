import {
  IDynaCMSArticle,
  IDynaCMSBlockBody,
  EViewWidthType,
} from "server-app/dist/interfaces";

import {HtmlContent} from "ui-components/dist/HtmlContent";

import {IAppStore} from "../../../../../state/IAppStore";
import {DynaCMSFont} from "../../../public-components";

import {getContentPaddingSx} from "../utils/getContentPadding";
import {sxHeadingLetter} from "../utils/sxHeadingLetter";

export interface IViewerBodyProps {
  article: IDynaCMSArticle;
  store: IAppStore;
  content: IDynaCMSBlockBody;
  viewWidthType?: EViewWidthType;
}

export const ViewerBody = (props: IViewerBodyProps): JSX.Element => {
  const {
    store: {dynaCMS: {state: {settings: {defaultArticleFontFamilyGeneral}}}},
    article: {fontFamilyGeneral: articleFontFamily},
    content: {
      html,
      font,
      headingFirstLetter,
    },
    viewWidthType,
  } = props;

  return (
    <DynaCMSFont
      font={[
        defaultArticleFontFamilyGeneral,
        articleFontFamily,
        font,
      ]}
      sx={{
        ...getContentPaddingSx(0),
        maxWidth: viewWidthType === EViewWidthType.EASY_READ ? 640 : undefined,
        "> div > p:first-of-type:first-letter":
          headingFirstLetter
            ? sxHeadingLetter as any
            : undefined,
        "a": {color: theme => theme.palette.info.main},
      }}
    >
      <HtmlContent html={html}/>
    </DynaCMSFont>
  );
};
